import { Chip, Typography } from "@material-tailwind/react";
import { BlogComponent } from "./blog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { ConvertUnixTimestampToDate } from "../../app/common";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GetBlogById } from "../../features/Blogs/BlogSlice";

function SingleBlogPage() {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const blogData = useSelector((state: RootState) => state.BlogsSlice);
  const blogsLength = blogData.blogSliceData.blogData.blogs.length;
  const navigate = useNavigate();
  const location = useLocation();
  const buttonClicked = (id: string) => {
    navigate({
      pathname: "/singleBlogPage",
      search: createSearchParams({ blogId: id.toLowerCase() }).toString()
    });
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let blogId = params.get("blogId") ?? "none";
    dispatch(GetBlogById(blogId))
  }, [location]);

  return (
    <>
      <div id="singleBlog" className="scroll-mt-[100px]">
        <Typography className="uppercase bg-#F6F6F6 font-bold text-lg lg:text-2xl 2xl:text-4xl text-center py-[20px]">
          {t("SingleBlogPost")}
        </Typography>
        <div className="px-[20px] md:px-[100px] xl:px-[110px] 2xl:px-[120px] py-[30px]">
          <a href="/blogPage" className="flex items-center">
            <svg className="w-[6px] h-[11px] 2xl:w-[12px] 2xl:h-[22px]" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 6 11" fill="none">
              <path d="M0.141703 5.84268L5.23331 10.8693C5.31827 10.9532 5.43114 11 5.54851 11C5.66589 11 5.77875 10.9532 5.86371 10.8693L5.86919 10.8636C5.91052 10.8229 5.94343 10.7739 5.96592 10.7197C5.9884 10.6654 6 10.607 6 10.5479C6 10.4888 5.9884 10.4304 5.96592 10.3761C5.94343 10.3218 5.91052 10.2729 5.86919 10.2322L1.07451 5.49905L5.86919 0.767807C5.91052 0.727127 5.94343 0.67816 5.96592 0.623888C5.9884 0.569615 6 0.511169 6 0.452106C6 0.393043 5.9884 0.334597 5.96592 0.280324C5.94343 0.226051 5.91052 0.177086 5.86919 0.136406L5.86371 0.130726C5.77875 0.0468118 5.66588 -2.47664e-07 5.54851 -2.42533e-07C5.43114 -2.37403e-07 5.31827 0.0468118 5.23331 0.130726L0.141703 5.15732C0.096924 5.20153 0.0612753 5.25469 0.0369175 5.3136C0.0125596 5.3725 -2.43214e-07 5.43592 -2.40413e-07 5.5C-2.37611e-07 5.56408 0.0125597 5.6275 0.0369175 5.6864C0.0612753 5.74531 0.096924 5.79848 0.141703 5.84268Z" fill="#333333" />
            </svg>
            <Typography className="underline text-sm 2xl:text-2xl font-normal tracking-[0.7px] ml-[19px]">
              {t("BackToAllBlogs")}
            </Typography>
          </a>
          <div className="grid grid-cols-1 xl:grid-cols-3 mt-[30px]">
            <div className="xl:col-span-2">
              <img src={blogData.blogSliceData.blogData.blog?.image} alt="" className="w-full max-w-[1079px] 2xl:max-w-[1550px] max-h-[700px] object-cover" />
              <div className="flex items-center mt-[20px]">
                {blogData.blogSliceData.blogData.blog?.tags.slice(0, 2).map((tag, index) => (
                  <Chip key={index} variant="outlined" size="sm" value={tag} color="gray" className="mr-[10px] rounded-[5px] lowercase text-sm 2xl:text-2xl " />
                ))}
                <Typography className="pl-3 uppercase text-#FF6104 text-base 2xl:text-2xl font-light tracking-[0.8px]">
                  {blogData.blogSliceData.blogData.blog?.minutesToRead} {t("MinToRead")}
                </Typography>
              </div>
              <Typography className="text-xl md:text-2xl 2xl:text-4xl font-bold tracking-[0.9px] mt-[20px] lg:mt-[30px]">
                {blogData.blogSliceData.blogData.blog?.title}
              </Typography>
              {blogData.blogSliceData.blogData.blog && <div className="mt-[25px] lg:mt-[50px] ql-editor whitespace-normal h-auto p-0" dangerouslySetInnerHTML={{ __html: blogData.blogSliceData.blogData.blog.text }}></div>}
            </div>
            <div className="hidden xl:flex flex-col ml-[80px]">
              <Typography className="text-2xl 2xl:text-4xl font-normal">
                {t("TheLatest")}
              </Typography>
              {blogData.blogSliceData.blogData.blogs.slice(blogsLength - 3, blogsLength).reverse().map((blogLink, index) => (
                <div key={blogLink.id + index} className="pt-[30px]">
                  <Typography className="hover:underline text-sm 2xl:text-2xl cursor-pointer" onClick={() => buttonClicked(blogLink.id)}>
                    {blogLink.title}
                  </Typography>
                  <div className="flex items-center mt-3">
                    <Typography className="text-base 2xl:text-2xl font-normal text-#333333 opacity-80">
                      {ConvertUnixTimestampToDate(blogLink.creationTimestampUnix)}
                    </Typography>
                    <svg className="ml-[17px] mr-[10px] w-[20px] h-[2px] 2xl:w-[40px] 2xl:h-[3px]" width="100%" height="100%" viewBox="0 0 20 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="0.5" width="20" height="1" fill="#121416" fillOpacity="0.81" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z" stroke="#121416" strokeOpacity="0.81" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.9993 11.9983L9.66602 13.5538M11.9993 8.10938V11.9983" stroke="#121416" strokeOpacity="0.81" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <Typography className="text-base 2xl:text-2xl font-normal text-#333333 opacity-80 ml-[4px]">
                      {blogLink.minutesToRead} {t("MinToRead")}
                    </Typography>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr className=" bg-#A0A0A0 my-[30px] lg:my-[60px]" />
          <div>
            <Typography className="uppercase text-lg 2xl:text-3xl font-bold tracking-[0.9px] text-center my-[30px] lg:my-[60px]">
              {t("MoreBlogsToRead")}
            </Typography>
            <section className="">
              <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 3xl:grid-cols-4 gap-10  w-fit mx-auto">
                <div className="">
                  {blogsLength > 0 ?
                    <BlogComponent key={blogData.blogSliceData.blogData.blogs[blogsLength - 1].title} {...blogData.blogSliceData.blogData.blogs[blogsLength - 1]} /> : <Typography className="text-center">{t("Loading")}</Typography>}
                </div>
                <div className="">
                  {blogsLength > 1 ?
                    <BlogComponent key={blogData.blogSliceData.blogData.blogs[blogsLength - 2].title} {...blogData.blogSliceData.blogData.blogs[blogsLength - 2]} /> : <></>}
                </div>
                <div className="lg:hidden 2xl:block">
                  {blogsLength > 2 ?
                    <BlogComponent key={blogData.blogSliceData.blogData.blogs[blogsLength - 3].title} {...blogData.blogSliceData.blogData.blogs[blogsLength - 3]} /> : <></>}
                </div>
                <div className="hidden 3xl:block">
                  {blogsLength > 3 ?
                    <BlogComponent key={blogData.blogSliceData.blogData.blogs[blogsLength - 4].title} {...blogData.blogSliceData.blogData.blogs[blogsLength - 4]} /> : <></>}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
export default SingleBlogPage;
