import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAddress } from "../../app/apiModel";
import { AppRoles, handleFulfilled, handlePending, handleRejected, RejectedValue, RemoveItemFromArray, StatusValue, } from "../../app/common";
import {
  AddMembershipModel, RemoveMembershipModel, SetMembershipRoleModel, GetDirectoryDataResponse, GetDirectoryInfo, GetDirectoryMembersResponse,
  GetHomeDirectoryResponse, GetDirectoryInfoResponse, ErrorResponse, DirectoryData, DirectorySliceData, AddMembershipResponseModel, UpdateMembershipRole,
} from "./directoryModel";

export const PostAddMembership = createAsyncThunk<AddMembershipResponseModel, AddMembershipModel, { rejectValue: RejectedValue }>(
  "directories/addmembership",
  async (mermbership: AddMembershipModel, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "directories/addmembership", {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        email: mermbership.email,
        DirectoryId: mermbership.DirectoryId,
      }),
    });

    if (response.status === 200) {
      const responseModel: AddMembershipResponseModel = await response.json();
      responseModel.directoryId = mermbership.DirectoryId;
      return (responseModel);
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const PostRemoveMembership = createAsyncThunk<RemoveMembershipModel, RemoveMembershipModel, { rejectValue: RejectedValue }>(
  "directories/removemembership",
  async (mermbership: RemoveMembershipModel, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "directories/removemembership", {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        userId: mermbership.userId,
        DirectoryId: mermbership.DirectoryId,
      }),
    });

    if (response.status === 200) {
      return mermbership;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const PostSetMembershipRole = createAsyncThunk<UpdateMembershipRole, SetMembershipRoleModel, { rejectValue: RejectedValue }>(
  "directories/setmembershiprole",
  async (mermbershipRole: SetMembershipRoleModel, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "directories/setmembershiprole", {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        directoryId: mermbershipRole.directoryId,
        userId: mermbershipRole.userId,
        RoleType: mermbershipRole.RoleType,
      }),
    });

    if (response.status === 200) {
      let roleUpdate: UpdateMembershipRole = {
        userId: mermbershipRole.userId,
        roleUpdate: mermbershipRole.RoleType
      }
      return roleUpdate;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const GetAllDirectories = createAsyncThunk<GetDirectoryDataResponse[], void, { rejectValue: RejectedValue }>(
  "GetAllDirectories",
  async (_, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "directories/getalldirectories", {
      method: "GET",
      headers: requestHeaders,
    });

    if (response.status === 200) {
      const dataResponse = (await response.json()) as GetDirectoryDataResponse[];
      return dataResponse;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  });

export const GetDirectoryInfoData = createAsyncThunk<GetDirectoryInfo, GetHomeDirectoryResponse, { rejectValue: RejectedValue }>(
  "GetDirectoryInfo",
  async (directory: GetHomeDirectoryResponse, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress +
      "directories/getdirectoryinfo?directoryId=" +
      directory.directoryId,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const dataResponse = (await response.json()) as GetDirectoryInfo;
      return dataResponse;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const GetDirectoryMembers = createAsyncThunk<GetDirectoryMembersResponse[], GetHomeDirectoryResponse, { rejectValue: RejectedValue }>(
  "GetDirectoryMembers",
  async (directory: GetHomeDirectoryResponse, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress +
      "directories/getdirectorymembers?directoryId=" +
      directory.directoryId,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const dataResponse =
        (await response.json()) as GetDirectoryMembersResponse[];
      return dataResponse;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const GetDirectoryMembersForLicenses = createAsyncThunk<GetDirectoryMembersResponse[], GetHomeDirectoryResponse, { rejectValue: RejectedValue }>(
  "GetDirectoryMembersForLicenses",
  async (directory: GetHomeDirectoryResponse, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress +
      "directories/getdirectorymembers?directoryId=" +
      directory.directoryId,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const dataResponse =
        (await response.json()) as GetDirectoryMembersResponse[];
      return dataResponse;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const GetHomeDirectory = createAsyncThunk<GetHomeDirectoryResponse, void, { rejectValue: RejectedValue }>("GetHomeDirectory", async (_, { rejectWithValue }) => {
  const requestHeaders: HeadersInit = new Headers();
  const accessToken = localStorage.getItem("webShopBimTAccessToken");
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Authorization", "Bearer " + accessToken);

  const response = await fetch(apiAddress + "directories/gethomedirectory", {
    method: "GET",
    headers: requestHeaders,
  });

  if (response.status === 200) {
    const dataResponse = (await response.json()) as GetHomeDirectoryResponse;
    return dataResponse;
  }
  else if (response.status === 401 || response.status === 402) {
    return rejectWithValue({ message: response.statusText, status: response.status })
  }
  else {
    const errorResponse = (await response.json()) as ErrorResponse;
    return rejectWithValue({
      message: errorResponse.detail,
      status: response.status,
    });
  }
});

export const GetMembershipInfo = createAsyncThunk<GetDirectoryInfoResponse, string, { rejectValue: RejectedValue }>(
  "GetMembershipInfo",
  async (directoryId: string, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress +
      "directories/getmembershipinfo?directoryId=" +
      directoryId,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const dataResponse = (await response.json()) as GetDirectoryInfoResponse;
      return dataResponse;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

const initialAllDirectories: GetDirectoryDataResponse[] = [];

const initialDirectoryInfo: GetDirectoryInfo = {
  id: "",
  directoryMail: "",
  creatorId: "",
  editorId: "",
  ownerId: "",
  creationDate: "",
  editingDate: "",
  isActive: false,
  isDeleted: false,
};

const initialDirectoryMembers: GetDirectoryMembersResponse[] = [
  {
    directoryId: "",
    userId: "",
    role: "",
    firstName: "",
    lastName: "",
    email: ""
  },
];

const initialHomeDirectory: GetHomeDirectoryResponse = {
  directoryId: "",
};

const initialMemberhipInfo: GetDirectoryInfoResponse = {
  membershipId: "",
  invitedBy: "",
  joinedOn: "",
};

const initStatusValues: StatusValue = {
  statusCode: "",
  isLoading: false,
  error: "",
}

const initialDirectoryData: DirectoryData = {
  directoryData: initialAllDirectories,
  directoryInfoData: initialDirectoryInfo,
  directoryMembersData: initialDirectoryMembers,
  directoryMembersDataForLicenses: initialDirectoryMembers,
  homeDirectoryData: initialHomeDirectory,
  membershipInfoData: initialMemberhipInfo,
}

const initialDirectorySliceData: DirectorySliceData = {
  directoryData: initialDirectoryData,
  statusValues: initStatusValues
}

export const DirectorySlice = createSlice({
  name: "DirectorySlice",
  initialState: {
    directorySliceData: initialDirectorySliceData
  },
  reducers: {
    resetDirectorySlice: (state) => {
      state.directorySliceData.statusValues.statusCode = '';
      state.directorySliceData.statusValues.isLoading = false;
      state.directorySliceData.statusValues.error = '';
      state.directorySliceData.directoryData.directoryData = initialAllDirectories;
      state.directorySliceData.directoryData.directoryInfoData = initialDirectoryInfo;
      state.directorySliceData.directoryData.directoryMembersData = initialDirectoryMembers;
      state.directorySliceData.directoryData.directoryMembersDataForLicenses = initialDirectoryMembers;
      state.directorySliceData.directoryData.homeDirectoryData = initialHomeDirectory;
      state.directorySliceData.directoryData.membershipInfoData = initialMemberhipInfo;
    },
    resetStatusCode: (state) => {
      state.directorySliceData.statusValues.statusCode = '';
    },
    addMemberToDirectory: (state, action: PayloadAction<AddMembershipResponseModel>) => {
      let memberToAdd: GetDirectoryMembersResponse = {
        directoryId: action.payload.directoryId,
        userId: action.payload.userId,
        role: action.payload.role,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email
      }
      state.directorySliceData.directoryData.directoryMembersData.push(memberToAdd)
    },
    removeMemberFromDirectory: (state, action: PayloadAction<GetDirectoryMembersResponse | undefined>) => {
      if (action.payload !== undefined)
        state.directorySliceData.directoryData.directoryMembersData = RemoveItemFromArray(state.directorySliceData.directoryData.directoryMembersData, action.payload)
    },
    changeMemberRole: (state, action: PayloadAction<UpdateMembershipRole>) => {
      let indexOfMemberToUpdate = state.directorySliceData.directoryData.directoryMembersData.findIndex(x => x.userId === action.payload.userId)
      if (indexOfMemberToUpdate !== -1)
        state.directorySliceData.directoryData.directoryMembersData[indexOfMemberToUpdate].role = action.payload.roleUpdate
    }
  },
  extraReducers(builder) {
    //Add Membership
    builder
      .addCase(PostAddMembership.fulfilled, (state, action) => {
        state.directorySliceData.statusValues.statusCode = '201';
        handleFulfilled(state.directorySliceData.statusValues, action.payload);
        let memberToAdd: GetDirectoryMembersResponse = {
          directoryId: action.payload.directoryId,
          userId: action.payload.userId,
          role: AppRoles.Member,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email
        }
        state.directorySliceData.directoryData.directoryMembersData.push(memberToAdd)
      })
      .addCase(PostAddMembership.rejected, (state, action) => {
        handleRejected(state.directorySliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(PostAddMembership.pending, (state) => {
        handlePending(state.directorySliceData.statusValues);
      })
      //Remove Membership
      .addCase(PostRemoveMembership.fulfilled, (state, action) => {
        state.directorySliceData.statusValues.statusCode = '200';
        handleFulfilled(state.directorySliceData.statusValues, action.payload);
        let memberToRemove: GetDirectoryMembersResponse | undefined = state.directorySliceData.directoryData.directoryMembersData.find(member => member.userId === action.payload.userId)

        if (memberToRemove !== undefined)
          state.directorySliceData.directoryData.directoryMembersData = RemoveItemFromArray(state.directorySliceData.directoryData.directoryMembersData, memberToRemove)
      })
      .addCase(PostRemoveMembership.rejected, (state, action) => {
        handleRejected(state.directorySliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(PostRemoveMembership.pending, (state) => {
        handlePending(state.directorySliceData.statusValues);
      })
      //Set Membership Role
      .addCase(PostSetMembershipRole.fulfilled, (state, action) => {
        state.directorySliceData.statusValues.statusCode = '200';
        handleFulfilled(state.directorySliceData.statusValues, action.payload);

        let indexOfMemberToUpdate = state.directorySliceData.directoryData.directoryMembersData.findIndex(x => x.userId === action.payload.userId)
        if (indexOfMemberToUpdate !== -1)
          state.directorySliceData.directoryData.directoryMembersData[indexOfMemberToUpdate].role = action.payload.roleUpdate
      })
      .addCase(PostSetMembershipRole.rejected, (state, action) => {
        handleRejected(state.directorySliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(PostSetMembershipRole.pending, (state) => {
        handlePending(state.directorySliceData.statusValues);
      })
      //Get all directories
      .addCase(GetAllDirectories.fulfilled, (state, action) => {
        state.directorySliceData.directoryData.directoryData = handleFulfilled(state.directorySliceData.statusValues, action.payload);
      })
      .addCase(GetAllDirectories.rejected, (state, action) => {
        handleRejected(state.directorySliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetAllDirectories.pending, (state, action) => {
        handlePending(state.directorySliceData.statusValues);
      });
    //Get directory info
    builder
      .addCase(GetDirectoryInfoData.fulfilled, (state, action) => {
        state.directorySliceData.directoryData.directoryInfoData = handleFulfilled(state.directorySliceData.statusValues, action.payload);
      })
      .addCase(GetDirectoryInfoData.rejected, (state, action) => {
        handleRejected(state.directorySliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetDirectoryInfoData.pending, (state, action) => {
        handlePending(state.directorySliceData.statusValues);
      });
    //Get directory members
    builder
      .addCase(GetDirectoryMembers.fulfilled, (state, action) => {
        state.directorySliceData.directoryData.directoryMembersData = handleFulfilled(state.directorySliceData.statusValues, action.payload);
      })
      .addCase(GetDirectoryMembers.rejected, (state, action) => {
        handleRejected(state.directorySliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetDirectoryMembers.pending, (state, action) => {
        handlePending(state.directorySliceData.statusValues);
      });
    //Get directory members for licenses
    builder
      .addCase(GetDirectoryMembersForLicenses.fulfilled, (state, action) => {
        state.directorySliceData.directoryData.directoryMembersDataForLicenses = handleFulfilled(state.directorySliceData.statusValues, action.payload);
      })
      .addCase(GetDirectoryMembersForLicenses.rejected, (state, action) => {
        handleRejected(state.directorySliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetDirectoryMembersForLicenses.pending, (state, action) => {
        handlePending(state.directorySliceData.statusValues);
      });
    //Get home directory
    builder
      .addCase(GetHomeDirectory.fulfilled, (state, action) => {
        state.directorySliceData.directoryData.homeDirectoryData = handleFulfilled(state.directorySliceData.statusValues, action.payload);
      })
      .addCase(GetHomeDirectory.rejected, (state, action) => {
        handleRejected(state.directorySliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetHomeDirectory.pending, (state, action) => {
        handlePending(state.directorySliceData.statusValues);
      });
    //Get membership info
    builder
      .addCase(GetMembershipInfo.fulfilled, (state, action) => {
        state.directorySliceData.directoryData.membershipInfoData = handleFulfilled(state.directorySliceData.statusValues, action.payload);
      })
      .addCase(GetMembershipInfo.rejected, (state, action) => {
        handleRejected(state.directorySliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetMembershipInfo.pending, (state, action) => {
        handlePending(state.directorySliceData.statusValues);
      });
  },
});

export default DirectorySlice.reducer;
export const { resetDirectorySlice, resetStatusCode, addMemberToDirectory, removeMemberFromDirectory, changeMemberRole } = DirectorySlice.actions;
