import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BlogData, BlogSliceData, CreateBlogRequest, EditBlogRequest, GetBlogResponse } from "./BlogModel";
import { RejectedValue, StatusValue, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { apiAddress } from "../../app/apiModel";
import { ErrorResponse } from "../Authentification/authModel";

// no_ - used for middleware to know to ignore refresh token for those actions
export const GetAllBlogs = createAsyncThunk<GetBlogResponse[], void, { rejectValue: RejectedValue, shouldRefreshToken : boolean }>(
    'no_BlogSlicer/GetAllBlogs',
    async (_, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'blog/getallblogs', {
            method: 'GET',
            headers: requestHeaders,
        })
        if (response.status === 200) {
            const dataResponse = (await response.json()) as GetBlogResponse[];
            return dataResponse;
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }

);


export const GetBlogById = createAsyncThunk<GetBlogResponse, string, { rejectValue: RejectedValue }>(
    'no_BlogSlicer/GetBlog',
    async (blogId: string, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'blog/getblogbyid?blogId=' + blogId, {
            method: 'GET',
            headers: requestHeaders
        })
        console.log(blogId)
        if (response.status === 200) {
            const dataResponse = (await response.json()) as GetBlogResponse;
            return dataResponse;
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const PostCreateBlog = createAsyncThunk<string, CreateBlogRequest, { rejectValue: RejectedValue }>(
    'BlogSlicer/CreateBlog',
    async (blog: CreateBlogRequest, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'blog/createblog', {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(blog)
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const PutBlog = createAsyncThunk<string, EditBlogRequest, { rejectValue: RejectedValue }>(
    'BlogSlicer/EditBlog',
    async (blog: EditBlogRequest, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'blog/editblog', {
            method: 'PUT',
            headers: requestHeaders,
            body: JSON.stringify(blog)
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const DeleteBlog = createAsyncThunk<string, string, { rejectValue: RejectedValue }>(
    'BlogSlicer/DeleteBlog',
    async (blogId: string, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'blog/deleteblog?blogId=' + blogId, {
            method: 'PUT',
            headers: requestHeaders,
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

const initialBlogResponse: GetBlogResponse = {
    id: "",
    image: "",
    tags: [],
    minutesToRead: "",
    title: "",
    text: "",
    description: "",
    creator: "",
    creationTimestampUnix: "",
}

const initStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    error: "",
}

const initialBlogData: BlogData = {
    blogs: [],
    blog: initialBlogResponse
}

const initialBlogSliceData: BlogSliceData = {
    blogData: initialBlogData,
    statusValues: initStatusValues
}

const BlogSlice = createSlice({
    name: 'BlogSlicer',
    initialState: {
        blogSliceData: initialBlogSliceData,
    },
    reducers: {
        resetStatusCode: (state) => {
            state.blogSliceData.statusValues.statusCode = '';
            state.blogSliceData.statusValues.error = '';
        },
    },
    extraReducers(builder) {
        builder
            //GetAllBlogs
            .addCase(GetAllBlogs.fulfilled, (state, action) => {
                state.blogSliceData.blogData.blogs = handleFulfilled(state.blogSliceData.statusValues, action.payload);
            })
            .addCase(GetAllBlogs.rejected, (state, action) => {
                handleRejected(state.blogSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(GetAllBlogs.pending, (state) => {
                handlePending(state.blogSliceData.statusValues);
            })
            //GetBlog
            .addCase(GetBlogById.fulfilled, (state, action) => {
                state.blogSliceData.blogData.blog = handleFulfilled(state.blogSliceData.statusValues, action.payload);
            })
            .addCase(GetBlogById.rejected, (state, action) => {
                handleRejected(state.blogSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(GetBlogById.pending, (state) => {
                handlePending(state.blogSliceData.statusValues);
            })
            //CreateBlog
            .addCase(PostCreateBlog.fulfilled, (state, action) => {
                state.blogSliceData.statusValues.statusCode = handleFulfilled(state.blogSliceData.statusValues, action.payload);
            })
            .addCase(PostCreateBlog.rejected, (state, action) => {
                handleRejected(state.blogSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PostCreateBlog.pending, (state) => {
                handlePending(state.blogSliceData.statusValues);
            })
            //EditBlog
            .addCase(PutBlog.fulfilled, (state, action) => {
                state.blogSliceData.statusValues.statusCode = handleFulfilled(state.blogSliceData.statusValues, action.payload);
            })
            .addCase(PutBlog.rejected, (state, action) => {
                handleRejected(state.blogSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PutBlog.pending, (state) => {
                handlePending(state.blogSliceData.statusValues);
            })
            //DeleteBlog
            .addCase(DeleteBlog.fulfilled, (state, action) => {
                state.blogSliceData.statusValues.statusCode = handleFulfilled(state.blogSliceData.statusValues, action.payload);
            })
            .addCase(DeleteBlog.rejected, (state, action) => {
                handleRejected(state.blogSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(DeleteBlog.pending, (state) => {
                handlePending(state.blogSliceData.statusValues);
            })
    }
})

export const { resetStatusCode } = BlogSlice.actions;
export default BlogSlice.reducer;

