import { Button, Card, CardBody, CardFooter, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

interface SuccessCardProps {
  text: string;
  success: boolean;
  onClickFunction: () => void
}

function SuccessCard(props: SuccessCardProps) {
  const { t } = useTranslation();

  return (
    <div className=" w-full h-full">
      <Card >
        <CardBody className="p-0">
          <Typography className="text-2xl 2xl:text-3xl font-bold uppercase text-center mt-[30px]">
            {props.success ? t("Success") : t("Failed")}
          </Typography>
          <div className="mx-auto w-[56px] 2xl:w-[70px] mt-[20px]">
            {props.success ? (
              <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M28 0C12.5361 0 0 12.5361 0 28C0 43.4647 12.5361 56 28 56C43.4647 56 56 43.4647 56 28C56 12.5361 43.4647 0 28 0ZM28 52.5551C14.4909 52.5551 3.5 41.5091 3.5 27.9999C3.5 14.4908 14.4909 3.49989 28 3.49989C41.5091 3.49989 52.5 14.4908 52.5 27.9999C52.5 41.509 41.5091 52.5551 28 52.5551ZM39.1746 17.7546L22.7464 34.286L15.3483 26.8879C14.6649 26.2045 13.5572 26.2045 12.8729 26.8879C12.1896 27.5713 12.1896 28.679 12.8729 29.3624L21.5346 38.0249C22.2179 38.7074 23.3257 38.7074 24.0099 38.0249C24.0887 37.9461 24.1561 37.8603 24.2174 37.7711L41.6509 20.2299C42.3334 19.5466 42.3334 18.4388 41.6509 17.7546C40.9666 17.0713 39.8589 17.0713 39.1746 17.7546Z"
                  fill="#A3B974"
                />
              </svg>
            ) : (
              <svg width="100%" height="100%" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M28 0C12.5361 0 0 12.5361 0 28C0 43.4648 12.5361 56 28 56C43.4648 56 56 43.4648 56 28C56 12.5361 43.4648 0 28 0ZM28 52.5551C14.4909 52.5551 3.5 41.5091 3.5 27.9999C3.5 14.4908 14.4909 3.49989 28 3.49989C41.5091 3.49989 52.5 14.4908 52.5 27.9999C52.5 41.509 41.5091 52.5551 28 52.5551Z"
                  fill="#EA4247"
                />
                <path d="M37 19L18 38M18 19L37 38" stroke="#EA4247" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            )}
          </div>
          <Typography className="text-base 2xl:text-xl font-light mx-auto w-fit mt-[20px] text-center">
            {props.text}
          </Typography>
        </CardBody>

        <CardFooter className="p-2 mx-auto mt-[30px]">
          <Button id="continueBtn" onClick={props.onClickFunction} className="bg-#FF8136 hover:bg-#FF6104 h-[40px] w-[280px] text-base font-light p-0 uppercase shadow-none hover:shadow-none">
            {t("Continue")}
          </Button>
        </CardFooter>
      </Card>
    </div>

  );
}

export default SuccessCard;
