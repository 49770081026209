import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { postForgotPassword } from "../authentificationSlice";
import { InitPasswordReset } from "../authModel";
import { ValidateEmail } from "../../../app/validation";
import { LoadingPage } from "../../CommonPages/loadingPage";
import ForgotPasswordCard from "./forgotPasswordCard";
import { Alert, Dialog, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import SuccessCard from "../../CommonPages/successCard";

export function ForgotPassword() {
  const [errorMesssage, setErrorMesssage] = useState('');
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const forgotPasswordData = useSelector((state: RootState) => state.AuthentificationSlice);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [openDialog, setOpenDialog] = useState(false);
  const [success, setSuccess] = useState(false);
  const [messsage, setMesssage] = useState('');

  const handleOpenDialog = () => setOpenDialog(!openDialog);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submitFunction(email);
  };

  const submitFunction = (email: string) => {
    if (ValidateEmail(email)) {
      const forgotPassReq: InitPasswordReset = { username: email };
      dispatch(postForgotPassword(forgotPassReq))
    }
    else {
      setErrorMesssage(t("YouHaveEnteredAnInvalidEmailAddress"));
      setOpen(true);
    }
  }

  useEffect(() => {
    if (forgotPasswordData.statusCode === '200' || forgotPasswordData.statusCode === 'OK') {
      setSuccess(true);
      setMesssage(t("RequestForPasswordResetWasSentCheckYourEmail"));
      handleOpenDialog();
    }
    else if (forgotPasswordData.statusCode === '404') {
      setSuccess(false);
      setMesssage(t("UserDoesNotExist"));
      handleOpenDialog();
    }
    else if (forgotPasswordData.statusCode !== '') {
      setSuccess(false);
      setMesssage(forgotPasswordData.error ?? t("AnErrorOccurred"));
      handleOpenDialog();
    }
  }, [forgotPasswordData.statusCode])

  if (forgotPasswordData.isLoading === true) {
    return (LoadingPage(forgotPasswordData.isLoading))
  }

  return (
    <div className="flex justify-center m-[5%]">
      <form className="space-y-5" onSubmit={handleSubmit}>
        {errorMesssage !== "" ?
          <Alert color="red" open={open} onClose={() => setOpen(false)}>
            <Typography className="text-[14px] flex-nowrap overflow-hidden text-ellipsis max-w-[320px] max-lg:w-[400px] max-2xl:w-[500px]">
              {errorMesssage}
            </Typography>
          </Alert>
          : <></>}
        <ForgotPasswordCard onClickFunction={submitFunction} setEmailForm={setEmail} />
        <input type="submit" style={{ display: "none" }} />
      </form>
      <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}>
        <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
      </Dialog>
    </div>
  );
}
