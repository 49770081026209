import { Tooltip, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

interface InfoProps {
    text: string;
}

function InfoComponent(props: InfoProps) {
    const { t } = useTranslation();

    return (
        <Tooltip className="rounded-[5px] bg-#F6F6F6 text-#333333 border-[1px] border-#788090-20 px-[10px] text-[12px] 2xl:text-[14px] leading-[14px] 2xl:leading-[18px] font-[300] w-[200px] md:w-[300px] lg:w-[352px]" content={t(props.text)} placement="right-start">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className=" opacity-100">
                <circle cx="8" cy="8" r="7" stroke="#788090" strokeWidth="1.5" />
                <path d="M8 11V8" stroke="#788090" strokeWidth="1.5" strokeLinecap="round" />
                <circle cx="8.0002" cy="5.5002" r="1.05" fill="#788090" />
            </svg>
        </Tooltip>
    );
}

export default InfoComponent;