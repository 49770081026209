// src/authConfig.ts

import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: "8fee51d0-dbf6-4644-90e9-602a209cd210", // Replace with your Azure AD app client ID
        authority: "https://login.microsoftonline.com/common", // Use "common" for multi-tenant and personal Microsoft accounts
        redirectUri: "https://www.bimagine.com",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false, // Set to true if you are using Internet Explorer
    },
};

export const loginRequest = {
    scopes: ['api://97cd06f6-c9c6-46da-8859-be54b0eda196/access_as_user'], // Add scopes here as needed
    prompt: "select_account"
};
