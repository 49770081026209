import { Alert, Button, Input, Textarea, Typography } from "@material-tailwind/react";
import FAQComponent from "../UIComponents/FAQs/FAQComponent";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { PostSendContactEmail, resetStatusCode } from "../features/ContactEmail/ContactEmailSlice";
import SentArrow from "../Images/SentArrow.png";
import ReactGA from "react-ga4";
import { getCurrentDate } from "../app/common";

export function FAQPage() {
  const accountData = useSelector((state: RootState) => state.AccountSlice);
  const contactData = useSelector((state: RootState) => state.ContactSlice);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [isSuccess, setIsSuccess] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const faqs = [
    {
      id: "1",
      question: t("WhatSoftwareProductsDoYouOffer"),
      answer: t("WhatSoftwareProductsDoYouOfferAnswer"),
    },
    {
      id: "2",
      question: t("WhatIsThePricingStructureForYourSoftware"),
      answer: t("WhatIsThePricingStructureForYourSoftwareAnswer"),
    },
    {
      id: "3",
      question: t("WhatKindOfCustomerSupportDoYouProvide"),
      answer: t("WhatKindOfCustomerSupportDoYouProvideAnswer"),
    },
    {
      id: "4",
      question: t("HowDoIObtainALicense"),
      answer: t("HowDoIObtainALicenseAnswer"),
    },
    {
      id: "5",
      question: t("HowToEstablishB2BSingleSignOn"),
      answer: t("HowToEstablishB2BSingleSignOnAnswer"),
      link: "singleSignOn",
    },
  ];

  const [numberOfQuestions, setNumberOfQuestions] = useState(5);
  const [showLoadMore, setShowLoadMore] = useState(true);

  useEffect(() => {
    ReactGA.send({ hitType: "PageView", page: window.location.pathname + window.location.search, title: getCurrentDate() });
  }, [])

  useEffect(() => {
    if (numberOfQuestions === faqs.length) {
      setShowLoadMore(false);
    }
  }, [])

  useEffect(() => {
    if (accountData.hasUserData) {
      setIsLoggedIn(true);
    }
    else {
      setIsLoggedIn(false);
      setTitle('');
      setMessage('');
      setIsSuccess(true);
    }
  }, [accountData.hasUserData])

  useEffect(() => {
    if (contactData.statusValues.statusCode === '200') {
      setTitle('');
      setMessage('');
      setIsVisible(true);
      setIsSuccess(true);
      setIsDisabled(true);
    }
    else if (contactData.statusValues.statusCode !== '') {
      setIsSuccess(false);
    }
    dispatch(resetStatusCode());
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
      setIsDisabled(false);
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, [contactData.statusValues.statusCode])

  const setTitleOnChange = (title: string) => {
    setTitle(title);
    setTitleError(false);
  }

  const setMessageOnChange = (message: string) => {
    setMessage(message);
    setMessageError(false);
  }

  function handleLoadMore() {
    setNumberOfQuestions(numberOfQuestions + 4);
    if (numberOfQuestions === faqs.length) {
      setShowLoadMore(false);
    }
  }

  function handleSubmitButtonClick(): void {
    if (title === "") {
      setTitleError(true);
    }
    if (message === "") {
      setMessageError(true);
    }
    if (title !== "" && message !== "") {
      dispatch(PostSendContactEmail({ title: title, message: message }))
      setIsSuccess(true);
    }
  }

  return (
    <div className="bg-#FFFFFF">
      <div>
        <div className="mb-[30px] lg:mb-[60px]">
          <Typography className="uppercase bg-#F6F6F6 font-bold text-lg lg:text-2xl 2xl:text-4xl text-center py-[20px]">
            {t("FrequentlyAskedQuestions")}
          </Typography>
        </div>
        <div className="mx-[20px] lg:mx-[100px]">
          {faqs.slice(0, numberOfQuestions).map((faq) => (
            <FAQComponent key={faq.id} faq={faq} />
          ))}
        </div>
        <Typography className="uppercase underline text-#FF8136 font-medium text-base lg:text-2xl text-center py-[30px] cursor-pointer" onClick={() => handleLoadMore()} >
          {showLoadMore && t("LoadMore")}
        </Typography>
      </div>
      <div className="bg-#F6F6F6 pb-[30px] lg:pb-[60px]">
        <div className="pt-[30px] lg:pt-[60px]">
          <Typography className="uppercase font-bold text-lg lg:text-2xl text-center pb-0">
            {t("ContactQuestion")}
          </Typography>
          <Typography className="uppercase font-bold text-lg lg:text-2xl text-center pt-5">
            {t("ContactUsHere")}
          </Typography>
        </div>
        <div className="w-[320px] lg:w-[430px] space-y-2 mx-auto mt-[30px] mb-1">
          {!isLoggedIn &&
            <Typography className="text-sm pl-1">{t("MustBeLoggedIn")}</Typography>
          }
        </div>
        <form className="w-[320px] lg:w-[430px] space-y-2 mx-auto">
          <Input id="title" label={t("Title")} className="w-full h-[40px] bg-white" color="orange" error={titleError} disabled={isDisabled || !isLoggedIn} value={title} onChange={(event) => setTitleOnChange(event.target.value)} />
          <Textarea id="message" label={t("Message")} className="w-full h-[140px] bg-white" color="orange" error={messageError} disabled={isDisabled || !isLoggedIn} value={message} onChange={(event) => setMessageOnChange(event.target.value)} />
          <div className="w-fit mx-auto flex flex-col justify-center items-center">
            {!isSuccess &&
              <Typography className="text-sm mb-1 text-#EA4247">{t("SomethingWentWrong") + " " + t("PleaseTryAgain")}</Typography>
            }
            {
              !isVisible ? <Button id="contactSubmitBtn" disabled={!isLoggedIn} className="bg-#FF8136 shadow-none hover:bg-#FF6104 hover:shadow-none w-[187px] h-[40px] text-base font-medium tracking-[0.8px] p-0" onClick={handleSubmitButtonClick}>
                {t("Submit")}
              </Button> : <Button id="contactSentBtn" disabled={!isLoggedIn} ripple={false} className="bg-#A3B974 cursor-default shadow-none hover:shadow-none w-[187px] h-[40px] text-base font-medium tracking-[0.8px] p-0 flex flex-row items-center justify-center gap-[6px]">
                {t("Sent")}
                <img src={SentArrow}></img>
              </Button>
            }
          </div>
        </form>
      </div>
    </div>
  );
}
