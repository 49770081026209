import { Card, Typography } from "@material-tailwind/react";

interface About {
  photo: string;
  title: string;
  text: string;
}

function AboutCard(props: { aboutCard: About }) {
  return (
    <>
      <Card className="w-[280px] 2xl:w-[400px] 3xl:w-[500px] h-fit mx-auto shadow-none text-#333333">
        <img src={props.aboutCard.photo} className="w-full h-auto" alt="" />
        <Typography className="p-0 uppercase font-bold text-lg 2xl:text-2xl mt-[30px]">
          {props.aboutCard.title}
        </Typography>
        <hr className="h-1 w-[70px] 2xl:w-[140px] rounded bg-#FF6104 border-none mt-1" />
        <Typography className="p-0 w-full mt-[30px] text-base 2xl:text-xl font-light">
          {props.aboutCard.text}
        </Typography>
      </Card>
    </>
  );
}

export default AboutCard;
