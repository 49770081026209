import { Typography } from "@material-tailwind/react";
import { ConvertUnixTimestampToDate, GetUserFullName, ResponsiveWidthProfile } from "../../../app/common";
import { TableWithStripedRows } from "../../../UIComponents/Tables/uiTable";
import { LoadingPage } from "../../CommonPages/loadingPage";
import { DirectorySliceData, GetDirectoryMembersResponse } from "../directoryModel";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export function Directory({ directoryData, statusValues }: DirectorySliceData) {
  const { t } = useTranslation();
  const [directoryMembers, setDirectoryMembersData] = useState<GetDirectoryMembersResponse[]>([]);

  useEffect(() => {
    let members: GetDirectoryMembersResponse[] = [];
    directoryData.directoryMembersData.forEach(member => {
      if (member.directoryId === directoryData.homeDirectoryData.directoryId) {
        members.push(member);
      }
    });
    if (directoryData.directoryMembersData[0].directoryId === directoryData.homeDirectoryData.directoryId) {
      setDirectoryMembersData(members);
    }
  }, [directoryData.directoryMembersData])

  if (statusValues.isLoading || statusValues.isLoading) {
    return LoadingPage(statusValues.isLoading);
  }

  const TABLE_MEMBERS_HEAD = [t("User"), t("FirstName"), t("LastName"), t("Role")];
  const TABLE_DIRECTORIES_HEAD = [t("DirectoryOf"), t("InvitedBy"), t("JoinedOn"), t("Role")];

  return (
    <div id="directory" className={"scroll-mt-[50px] xl:scroll-mt-[70px] 2xl:scroll-mt-[90px] rounded-b-[5px] bg-#FFFFFF h-auto space-y-5 shadow-4s pb-10 " + ResponsiveWidthProfile}>
      <div className="2xl:h-[30px] bg-#FF6104-20 rounded-t-[5px]">
        <Typography variant="lead" className="uppercase font-semibold px-10">
          {t("Directory")}
        </Typography>
      </div>

      <div className="2xl:space-y-5 lg:space-y-2.5 gap-y-10 px-10">
        {/* Inside Directory:*/}
        <div className="flex flex-col lg:flex-row sm:gap-2 md:gap-20 justify-between">
          {/* Left side:*/}

          <div className="flex flex-col md:flex-row">
            <Typography variant="paragraph" className="uppercase text-sm font-semibold">
              {t("DirectoryOf")}
            </Typography>
            <div className="pl-2">
              <Typography variant="small" color="gray" className="text-sm font-light">
                {GetUserFullName(directoryData.directoryMembersData.find((m) => m.userId === directoryData.directoryInfoData.ownerId))} ({directoryData.directoryInfoData.directoryMail})
              </Typography>
            </div>
          </div>
        </div>
        <hr className=" fill-#788090 h-[1px] rounded bg-gray-7802 mx-auto my-2" />

        <div className="flex flex-col gap-3 h-fit max-h-[300px] pb-5">
          <Typography variant="paragraph" className="uppercase text-sm font-semibold">
            {t("MembersOfYourDirectory")}
          </Typography>
          {/* Inside Directory Members:*/}
          <TableWithStripedRows
            tableHead={TABLE_MEMBERS_HEAD}
            tableBody={directoryMembers.map((member) => {
              return {
                itemId: member.userId,
                tableData: [member.email, member.firstName, member.lastName, member.role],
              };
            }
            )}
          />
        </div>

        <div className="flex flex-col gap-3 h-fit max-h-[300px]">
          <Typography variant="paragraph" className="uppercase text-sm font-semibold">
            {t("DirectoriesYouAreIn")}
          </Typography>
          {/* Inside All Directories:*/}
          <TableWithStripedRows
            tableHead={TABLE_DIRECTORIES_HEAD}
            tableBody={directoryData.directoryData.map((directory) => {
              return {
                itemId: directory.directoryId,
                tableData: [directory.directoryMail, directory.invitedByFullName, ConvertUnixTimestampToDate(directory.joinedOn), directory.role],
              };
            })}
          />
        </div>
      </div>
    </div>
  );
}
