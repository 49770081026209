import { Card, Typography, Button, } from "@material-tailwind/react";
import { TableWithButtonModel } from "./tableModel";
import { TableDataWidth } from "../../app/common";
import { useTranslation } from "react-i18next";

export function TableWithButton({ tableHead, tableBody, onClickFunction }: TableWithButtonModel) {
    const { t } = useTranslation();
    if (tableBody === undefined || tableBody.length === 0) {
        return <Typography variant="small" color="blue-gray" className=" leading-none text-#D9D9D9">{t("NoData")}</Typography>
    }
    return (
        <div className="overflow-auto">
            <Card className="h-full w-full border-hidden overflow-y-auto ">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {tableHead.map((head) => (
                                <th key={head} className=" bg-#F6F6F6 p-4 font-thin">
                                    <Typography variant="small" color="blue-gray" className=" font-semibold leading-none">
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {tableBody.map(({ tableData, itemId }, index) => (
                            <tr key={index} className="even:bg-blue-gray-50/50">
                                {tableData.map((data, index) => {
                                    const isLast = index === tableData.length - 1;
                                    return (
                                        <td key={data + index} className={"p-4 " + TableDataWidth}>
                                            {isLast === true ? <Button id="tableBtn" className=" bg-#EA4247 hover:bg-#D12026 hover:shadow-none"
                                                onClick={() => onClickFunction(itemId)}>{data}</Button> :
                                                <Typography variant="small" color="blue-gray" className="font-normal">
                                                    {data}
                                                </Typography>
                                            }
                                        </td>
                                    )
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card>
        </div>

    );
}