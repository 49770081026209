import { useEffect, useRef, useState } from "react";
import { BlogComponent } from "../UIComponents/Blogs/blog";
import { Button, Chip, IconButton, Typography } from "@material-tailwind/react";
import LeftArrow from "../Images/leftArrow.png";
import RightArrow from "../Images/rightArrow.png";
import LeftEndArrow from "../Images/leftEndArrow.png";
import RightEndArrow from "../Images/rightEndArrow.png.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { GetAllBlogs } from "../features/Blogs/BlogSlice";
import { createSearchParams, useNavigate } from "react-router-dom";
import { GetBlogResponse } from "../features/Blogs/BlogModel";
import { LoadingPage } from "../features/CommonPages/loadingPage";
import ReactGA from "react-ga4";
import { getCurrentDate } from "../app/common";

export function BlogPage() {
  const dispatch = useDispatch<AppDispatch>();
  const blogData = useSelector((state: RootState) => state.BlogsSlice);
  const blogsLength = blogData.blogSliceData.blogData.blogs.length;
  const navigate = useNavigate();

  let sixBlogs: number = 6;
  let nineBlogs: number = 9;
  let twelveBlogs: number = 12;
  let tabletScreenSize: number = 640;
  let fHDScreenSize: number = 1024;
  let twoKScreenSize: number = 1380;
  let fourKScreenSize: number = 2080;

  const startIndexRef = useRef<number>(1);
  const endIndexRef = useRef<number>(1);
  const pagesCountRef = useRef<number>(1);
  const isHiddenBlogRef = useRef<boolean>(false);

  const [active, setActive] = useState(1);
  const [blogs, setBlogs] = useState<GetBlogResponse[]>([]);
  const [blogsToShow, setBlogsToShow] = useState<GetBlogResponse[]>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [blogsPerPage, setBlogsPerPage] = useState<number>(0);

  const { t } = useTranslation();

  const getItemProps = (index: number) =>
  ({
    className:
      active === index
        ? "w-[20px] h-[20px] rounded-[1px] text-[16px] text-#FFFFFF bg-#4E4E4E shadow-none hover:shadow-none"
        : "w-[20px] h-[20px] rounded-[1px] text-[16px] text-#4E4E4E bg-#FFFFFF shadow-none hover:shadow-none hover:bg-transparent active:bg-transparent",
    variant: active === index ? "filled" : "text",
    onClick: () => select(index),
  } as any);

  const next = (step: number) => {
    const newActive = Math.min(active + step, pagesCountRef.current);
    const newStartIndex = (newActive - 1) * blogsPerPage;
    const newEndIndex = Math.min(newStartIndex + blogsPerPage, blogs.length);

    setActive(newActive);

    startIndexRef.current = newStartIndex;
    endIndexRef.current = newEndIndex;

    setBlogsToShow(blogs.slice(newStartIndex, newEndIndex));
  };

  const prev = (step: number) => {
    const newActive = Math.max(active - step, 1);
    const newStartIndex = (newActive - 1) * blogsPerPage;
    const newEndIndex = newStartIndex + blogsPerPage;

    setActive(newActive);

    startIndexRef.current = newStartIndex;
    endIndexRef.current = newEndIndex;

    setBlogsToShow(blogs.slice(newStartIndex, newEndIndex));
  };

  const select = (step: number) => {
    setActive(step);

    startIndexRef.current = (step - 1) * blogsPerPage;
    endIndexRef.current = startIndexRef.current + blogsPerPage;

    setBlogsToShow(blogs.slice(startIndexRef.current, endIndexRef.current));
  };

  function CreateBlogs() {
    return (
      <div className="container flex flex-wrap justify-center gap-[5%] xl:gap-[1%] 2xl:gap-[5%] max-w-[90%] mx-auto mt-[60px]">
        {blogsToShow.map((blog, index) => {
          const isHidden =
            (window.innerWidth < tabletScreenSize && index >= sixBlogs) ||
            (window.innerWidth >= tabletScreenSize && window.innerWidth < fHDScreenSize && index >= sixBlogs) ||
            (window.innerWidth >= fHDScreenSize && window.innerWidth < twoKScreenSize && index >= sixBlogs) ||
            (window.innerWidth >= twoKScreenSize && window.innerWidth < fourKScreenSize && index >= nineBlogs) ||
            (window.innerWidth >= fourKScreenSize && index >= twelveBlogs);
          return (
            <div key={blog.title} className={`flex ${isHidden ? "hidden" : ""}`}>
              <BlogComponent {...blog} />
            </div>
          );
        })}
      </div>
    );
  }

  function CreatePages() {
    const pagesToCreate = [];

    for (let i = 1; i <= pagesCountRef.current; i++) {
      pagesToCreate.push(
        <IconButton key={i} {...getItemProps(i)}>
          {i}
        </IconButton>
      );
    }

    return (
      <div className="flex justify-center items-center gap-2">
        {pagesToCreate}
      </div>
    );
  }

  function PagesReload() {
    if (window.innerWidth < tabletScreenSize) {
      pagesCountRef.current =
        blogData.blogSliceData.blogData.blogs.length <= sixBlogs
          ? 1
          : Math.ceil(blogData.blogSliceData.blogData.blogs.length / sixBlogs);
      setBlogsPerPage(sixBlogs);
    } else if (
      window.innerWidth >= tabletScreenSize &&
      window.innerWidth < fHDScreenSize
    ) {
      pagesCountRef.current =
        blogData.blogSliceData.blogData.blogs.length <= sixBlogs
          ? 1
          : Math.ceil(blogData.blogSliceData.blogData.blogs.length / sixBlogs);
      setBlogsPerPage(sixBlogs);
    } else if (
      window.innerWidth >= fHDScreenSize &&
      window.innerWidth < twoKScreenSize
    ) {
      pagesCountRef.current =
        blogData.blogSliceData.blogData.blogs.length <= sixBlogs
          ? 1
          : Math.ceil(blogData.blogSliceData.blogData.blogs.length / sixBlogs);
      setBlogsPerPage(sixBlogs);
    } else if (
      window.innerWidth >= twoKScreenSize &&
      window.innerWidth < fourKScreenSize
    ) {
      pagesCountRef.current =
        blogData.blogSliceData.blogData.blogs.length <= nineBlogs
          ? 1
          : Math.ceil(blogData.blogSliceData.blogData.blogs.length / nineBlogs);
      setBlogsPerPage(nineBlogs);
    } else if (window.innerWidth >= fourKScreenSize) {
      pagesCountRef.current =
        blogData.blogSliceData.blogData.blogs.length <= twelveBlogs
          ? 1
          : Math.ceil(
            blogData.blogSliceData.blogData.blogs.length / twelveBlogs
          );
      setBlogsPerPage(twelveBlogs);
    }
  }

  const buttonClicked = (id: string) => {
    navigate({
      pathname: "/singleBlogPage",
      search: createSearchParams({ blogId: id.toLowerCase() }).toString(),
    });
  };

  useEffect(() => {
    setBlogs(blogData.blogSliceData.blogData.blogs); //set blogs from blogs slice
    setBlogsToShow(blogData.blogSliceData.blogData.blogs); //set blogsToShow from blogs
    PagesReload();

    const handleResize = () => {
      PagesReload();
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [blogData.blogSliceData.blogData.blogs]);

  useEffect(() => {
    dispatch(GetAllBlogs());
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "PageView", page: window.location.pathname + window.location.search, title: getCurrentDate()});
  }, [])

  return (
    <div className="font-roboto pb-[40px] bg-#FFFFFF">
      <div className="bg-#F6F6F6 h-[60px] xl:2xl:h-[70px] flex justify-center items-center">
        <h1 className="uppercase text-#333333 font-bold sm:text-[18px] lg:text-[20px] xl:text-[24px] 2xl:text-[36px]">
          BLOG
        </h1>
      </div>
      {blogsLength > 0 ?
        <div className="px-[30px] lg:px-[100px] grid grid-cols-1 xl:grid-cols-2 gap-[30px] justify-between mt-[30px] mb-[30px] md:lg:mt-[60px] md:lg:mb-[60px] xl:2xl:mt-[60px] xl:2xl:mb-[60px]">
          <div className="mx-auto">
            <img src={blogData.blogSliceData.blogData.blogs[blogsLength - 1].image} alt="" className="w-full max-h-[300px] xl:max-h-[500px] bg-transparent rounded-[5px]" />
          </div>
          <div className="mx-auto my-[auto]">
            <div className="flex items-center uppercase font-bold text-lg 2xl:text-3xl text-left xl:pb-[20px] tracking-[0.9px] 2xl:tracking-[1.6px]">
              {blogData.blogSliceData.blogData.blogs[blogsLength - 1].tags.map((tag) => (
                <Chip variant="outlined" size="sm" value={tag} color="gray" className="mr-[10px] rounded-[5px] lowercase text-[14px]" key={tag} />
              ))}
              <Typography className="pl-0 uppercase text-#FF6104 text-[16px] 2xl:text-[20px]">
                {blogData.blogSliceData.blogData.blogs[blogsLength - 1].minutesToRead}{" "}
                {t("MinToRead")}
              </Typography>
            </div>
            <div className="text-[18px] md:lg:xl:text-[24px] 2xl:text-[30px] font-bold py-[10px] xl:pb-[10px] tracking-[0.8px] 2xl:tracking-[1.4px]">
              {blogData.blogSliceData.blogData.blogs[blogsLength - 1].title}
            </div>
            <div className="text-[16px] 2xl:text-[20px] font-light tracking-[0.8px] 2xl:tracking-[1.4px]">
              {blogData.blogSliceData.blogData.blogs[blogsLength - 1].description}
            </div>
            <div className="font-light tracking-[0.8px] 2xl:tracking-[1.4px] pt-[20px]">
              <Button id="readMoreBtn" className="bg-#FF8136 hover:bg-#FF6104 text-#FFFFFF flex justify-center items-center text-[16px] 2xl:text-[18px] rounded-[5px] w-[186px] h-[40px] shadow-none hover:shadow-none" onClick={() => buttonClicked(blogData.blogSliceData.blogData.blogs[blogsLength - 1].id)}>
                {t("ReadMore")}
              </Button>
            </div>
          </div>
        </div>
        : <div className="py-40 xl:py-80">
          {LoadingPage(blogData.blogSliceData.statusValues.isLoading)}
        </div>}

      <div className="mx-auto h-[1px] w-[87%] bg-#A0A0A0" hidden={blogData.blogSliceData.statusValues.isLoading}></div>
      <CreateBlogs />
      {
        blogData.blogSliceData.statusValues.isLoading ? <></> :
          <div className="flex justify-center items-center mt-[40px] xl:2xl:mt-[60px]">
            <div className="flex justify-center items-center gap-5 mr-3">
              <Button id="leftEndArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => prev(pagesCountRef.current)} disabled={active === 1}>
                <img src={LeftEndArrow} className="h-[14px] w-[16px]" alt=""></img>
              </Button>
              <Button id="leftArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => prev(1)} disabled={active === 1}>
                <img src={LeftArrow} className="h-[14px] w-[8px]" alt=""></img>
              </Button>
            </div>
            <CreatePages />
            <div className="flex justify-center items-center gap-5 ml-3">
              <Button id="rightArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => next(1)} disabled={active === pagesCountRef.current}>
                <img src={RightArrow} className="h-[14px] w-[8px]" alt=""></img>
              </Button>
              <Button id="rightEndArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => next(pagesCountRef.current)} disabled={active === pagesCountRef.current}>
                <img src={RightEndArrow} className="h-[14px] w-[16px]" alt=""></img>
              </Button>
            </div>
          </div>
      }
    </div>
  );
}
