import { Avatar, Button, Card, CardBody, CardFooter, CardHeader, Chip, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import { BlogPostModel } from "../../features/Blogs/BlogModel";
import { ConvertUnixTimestampToDate } from "../../app/common";

export const BlogPostComponent: React.FC<BlogPostModel> = ({ id, image, tags, minutesToRead, title, text, description, creator, creationTimestampUnix, deleteBlogFunc, editBlogFunc }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const buttonClicked = () => {
        navigate({
            pathname: "/singleBlogPage",
            search: createSearchParams({ blogId: id.toLowerCase() }).toString(),
        });
        window.scrollTo(0, 0);
    }

    return (
        <Card color="transparent" shadow={false} className="w-full transition-transform transform hover:scale-[1.01]">
            <CardHeader color="transparent" floated={false} shadow={false} className="mx-0 my-0 flex items-center gap-5 py-6" >
                <Avatar className="w-[140px] h-[90px] rounded-[5px]" variant="rounded" src={image} alt="blogPost" />
                <div className="flex w-full flex-col gap-2">
                    <div className="flex items-center justify-start gap-5">
                        <Typography className="text-#333333 text-[16px] font-bold flex-nowrap overflow-hidden text-ellipsis max-w-full max-h-[50px] cursor-pointer under hover:underline" onClick={buttonClicked}>
                            {title}
                        </Typography>
                        <Typography className="text-#333333 text-[16px]">
                            {ConvertUnixTimestampToDate(creationTimestampUnix)}
                        </Typography>
                        <Typography className="text-#333333 text-[16px]">
                            {creator}
                        </Typography>
                    </div>
                    <Typography className="text-#333333 text-[14px] flex-nowrap overflow-hidden text-ellipsis max-w-full max-h-[60px]">
                        {description}
                    </Typography>
                </div>
                <div className="flex flex-col justify-center items-end gap-5">
                    <Button id="editBtn" size="sm" ripple={false} className="bg-transparent shadow-none hover:shadow-none cursor-default p-0" onClick={() => editBlogFunc(id)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#4E4E4E shadow-none hover:stroke-#FF6104 hover:shadow-none duration-300 cursor-pointer">
                            <path d="M16.652 3.45506L17.3009 2.80624C18.3759 1.73125 20.1188 1.73125 21.1938 2.80624C22.2687 3.88124 22.2687 5.62415 21.1938 6.69914L20.5449 7.34795M16.652 3.45506C16.652 3.45506 16.7331 4.83379 17.9497 6.05032C19.1662 7.26685 20.5449 7.34795 20.5449 7.34795M16.652 3.45506L10.6872 9.41993C10.2832 9.82394 10.0812 10.0259 9.90743 10.2487C9.70249 10.5114 9.52679 10.7957 9.38344 11.0965C9.26191 11.3515 9.17157 11.6225 8.99089 12.1646L8.41242 13.9M20.5449 7.34795L14.5801 13.3128C14.1761 13.7168 13.9741 13.9188 13.7513 14.0926C13.4886 14.2975 13.2043 14.4732 12.9035 14.6166C12.6485 14.7381 12.3775 14.8284 11.8354 15.0091L10.1 15.5876M10.1 15.5876L8.97709 15.9619C8.71035 16.0508 8.41626 15.9814 8.21744 15.7826C8.01862 15.5837 7.9492 15.2897 8.03811 15.0229L8.41242 13.9M10.1 15.5876L8.41242 13.9" strokeWidth="1.5" />
                            <path d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </Button>
                    <Button id="deleteBtn" size="sm" ripple={false} className="bg-transparent shadow-none hover:shadow-none cursor-default p-0" onClick={() => deleteBlogFunc(id)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="stroke-#333333 shadow-none hover:stroke-#EA4247 hover:shadow-none duration-300 cursor-pointer">
                            <path d="M20.5001 6H3.5" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M18.8337 8.5L18.3737 15.3991C18.1967 18.054 18.1082 19.3815 17.2432 20.1907C16.3782 21 15.0478 21 12.387 21H11.6136C8.95284 21 7.62244 21 6.75743 20.1907C5.89242 19.3815 5.80393 18.054 5.62693 15.3991L5.16699 8.5" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M9.5 11L10 16" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M14.5 11L14 16" strokeWidth="1.5" strokeLinecap="round" />
                            <path d="M6.5 6C6.55588 6 6.58382 6 6.60915 5.99936C7.43259 5.97849 8.15902 5.45491 8.43922 4.68032C8.44784 4.65649 8.45667 4.62999 8.47434 4.57697L8.57143 4.28571C8.65431 4.03708 8.69575 3.91276 8.75071 3.8072C8.97001 3.38607 9.37574 3.09364 9.84461 3.01877C9.96213 3 10.0932 3 10.3553 3H13.6447C13.9068 3 14.0379 3 14.1554 3.01877C14.6243 3.09364 15.03 3.38607 15.2493 3.8072C15.3043 3.91276 15.3457 4.03708 15.4286 4.28571L15.5257 4.57697C15.5433 4.62992 15.5522 4.65651 15.5608 4.68032C15.841 5.45491 16.5674 5.97849 17.3909 5.99936C17.4162 6 17.4441 6 17.5 6" strokeWidth="1.5" />
                        </svg>
                    </Button>
                </div>
            </CardHeader>
        </Card>
    );
}