import { Typography } from "@material-tailwind/react";
import FeatureComponent from "./FeatureComponent";
import { t } from "i18next";

function CalculationToolsFeaturesComponent() {
    const features = [
        {
            id: "1",
            Icon: () => (
                <>
                    <div className="w-[30px] h-[48px] 2xl:w-[50px] 2xl:h-[68px]">
                        <svg width="100%" height="100%" viewBox="0 0 30 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.73569 28.4868C1.57856 26.9835 1 26.2318 1 23.9999C1 21.768 1.57856 21.0163 2.73569 19.5131C5.04615 16.5114 8.921 13.1084 14.6144 13.1084C20.3078 13.1084 24.1826 16.5114 26.4931 19.5131C27.6502 21.0163 28.2288 21.768 28.2288 23.9999C28.2288 26.2318 27.6502 26.9835 26.4931 28.4868C24.1826 31.4884 20.3078 34.8914 14.6144 34.8914C8.921 34.8914 5.04615 31.4884 2.73569 28.4868Z" stroke="#FF6104" strokeWidth="1.5" />
                            <path d="M18.6989 24.0003C18.6989 26.256 16.8703 28.0846 14.6146 28.0846C12.3589 28.0846 10.5303 26.256 10.5303 24.0003C10.5303 21.7446 12.3589 19.916 14.6146 19.916C16.8703 19.916 18.6989 21.7446 18.6989 24.0003Z" stroke="#FF6104" strokeWidth="1.5" />
                            <rect x="14.6152" y="40.3369" width="1.36144" height="6.80719" rx="0.680719" fill="#FF6104" />
                            <rect x="4.89062" y="38.9756" width="1.36144" height="6.80719" rx="0.680719" transform="rotate(33.4461 4.89062 38.9756)" fill="#FF6104" />
                            <rect width="1.36144" height="6.80719" rx="0.680719" transform="matrix(-0.834405 0.551152 0.551152 0.834405 25.2803 38.9756)" fill="#FF6104" />
                            <rect x="15.5576" y="7.66309" width="1.36144" height="6.80719" rx="0.680719" transform="rotate(-180 15.5576 7.66309)" fill="#FF6104" />
                            <rect x="25.2803" y="9.02441" width="1.36144" height="6.80719" rx="0.680719" transform="rotate(-146.554 25.2803 9.02441)" fill="#FF6104" />
                            <rect width="1.36144" height="6.80719" rx="0.680719" transform="matrix(0.834405 -0.551152 -0.551152 -0.834405 4.89062 9.02441)" fill="#FF6104" />
                        </svg>
                    </div>
                </>
            ),
            title: t("IntuitivelyDesignedInterface"),
            text: t("IntuitivelyDesignedInterfaceDescription"),
        },
        {
            id: "2",
            Icon: () => (
                <>
                    <div className="w-[37px] h-[41px] 2xl:w-[57px] 2xl:h-[61px]">
                        <svg width="100%" height="100%" viewBox="0 0 37 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 17.3382C1 11.1311 1 8.0276 1.73282 6.9835C2.46565 5.9394 5.38381 4.9405 11.2201 2.94271L12.3321 2.5621C15.3744 1.5207 16.8955 1 18.4706 1C20.0456 1 21.5668 1.5207 24.6091 2.5621L25.7211 2.94272C31.5574 4.9405 34.4755 5.9394 35.2084 6.9835C35.9412 8.0276 35.9412 11.1311 35.9412 17.3382C35.9412 18.2758 35.9412 19.2925 35.9412 20.395C35.9412 31.3395 27.7126 36.6507 22.5499 38.9059C21.1494 39.5176 20.4492 39.8235 18.4706 39.8235C16.492 39.8235 15.7918 39.5176 14.3913 38.9059C9.22857 36.6506 1 31.3395 1 20.395C1 19.2925 1 18.2758 1 17.3382Z" stroke="#FF6104" strokeWidth="1.5" />
                            <path d="M16.2603 13.3526C17.2438 11.5882 17.7355 10.7061 18.4708 10.7061C19.206 10.7061 19.6977 11.5882 20.6812 13.3526L20.9357 13.809C21.2152 14.3104 21.3549 14.5611 21.5728 14.7265C21.7907 14.8919 22.0621 14.9533 22.6048 15.0761L23.0989 15.1879C25.0088 15.62 25.9637 15.8361 26.1909 16.5666C26.4181 17.2972 25.7671 18.0585 24.465 19.5811L24.1282 19.975C23.7582 20.4076 23.5732 20.6239 23.49 20.8916C23.4067 21.1592 23.4347 21.4478 23.4907 22.0251L23.5416 22.5506C23.7384 24.582 23.8369 25.5978 23.2421 26.0493C22.6473 26.5008 21.7532 26.0891 19.9649 25.2658L19.5023 25.0528C18.9942 24.8188 18.7401 24.7018 18.4708 24.7018C18.2014 24.7018 17.9474 24.8188 17.4392 25.0528L16.9766 25.2658C15.1884 26.0891 14.2943 26.5008 13.6995 26.0493C13.1047 25.5978 13.2031 24.582 13.3999 22.5506L13.4509 22.0251C13.5068 21.4478 13.5348 21.1592 13.4515 20.8916C13.3683 20.6239 13.1833 20.4076 12.8133 19.975L12.4765 19.5811C11.1745 18.0585 10.5234 17.2972 10.7506 16.5666C10.9778 15.8361 11.9328 15.62 13.8426 15.1879L14.3367 15.0761C14.8795 14.9533 15.1508 14.8919 15.3687 14.7265C15.5866 14.5611 15.7263 14.3104 16.0058 13.809L16.2603 13.3526Z" stroke="#FF6104" strokeWidth="1.5" />
                        </svg>
                    </div>
                </>
            ),
            title: t("SecureAndReliableProcess"),
            text: t("SecureAndReliableProcessDescription"),
        },
        {
            id: "3",
            Icon: () => (
                <>
                    <div className="w-[29px] h-[41px] 2xl:w-[49px] 2xl:h-[61px]">
                        <svg width="100%" height="100%" viewBox="0 0 29 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.9303 10.7495C13.668 9.42627 14.0368 8.76465 14.5882 8.76465C15.1396 8.76465 15.5084 9.42627 16.246 10.7495L16.4369 11.0919C16.6465 11.4679 16.7513 11.6559 16.9147 11.78C17.0781 11.904 17.2816 11.9501 17.6887 12.0422L18.0593 12.126C19.4917 12.4501 20.2079 12.6121 20.3783 13.1601C20.5487 13.708 20.0604 14.279 19.0839 15.4209L18.8312 15.7163C18.5538 16.0408 18.415 16.2031 18.3526 16.4038C18.2902 16.6045 18.3111 16.821 18.3531 17.2539L18.3913 17.6481C18.5389 19.1716 18.6127 19.9334 18.1666 20.2721C17.7206 20.6107 17.05 20.302 15.7088 19.6844L15.3618 19.5247C14.9807 19.3492 14.7902 19.2615 14.5882 19.2615C14.3862 19.2615 14.1956 19.3492 13.8145 19.5247L13.4675 19.6844C12.1264 20.302 11.4558 20.6107 11.0097 20.2721C10.5636 19.9334 10.6374 19.1716 10.7851 17.6481L10.8233 17.2539C10.8652 16.821 10.8862 16.6045 10.8238 16.4038C10.7613 16.2031 10.6226 16.0408 10.3451 15.7163L10.0925 15.4209C9.11595 14.279 8.62769 13.708 8.79809 13.1601C8.96848 12.6121 9.68468 12.4501 11.1171 12.126L11.4877 12.0422C11.8947 11.9501 12.0982 11.904 12.2616 11.78C12.4251 11.6559 12.5299 11.4679 12.7395 11.0919L12.9303 10.7495Z" stroke="#FF6104" strokeWidth="1.5" />
                            <path d="M28.1765 14.5882C28.1765 22.0928 22.0928 28.1765 14.5882 28.1765C7.08366 28.1765 1 22.0928 1 14.5882C1 7.08366 7.08366 1 14.5882 1C22.0928 1 28.1765 7.08366 28.1765 14.5882Z" stroke="#FF6104" strokeWidth="1.5" />
                            <path d="M5.56415 26.2354L4.32787 30.7446C3.10815 35.1935 2.49829 37.418 3.31212 38.6357C3.59733 39.0625 3.97994 39.405 4.42392 39.6311C5.69078 40.2761 7.64683 39.2569 11.5589 37.2185C12.8606 36.5402 13.5114 36.2011 14.203 36.1274C14.4593 36.1001 14.7176 36.1001 14.9739 36.1274C15.6655 36.2011 16.3163 36.5402 17.618 37.2185C21.5301 39.2569 23.4861 40.2761 24.753 39.6311C25.197 39.405 25.5796 39.0625 25.8648 38.6357C26.6786 37.418 26.0688 35.1935 24.8491 30.7446L23.6128 26.2354" stroke="#FF6104" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </div>
                </>
            ),
            title: t("StandardizedHeatLoadCalculationProcedure"),
            text: t("StandardizedHeatLoadCalculationProcedureDescription"),
        },
        {
            id: "4",
            Icon: () => (
                <>
                    <div className="w-[41px] h-[41px] 2xl:w-[61px] 2xl:h-[61px]">
                        <svg width="100%" height="100%" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.2012 3.6465C19.1847 1.88217 19.6765 1 20.4117 1C21.1469 1 21.6386 1.88217 22.6222 3.6465L22.8766 4.10296C23.1561 4.60433 23.2958 4.85501 23.5137 5.02041C23.7316 5.18582 24.003 5.24722 24.5457 5.37001L25.0398 5.48181C26.9497 5.91394 27.9046 6.13 28.1318 6.86059C28.359 7.59118 27.708 8.35245 26.406 9.875L26.0691 10.2689C25.6991 10.7016 25.5141 10.9179 25.4309 11.1855C25.3477 11.4531 25.3756 11.7418 25.4316 12.319L25.4825 12.8446C25.6793 14.876 25.7778 15.8917 25.183 16.3432C24.5882 16.7948 23.6941 16.3831 21.9059 15.5597L21.4432 15.3467C20.9351 15.1127 20.681 14.9958 20.4117 14.9958C20.1424 14.9958 19.8883 15.1127 19.3801 15.3467L18.9175 15.5597C17.1293 16.3831 16.2352 16.7948 15.6404 16.3432C15.0456 15.8917 15.144 14.876 15.3409 12.8446L15.3918 12.319C15.4477 11.7418 15.4757 11.4531 15.3925 11.1855C15.3092 10.9179 15.1242 10.7016 14.7543 10.2689L14.4174 9.875C13.1154 8.35245 12.4644 7.59118 12.6916 6.86059C12.9188 6.13 13.8737 5.91394 15.7836 5.48181L16.2777 5.37001C16.8204 5.24722 17.0917 5.18582 17.3096 5.02041C17.5275 4.85501 17.6673 4.60433 17.9467 4.10296L18.2012 3.6465Z" stroke="#FF6104" strokeWidth="1.5" />
                            <path d="M34.8357 12.0293C35.3275 11.1471 35.5733 10.7061 35.9409 10.7061C36.3086 10.7061 36.5544 11.1471 37.0462 12.0293L37.1734 12.2575C37.3132 12.5082 37.383 12.6336 37.492 12.7163C37.6009 12.799 37.7366 12.8297 38.008 12.8911L38.255 12.947C39.2099 13.163 39.6874 13.2711 39.801 13.6364C39.9146 14.0016 39.5891 14.3823 38.9381 15.1436L38.7697 15.3405C38.5847 15.5568 38.4922 15.665 38.4506 15.7988C38.4089 15.9326 38.4229 16.0769 38.4509 16.3656L38.4764 16.6283C38.5748 17.6441 38.624 18.1519 38.3266 18.3777C38.0292 18.6034 37.5821 18.3976 36.688 17.9859L36.4567 17.8794C36.2026 17.7624 36.0756 17.7039 35.9409 17.7039C35.8063 17.7039 35.6792 17.7624 35.4252 17.8794L35.1939 17.9859C34.2997 18.3976 33.8527 18.6034 33.5553 18.3777C33.2579 18.1519 33.3071 17.6441 33.4055 16.6284L33.431 16.3656C33.459 16.0769 33.473 15.9326 33.4313 15.7988C33.3897 15.665 33.2972 15.5568 33.1122 15.3405L32.9438 15.1436C32.2928 14.3823 31.9673 14.0016 32.0809 13.6364C32.1945 13.2711 32.6719 13.163 33.6269 12.947L33.8739 12.8911C34.1453 12.8297 34.281 12.799 34.3899 12.7163C34.4989 12.6336 34.5687 12.5082 34.7085 12.2575L34.8357 12.0293Z" stroke="#FF6104" strokeWidth="1.5" />
                            <path d="M3.77711 12.0293C4.26887 11.1471 4.51475 10.7061 4.88235 10.7061C5.24996 10.7061 5.49584 11.1471 5.98759 12.0293L6.11482 12.2575C6.25456 12.5082 6.32443 12.6336 6.43337 12.7163C6.54232 12.799 6.678 12.8297 6.94936 12.8911L7.19641 12.947C8.15135 13.163 8.62882 13.2711 8.74241 13.6364C8.85601 14.0016 8.5305 14.3823 7.87949 15.1436L7.71107 15.3405C7.52607 15.5568 7.43357 15.665 7.39196 15.7988C7.35035 15.9326 7.36433 16.0769 7.3923 16.3656L7.41776 16.6283C7.51619 17.6441 7.5654 18.1519 7.268 18.3777C6.9706 18.6034 6.52355 18.3976 5.62944 17.9859L5.39813 17.8794C5.14405 17.7624 5.01701 17.7039 4.88235 17.7039C4.74769 17.7039 4.62065 17.7624 4.36658 17.8794L4.13526 17.9859C3.24116 18.3976 2.7941 18.6034 2.4967 18.3777C2.19931 18.1519 2.24852 17.6441 2.34694 16.6284L2.37241 16.3656C2.40037 16.0769 2.41436 15.9326 2.37275 15.7988C2.33113 15.665 2.23864 15.5568 2.05364 15.3405L1.88521 15.1436C1.2342 14.3823 0.908697 14.0016 1.02229 13.6364C1.13589 13.2711 1.61336 13.163 2.56829 12.947L2.81534 12.8911C3.08671 12.8297 3.22239 12.799 3.33133 12.7163C3.44027 12.6336 3.51014 12.5082 3.64989 12.2575L3.77711 12.0293Z" stroke="#FF6104" strokeWidth="1.5" />
                            <path d="M6.82373 36.6949H11.2107C13.1729 36.6949 15.1563 36.8994 17.066 37.2928C20.4441 37.9888 24.0009 38.0731 27.4137 37.5206C29.0964 37.2483 30.7508 36.8318 32.2482 36.109C33.6001 35.4565 35.256 34.5368 36.3683 33.5066C37.479 32.4779 38.6357 30.7943 39.4567 29.4783C40.1607 28.3499 39.8202 26.9654 38.7066 26.1246C37.4697 25.1906 35.6341 25.1907 34.3974 26.125L30.8893 28.775C29.5297 29.8021 28.0446 30.7475 26.2754 31.0297C26.0627 31.0636 25.8398 31.0945 25.6072 31.1214M25.6072 31.1214C25.5372 31.1296 25.4664 31.1373 25.3946 31.1447M25.6072 31.1214C25.8903 31.0608 26.1713 30.8863 26.435 30.6561C27.6835 29.5664 27.7625 27.7298 26.6792 26.513C26.4278 26.2306 26.1337 25.9952 25.8066 25.8001C20.3765 22.5613 11.9279 25.0281 6.82373 28.6478M25.6072 31.1214C25.5364 31.1366 25.4654 31.1447 25.3946 31.1447M25.3946 31.1447C24.3787 31.249 23.1901 31.2759 21.8713 31.1514" stroke="#FF6104" strokeWidth="1.5" strokeLinecap="round" />
                            <rect x="1" y="24.2939" width="5.82353" height="15.5294" rx="1.5" stroke="#FF6104" strokeWidth="1.5" />
                        </svg>
                    </div>
                </>
            ),
            title: t("AdaptiveAndEasyToUse"),
            text: t("AdaptiveAndEasyToUseDescription"),
        },
    ];

    return (
        <>
            <div className="w-full">
                <Typography className="uppercase text-lg 2xl:text-3xl font-bold tracking-[0.9px] text-center my-[30px] lg:my-[60px]">
                    {t("WhatYouGetWithCalculationTools")}
                </Typography>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-[70px] w-fit mx-auto">
                    {features.map((feature) => (
                        <FeatureComponent key={feature.id} feature={feature} />
                    ))}
                </div>
            </div>
        </>
    );
}

export default CalculationToolsFeaturesComponent;
