import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import calculationToolsLogo from "../../Images/calculationToolsLogo.png";
import calculationToolsBg from "../../Images/calculationToolsBg.png"
import buildingProperties from "../../Images/buildingProperties.png"
import createComponents from "../../Images/createComponents.png"
import buildingStructure from "../../Images/buildingStructure.png"
import CalculationToolsFeaturesComponent from "../FeatureComponents/CalculationToolsFeaturesComponent";
import PricesComponent from "../Prices/PricesComponent";
import CalculationToolsFAQsComponent from "../FAQs/CalculationToolsFAQsComponent";
import { ProductsPage } from "../../MainPages/ProductsPage";
import TryNowComponent from "../calculationToolsTryNow/TryNowComponent";

export function CalculationToolsPage() {
    const { t } = useTranslation();

    const first_paragraph = t("AboutCalculationToolsFirstParagraph");
    const secoond_paragraph = t("AboutCalculationToolsSecondParagraph");

    return (
        <>
            <ProductsPage page={"calculationtools"} />
            <div className="max-w-[2560px] mx-auto pt-[100px]">
                <div className="px-[30px] lg:px-[100px] grid grid-cols-1 xl:grid-cols-2 mb-[30px] gap-[80px] justify-between">
                    <div className="flex items-centermx-auto relative items-center">
                        <img src={calculationToolsBg} alt="calculationToolsBg" className="w-full rounded-[5px]" />
                        <div className="absolute flex justify-center top-0 bottom-0 left-0 right-0">
                            <div className="flex flex-col mx-auto my-auto items-center space-y-1 md:lg:space-y-4 xl:2xl:space-y-4">
                                <img src={calculationToolsLogo} alt="calculationToolsLogo" className="h-[50px] w-[50px] 2xl:h-[70px] 2xl:w-[70px] mx-auto my-auto" />
                                <Typography className="uppercase text-#FFFFFF font-bold text-[20px] md:lg:text-[31px] xl:2xl:text-[31px]">
                                    Calculation Tools
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto my-auto">
                        <Typography className="uppercase font-bold text-lg 2xl:text-3xl text-left py-[20px] xl:py-0 xl:pb-[20px] tracking-[0.9px] 2xl:tracking-[1.6px]">
                            {t("AboutCalculationTools")}
                        </Typography>
                        <Typography className="text-base 2xl:text-xl font-light tracking-[0.8px] 2xl:tracking-[1.4px]">
                            {first_paragraph}
                            <br />
                            <br />
                            {secoond_paragraph}
                        </Typography>
                    </div>
                </div>
                <div className="px-[20px] lg:px-[100px]">
                    <hr className=" bg-#A0A0A0 my-[30px] lg:my-[60px]" />
                    <Typography className="uppercase font-bold text-lg 2xl:text-3xl text-center my-[30px] lg:my-[60px] py-[20px] xl:py-0 xl:pb-[20px] tracking-[0.9px] 2xl:tracking-[1.6px]">
                        {t("HowDoesTheProcessWithCalculationToolsWork")}
                    </Typography>
                    <div className="px-[30px] lg:px-[100px] grid grid-cols-1 xl:grid-cols-2 mb-[30px] gap-[80px] justify-between py-[20px]">
                        <div className="flex items-center">
                            <img src={buildingProperties} alt="buildingProperties" className="w-full rounded-[5px]" />
                        </div>
                        <div className="mx-auto my-auto">
                            <Typography className="uppercase font-bold text-lg 2xl:text-3xl text-left py-[20px] xl:py-0 xl:pb-[20px] tracking-[0.9px] 2xl:tracking-[1.6px]">
                                {t("EnterBuildingProperties")}
                            </Typography>
                            <Typography className="text-base 2xl:text-xl font-light tracking-[0.8px] 2xl:tracking-[1.4px]">
                                {t("EnterBuildingPropertiesFirstParagraph")}
                            </Typography>
                        </div>
                    </div>
                    <div className="px-[30px] lg:px-[100px] grid grid-cols-1 xl:grid-cols-2 mb-[30px] gap-[80px] justify-between py-[20px]">
                        <div className="mx-auto my-auto">
                            <Typography className="uppercase font-bold text-lg 2xl:text-3xl text-left py-[20px] xl:py-0 xl:pb-[20px] tracking-[0.9px] 2xl:tracking-[1.6px]">
                                {t("CreateSpacesAndComponents")}
                            </Typography>
                            <Typography className="text-base 2xl:text-xl font-light tracking-[0.8px] 2xl:tracking-[1.4px]">
                                {t("CreateSpacesAndComponentsFirstParagraph")}
                            </Typography>
                        </div>
                        <div className="flex items-center">
                            <img src={createComponents} alt="createComponents" className="w-full rounded-[5px]" />
                        </div>
                    </div>
                    <div className="px-[30px] lg:px-[100px] grid grid-cols-1 xl:grid-cols-2 mb-[30px] gap-[80px] justify-between py-[20px]">
                        <div className="flex items-center">
                            <img src={buildingStructure} alt="buildingStructure" className="w-full rounded-[5px]" />
                        </div>
                        <div className="mx-auto my-auto">
                            <Typography className="uppercase font-bold text-lg 2xl:text-3xl text-left py-[20px] xl:py-0 xl:pb-[20px] tracking-[0.9px] 2xl:tracking-[1.6px]">
                                {t("ExportPdfFileAndGenerateReport")}
                            </Typography>
                            <Typography className="text-base 2xl:text-xl font-light tracking-[0.8px] 2xl:tracking-[1.4px]">
                                {t("ExportPdfFileAndGenerateReportFirstParagraph")}
                            </Typography>
                        </div>
                    </div>
                    <hr className=" bg-#A0A0A0 my-[30px] lg:my-[60px]" />
                    <CalculationToolsFeaturesComponent />
                    <hr className=" bg-#A0A0A0 my-[30px] lg:my-[60px]" />
                    <PricesComponent familyTypeValue="CalculationTools" />
                    <hr className=" bg-#A0A0A0 my-[30px] lg:my-[60px]" />
                    <TryNowComponent />
                    <hr className=" bg-#A0A0A0 my-[30px] lg:my-[60px]" />
                    <CalculationToolsFAQsComponent />
                    <hr className="invisible my-[15px] lg:my-[30px]" />
                </div>
            </div>
        </>
    );
}