import { Button, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import tryDemoBg from "../../Images/TryDemoBg.jpg";
import tgaLogo from "../../Images/tgaLogoNew.png";

interface TryDemoProps {
    isButtonDisabled: boolean,
    onTryDemoClickFunction: () => void
}

function TryDemoComponent(props: TryDemoProps) {
    const { t } = useTranslation();

    return (
        <div className="mx-auto relative h-[150px] xl:h-[90px] w-full shadow-4s">
            <img src={tryDemoBg} alt="TryDemoBg" className="object-cover h-full w-full rounded-[5px]" />
            <div className="absolute flex flex-row xl:flex-row justify-center xl:justify-between top-0 bottom-0 left-0 right-0">
                <div className="flex flex-row w-full items-center justify-center gap-5 mx-3">
                    <img src={tgaLogo} alt="" className="h-[98px] w-[84px] xl:h-[70px] xl:w-[60px]" />
                    <div className="flex flex-col xl:flex-row items-center w-fit justify-center gap-2 xl:gap-10">
                        <Typography className="uppercase text-#282828 font-normal text-[26px] xl:text-[16px] 2xl:text-[20px] leading-[26px] xl:leading-[16px] 2xl:leading-[20px]">
                            TGA {t("Concept")}
                        </Typography>
                        <hr className="bg-#282828 w-[1px] hidden xl:block xl:py-[30px]" />
                        <Typography className="uppercase text-#282828 font-medium text-[10px] xl:text-[20px] 2xl:text-[26px] leading-[10px] xl:leading-[20px] 2xl:leading-[26px]">
                            {t("TheUltimateDWGAndPDFDrawingTool")}
                        </Typography>
                        <Button id="tryDemoBtn" onClick={props.onTryDemoClickFunction} className="bg-#FF8136 shadow-none hover:bg-#FF6104 hover:shadow-none w-fit h-[30px] xl:h-[40px] text-[12px] xl:text-[14px] leading-[12px] xl:leading-[14px] font-bold p-0 rounded-[5px] px-5">
                        { props.isButtonDisabled === false ? t("Login") : t("TryDemo")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TryDemoComponent;
