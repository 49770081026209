import { Button, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import tgaDocumentationBg from "../../Images/TgaDocumentationBg.jpg";

interface TgaDocumentationProps {
    onViewDocumentationClickFunction: () => void
}

function TgaDocumentationComponent(props: TgaDocumentationProps) {
    const { t } = useTranslation();

    return (
        <div className="mx-auto relative h-[150px] xl:h-[90px] w-full shadow-4s">
            <img src={tgaDocumentationBg} alt="TryDemoBg" className="object-cover h-full w-full rounded-[5px]" />
            <div className="absolute flex flex-row xl:flex-row justify-center xl:justify-between top-0 bottom-0 left-0 right-0">
                <div className="flex flex-col xl:flex-row items-center w-full justify-center gap-2 px-[40px] xl:gap-10">
                    <Typography className="uppercase xl:whitespace-nowrap text-#FFFFFF font-normal text-[24px] xl:text-[16px] 2xl:text-[22px] leading-[24px] xl:leading-[16px] 2xl:leading-[20px]">
                        TGA {t("Documentation")}
                    </Typography>
                    <hr className="bg-#FFFFFF w-[1px] hidden xl:block xl:py-[30px]" />
                    <Typography className="normal-case text-#FFFFFF font-medium text-[12px] xl:text-[16px] 2xl:text-[20px] leading-[12px] xl:leading-[16px] 2xl:leading-[20px]">
                        {t("TgaDocumentationDescription")}
                    </Typography>
                    <Button id="viewDocumentationBtn" onClick={props.onViewDocumentationClickFunction} className="bg-#FF8136 shadow-none hover:bg-#FF6104 hover:shadow-none w-fit h-[30px] xl:h-[40px] text-[12px] xl:text-[14px] leading-[12px] xl:leading-[14px] font-bold p-0 rounded-[5px] px-5">
                        {t("ViewDocumentation")}
                    </Button>
                </div>
            </div>
        </div >
    );
}

export default TgaDocumentationComponent;
