import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { postResetPassword } from "../authentificationSlice";
import { CompletePasswordResetRequest } from "../authModel";
import { useLocation } from "react-router-dom";
import { ValidateEmail, ValidatePassword, ValidatePasswordContent } from "../../../app/validation";
import { LoadingPage } from "../../CommonPages/loadingPage";
import { Alert, Dialog, Typography } from "@material-tailwind/react";
import ChangePasswordCard from "./changePasswordCard";
import SuccessCard from "../../CommonPages/successCard";
import { useTranslation } from "react-i18next";

export function ResetPassword() {
    const [hideMail, setHideMail] = useState(false);
    const [errorMesssage, setErrorMesssage] = useState('');
    const [messsage, setMesssage] = useState('');
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => setOpenDialog(!openDialog);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const accountData = useSelector((state: RootState) => state.AccountSlice);
    const authentificationData = useSelector((state: RootState) => state.AuthentificationSlice);


    useEffect(() => {
        if (accountData.accountSliceData.accountData?.loggedUserData?.email !== '' && accountData.accountSliceData.accountData?.loggedUserData?.email !== 'undefined'
            && accountData.accountSliceData.accountData?.loggedUserData?.email !== null) {
            setHideMail(true)
        }
        else {
            setHideMail(false)
        }
    }, [])

    const submitFunction = (email: string, newPassword: string, confirmNewPassword: string) => {
        let mailToValid = hideMail ? accountData.accountSliceData.accountData?.loggedUserData?.email : email;

        if (!ValidateEmail(mailToValid)) {
            setErrorMesssage(t("YouHaveEnteredAnInvalidEmailAddress"));
            setOpen(true);
        }
        else if (newPassword === "" && confirmNewPassword === "") {
            setErrorMesssage(t("InsertBothPasswords"));
            setOpen(true);
        }
        else if (!ValidatePassword(newPassword, confirmNewPassword)) {
            setErrorMesssage(t("PasswordsAreNotTheSame"));
            setOpen(true);
        }
        else if (!ValidatePasswordContent(newPassword)) {
            setErrorMesssage(t("PasswordFormat"));
            setOpen(true);
        }
        else if (!ValidatePasswordContent(confirmNewPassword)) {
            setErrorMesssage(t("PasswordFormat"));
            setOpen(true);
        }
        else {
            let code = params.get('code');
            const resetPassReq: CompletePasswordResetRequest = { username: mailToValid, password: newPassword, code: code ?? '' };
            dispatch(postResetPassword(resetPassReq))
        }
    }

    useEffect(() => {
        if (authentificationData.statusCode === '200') {
            setSuccess(true);
            setMesssage(t("PasswordChanged") ?? t("AnErrorOccurred"));
            handleOpenDialog();
        }
        else if (authentificationData.statusCode !== '') {
            setSuccess(false);
            setMesssage(authentificationData.error ?? t("AnErrorOccurred"));
            handleOpenDialog();
        }
        setOpen(false);
    }, [authentificationData.statusCode])

    if (authentificationData.isLoading === true) {
        return (LoadingPage(authentificationData.isLoading))
    }

    return (
        <div className="flex justify-center m-[5%]">
            <form className="space-y-5">
                {errorMesssage !== "" ?
                    <Alert color="red" open={open} onClose={() => setOpen(false)}>
                        <Typography className="text-[14px] flex-nowrap overflow-hidden text-ellipsis max-w-[320px] max-lg:w-[400px] max-2xl:w-[500px]">
                            {errorMesssage}
                        </Typography>
                    </Alert>
                    : <></>}
                <ChangePasswordCard hasMail={hideMail} onClickFunction={submitFunction} />
            </form>
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
            </Dialog>
        </div>
    );
}
