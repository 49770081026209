import { Button, Dialog, Typography } from "@material-tailwind/react";
import TryDemoComponent from "../TryDemo/tryDemoComponent";
import TgaDocumentationComponent from "../tgaDocumentation/tgaDocumentationComponent";
import TGAFAQsComponent from "../FAQs/TGAFAQsComponent";
import FeaturesComponent from "../FeatureComponents/FeaturesComponent";
import PricesComponent from "../Prices/PricesComponent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import tgaBackground from "../../Images/TGABanner.png";
import tgaLogo from "../../Images/tgaLogoNew.png";
import { GetDownloadApplication, resetStatusValues } from "../../features/Product/productSlice";
import { AssignDemoLicense } from "../../features/Licenses/licensesSlice";
import { useEffect, useState } from "react";
import SuccessCard from "../../features/CommonPages/successCard";
import { ProductsPage } from "../../MainPages/ProductsPage";
import { useNavigate } from "react-router-dom";

const applicationName = "TGA"
const tgaDucumentaionUrl = "https://tgadoc.bimagine.com/introduction/projectManagement";

export function TGAConceptPage() {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const productData = useSelector((state: RootState) => state.ProductSlice);
    const accountData = useSelector((state: RootState) => state.AccountSlice);
    const licensesData = useSelector((state: RootState) => state.LicensesSlice);
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);
    const [success, setSuccess] = useState(false);
    const [messsage, setMessage] = useState("");
    const handleOpenDialog = () => { setOpenDialog(!openDialog); }

    const first_paragraph = t("ProductsPageFirstParagraph");
    const secoond_paragraph = t("ProductsPageSecondParagraph");

    const DownloadClick = () => {
        dispatch(GetDownloadApplication(applicationName))
    }

    const TryDemoClick = () => {
        if (!accountData.hasUserData) {
            //redirect to Log in:
            navigate('/login');
        }
        else
            dispatch(AssignDemoLicense(applicationName))
    }

    const ViewDocumentationClick = () => {
        window.open(tgaDucumentaionUrl, '_blank', "noopener,noreferrer");
    }

    useEffect(() => {
        if (productData.productSliceData.productData !== undefined && productData.productSliceData.productData !== null && productData.productSliceData.statusValues.statusCode === '200') {
            try {
                const blob = productData.productSliceData.productData;
                const url = window.URL.createObjectURL(blob as Blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = applicationName + '.exe'; // specify the desired file name and extension
                document.body.appendChild(a);
                a.click();
                setSuccess(true);
                setMessage(t('DownloadStarted'));
                handleOpenDialog();

                window.URL.revokeObjectURL(url);
            } catch (error) {
                setSuccess(false);
                setMessage(t('AnErrorOccurred'));
                handleOpenDialog();
            }
            dispatch(resetStatusValues())
        }
    }, [productData.productSliceData.productData])

    useEffect(() => {
        if (licensesData.licensesSliceData.statusValues.statusCode === '200') {
            setSuccess(true);
            setMessage(t('DemoLicenseAssigned'));
            handleOpenDialog();
        }
        else if (licensesData.licensesSliceData.statusValues.statusCode !== '') {
            setSuccess(false);
            setMessage(t('AnErrorOccurred'));
            handleOpenDialog();
        }
    }, [licensesData.licensesSliceData.statusValues])

    return (
        <>
            <ProductsPage page={"tgaconcept"} />
            <div className="max-w-[2560px] mx-auto pt-[100px]">
                <div className="px-[30px] lg:px-[100px] grid grid-cols-1 xl:grid-cols-2 mb-[30px] gap-[80px] justify-between">
                    <div className="mx-auto relative">
                        <img src={tgaBackground} alt="tgaBackground" className=" object-cover h-full w-full rounded-[5px]" />
                        <div className="absolute flex justify-center top-0 bottom-0 left-0 right-0">
                            <div className="flex flex-col mx-auto my-auto items-center space-y-1 md:lg:space-y-4 xl:2xl:space-y-4">
                                <img src={tgaLogo} alt="" className="h-[70px] w-[60px] mx-auto my-auto" />
                                <Typography className="uppercase text-#FFFFFF font-bold text-[20px] md:lg:text-[31px] xl:2xl:text-[31px]">
                                    TGA {t("Concept")}
                                </Typography>
                                <Typography className="uppercase text-#FFFFFF font-medium text-[10px] md:lg:text-[12px] xl:2xl:text-[12px] pb-2">
                                    {t("TheUltimateDWGAndPDFDrawingTool")}
                                </Typography>
                                <Button id="downloadNowBtn" disabled={productData.productSliceData.statusValues.isLoading} onClick={DownloadClick} className="bg-#FF8136 shadow-none hover:bg-#FF6104 hover:shadow-none w-[140px] h-[30px] md:lg:w-[162px] xl:2xl:2-[162px] md:lg:h-[40px] xl:2xl:h-[40px] text-[12px] md:lg:text-[14px] xl:2xl:text-[14px] leading-[12px] md:leading-[14px] font-bold p-0 rounded-[5px]">
                                    {t("DownloadNow")}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto my-auto">
                        <Typography className="uppercase font-bold text-lg 2xl:text-3xl text-left py-[20px] xl:py-0 xl:pb-[20px] tracking-[0.9px] 2xl:tracking-[1.6px]">
                            {t("AboutTgaConcept")}
                        </Typography>
                        <Typography className="text-base 2xl:text-xl font-light tracking-[0.8px] 2xl:tracking-[1.4px]">
                            {first_paragraph}
                            <br />
                            <br />
                            {secoond_paragraph}
                        </Typography>
                    </div>
                </div>
                <div className="px-[20px] lg:px-[100px]">
                    <hr className=" bg-#A0A0A0 my-[30px] lg:my-[60px]" />
                    <FeaturesComponent />
                    <hr className="bg-#A0A0A0 my-[30px] lg:my-[60px]" />
                    <PricesComponent familyTypeValue="TGA" />
                    <div className="flex items-center my-[30px] lg:my-[60px]">
                        <hr className="flex-grow h-px bg-#A0A0A0" />
                        <div className="mx-5 text-#333333 text-base font-medium uppercase">{t("Or")}</div>
                        <hr className="flex-grow h-px bg-#A0A0A0" />
                    </div>
                    <TryDemoComponent isButtonDisabled={accountData.hasUserData} onTryDemoClickFunction={TryDemoClick} />
                    <hr className="bg-#A0A0A0 my-[30px] lg:my-[60px]" />
                    <TgaDocumentationComponent onViewDocumentationClickFunction={ViewDocumentationClick} />
                    <hr className="bg-#A0A0A0 my-[30px] lg:my-[60px]" />
                    <TGAFAQsComponent />
                    <hr className="invisible my-[15px] lg:my-[30px]" />
                </div>
                <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0.9, y: -100 },
                }}>
                    <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
                </Dialog>
            </div>
        </>
    );
}