import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RejectedValue, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { apiAddress } from "../../app/apiModel";
import { ErrorResponse } from "../Authentification/authModel";
import { Blob } from "buffer";
import { ProductSliceData } from "./productModel";

export const GetDownloadApplication = createAsyncThunk<Blob, string, { rejectValue: RejectedValue }>(
    "ProductSlice/GetDownloadApplication",
    async (applicationName: string, { rejectWithValue }) => {

        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem("webShopBimTAccessToken");
        requestHeaders.set("Authorization", "Bearer " + accessToken);
        requestHeaders.set("Content-Type", "application/octet-stream")

        const response = await fetch(apiAddress + "app/download?applicationName=" + applicationName,
            {
                method: "GET",
                headers: requestHeaders,
            }
        );

        if (response.status === 200) {
            return await response.blob() as Blob;
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status, });
        }
        else {
            const errorResponse = (await response.json()) as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
        }
    }
);

const productSliceData: ProductSliceData = {
    productData: {
        size: 0,
        type: "",
        arrayBuffer: function (): Promise<ArrayBuffer> {
            throw new Error("Function not implemented.");
        },
        slice: function (start?: number | undefined, end?: number | undefined, type?: string | undefined): Blob {
            throw new Error("Function not implemented.");
        },
        text: function (): Promise<string> {
            throw new Error("Function not implemented.");
        },
        stream: function (): unknown {
            throw new Error("Function not implemented.");
        }
    },
    statusValues: {
        statusCode: "",
        isLoading: false,
        error: ""
    }
}

export const ProductSlice = createSlice({
    name: "ProductSlice",
    initialState: { productSliceData },
    reducers: {
        resetStatusValues: (state) => {
            state.productSliceData.statusValues.error = "";
            state.productSliceData.statusValues.statusCode = "";
        }

    },
    extraReducers(builder) {
        builder
            //GetDownloadApplication
            .addCase(GetDownloadApplication.fulfilled, (state, action) => {
                state.productSliceData.productData = handleFulfilled(state.productSliceData.statusValues, action.payload);
                state.productSliceData.statusValues.statusCode = '200'
            })
            .addCase(GetDownloadApplication.pending, (state, action) => {
                handlePending(state.productSliceData.statusValues);
            })
            .addCase(GetDownloadApplication.rejected, (state, action) => {
                handleRejected(state.productSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
            })
    },
});

export default ProductSlice.reducer;
export const { resetStatusValues } = ProductSlice.actions;

