import { useEffect, useRef, useState } from "react";
import { PriceUpdates, UpdatePaymentIntervalRequest, UpdateSubscriptionProps } from "../stripeModel";
import { Button, Card, CardBody, Select, Typography, Option } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { PostUpdatePaymentInterval } from "../stripeSlice";

export function UpdateSubscription({ priceUpdates, productUpdates, onClickFunction }: UpdateSubscriptionProps) {

    const { t } = useTranslation();
    const [allPriceUpdates, setAllPriceUpdates] = useState<PriceUpdates[]>([])
    const selectedPriceUpdate = useRef('');
    const selectedProductUpdate = useRef('');
    const dispatch = useDispatch<AppDispatch>();

    const onUpdatePrice = () => {
        let priceToUpdate = allPriceUpdates.find(x => x.priceId === selectedPriceUpdate.current)
        if (priceToUpdate !== undefined) {
            let priceUpdate: UpdatePaymentIntervalRequest = {
                subscriptionItemId: priceUpdates.subscription.subscriptionItemId,
                newPriceId: priceToUpdate.priceId
            }
            dispatch(PostUpdatePaymentInterval(priceUpdate))
            onClickFunction();
        }
    }

    const onUpdateProduct = () => {
    }

    const setProductChoiceForGivenData = (choice: string | undefined): void => {
        selectedProductUpdate.current = choice ? choice : ''
    };
    const setPriceChoiceForGivenData = (choice: string | undefined): void => {
        selectedPriceUpdate.current = choice ? choice : ''
    };

    let mapedPriceUpdates: PriceUpdates[] = [];
    useEffect(() => {
        mapedPriceUpdates = [];
        priceUpdates.availablePriceUpdates.forEach(priceUpdate => {
            let price = priceUpdate.currencyOptions.find(x => x.currency === priceUpdates.subscription.userCurrency)
            let displayedPriceData = priceUpdate.intervalCount + " " + priceUpdate.interval + " - " + price?.unitAmount + price?.currency
            let updatePrice: PriceUpdates = {
                priceId: priceUpdate.id,
                subscriptionItemId: priceUpdates.subscription.subscriptionItemId,
                displayedData: displayedPriceData,

            }
            mapedPriceUpdates.push(updatePrice)
        });
        setAllPriceUpdates(mapedPriceUpdates)

    }, [priceUpdates])

    return (
        <div>
            <Card >
                <CardBody>
                    <Typography className="text-2xl 2xl:text-3xl font-bold uppercase text-center mt-[30px]">
                        {t("UpdateSubscriptionIntervalOrTier")}
                    </Typography>
                    <div className=" space-y-5">
                        {/* <div className="flex flex-col space-y-3">
                            <Typography className="text-base 2xl:text-xl font-light w-fit mt-[20px] ">
                                {t("AvailableProductUpdates")}:
                            </Typography>
                            <div className="flex flex-row space-x-2">
                                <Select onChange={(choice) => setProductChoiceForGivenData(choice)} label={t("SelectProduct")}>
                                    {allPriceUpdates.map((data, index) => (
                                        <Option value={data.priceId} key={index}>{data.displayedData}</Option>
                                    ))}
                                </Select>
                                <Button onClick={onUpdateProduct} className="bg-#FF8136 hover:bg-#FF6104 h-[40px] w-[140px] font-extralight p-0 uppercase shadow-none hover:shadow-none">
                                    {t("Update")}
                                </Button>
                            </div>

                        </div> */}
                        <Typography className="text-base 2xl:text-xl font-light w-fit mt-[20px] text-center">
                            {t("AvailablePriceUpdates")}:
                        </Typography>
                        <div className="flex flex-row space-x-2">
                            <Select id="selectPrice" onChange={(choice) => setPriceChoiceForGivenData(choice)} label={t("SelectPrice")}>
                                {allPriceUpdates.map((data, index) => (
                                    <Option id={index.toString()} value={data.priceId} key={index}>{data.displayedData}</Option>
                                ))}
                            </Select>
                            <Button id="updatePriceBtn" onClick={onUpdatePrice} className="bg-#FF8136 hover:bg-#FF6104 h-[40px] w-[140px] font-extralight p-0 uppercase shadow-none hover:shadow-none">
                                {t("Update")}
                            </Button>
                        </div>

                    </div>

                </CardBody>

            </Card>
        </div>
    )
}