import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface FAQProps {
  id: string;
  question: string;
  answer: string;
  link?: string;
}

interface IconProps {
  open: boolean;
  id: string;
}

function Icon(props: IconProps) {
  return props.open ? (
    <>
      <svg id={props.id} width="100%" height="100%" viewBox="0 0 13 2" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <rect y="2" width="2" height="13" rx="1" transform="rotate(-90 0 2)" fill="#FF6104" />
      </svg>
    </>
  ) : (
    <>
      <svg id={props.id} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13 13" fill="none" >
        <path d="M12.0714 7.42857H7.42857V12.0714C7.42857 12.3177 7.33074 12.5539 7.1566 12.728C6.98246 12.9022 6.74627 13 6.5 13C6.25373 13 6.01754 12.9022 5.8434 12.728C5.66926 12.5539 5.57143 12.3177 5.57143 12.0714V7.42857H0.928571C0.682299 7.42857 0.446113 7.33074 0.271972 7.1566C0.0978315 6.98246 0 6.74627 0 6.5C0 6.25373 0.0978315 6.01754 0.271972 5.8434C0.446113 5.66926 0.682299 5.57143 0.928571 5.57143H5.57143V0.928571C5.57143 0.682299 5.66926 0.446113 5.8434 0.271972C6.01754 0.0978311 6.25373 0 6.5 0C6.74627 0 6.98246 0.0978311 7.1566 0.271972C7.33074 0.446113 7.42857 0.682299 7.42857 0.928571V5.57143H12.0714C12.3177 5.57143 12.5539 5.66926 12.728 5.8434C12.9022 6.01754 13 6.25373 13 6.5C13 6.74627 12.9022 6.98246 12.728 7.1566C12.5539 7.33074 12.3177 7.42857 12.0714 7.42857Z" fill="#FF6104" />
      </svg>
    </>
  );
}

function FAQComponent(props: { faq: FAQProps }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="flex border-b-2 border-b-#ECECEC">
        <Accordion open={open} className="border-none outline-none">
          <div className="flex items-center cursor-pointer">
            <div onClick={() => setOpen(!open)} className="mb-1 w-[16px] h-[16px] 2xl:w-[20px] 2xl:h-[20px]">
              <Icon id={props.faq.id} open={open} />
            </div>
            <AccordionHeader onClick={() => setOpen(!open)} className="border-none outline-none ml-[13px] py-[20px] text-#333333 text-base 2xl:text-xl font-semibold">
              {props.faq.question}
            </AccordionHeader>
          </div>
          <AccordionBody className="border-l-2 border-l-#FF6104 py-1 pl-[10px] ml-[26px] mb-[20px] text-sm 2xl:text-lg font-light whitespace-pre-line pr-10">
            {props.faq.answer + ' '}
            {props.faq.link && <a className="underline cursor-pointer hover:text-#FF8136" href={props.faq.link!}>{t("Link")}</a>}
          </AccordionBody>
        </Accordion>
      </div>
    </>
  );
}

export default FAQComponent;
