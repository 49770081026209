import { Button, Card, CardBody, CardFooter, Input, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ActivateAccountCard: React.FC<ActivateAccountCardCardProps> = ({ onClickFunction, setEmailForm }) => {
    const [email, setEmail] = useState('');
    const { t } = useTranslation();

    const handleActivateAccountClick = () => {
        onClickFunction(email);
    };

    const setEmailOnChange = (email: string) => {
        setEmail(email);
        setEmailForm(email);
    };

    return (
        <Card shadow={false} className="w-[320px] lg:w-[400px] 2xl:w-[500px] h-[240px] lg:h-[250px] 2xl:h-[260px] px-[20px] lg:px-[25px] shadow-4s">
            <CardBody className="p-0">
                <Typography className="uppercase mt-[40px] text-xl 2xl:text-2xl font-bold text-#333333 text-center">
                    {t("ActivateAccount")}
                </Typography>
                <div className="mt-[28px] mb-[35px]">
                    <Input id="email" label={t("Email")} className="w-full h-[40px]" color="orange" onChange={(e) => setEmailOnChange(e.target.value)} />
                </div>
            </CardBody>
            <CardFooter className="p-0">
                <Button id="activateBtn" className="bg-#FF8136 hover:bg-#FF6104 h-[40px] w-full text-base 2xl:text-lg font-light p-0 uppercase shadow-none hover:shadow-none" onClick={handleActivateAccountClick}>
                    {t("Activate")}
                </Button>
            </CardFooter>
        </Card>
    );
}

export default ActivateAccountCard;

interface ActivateAccountCardCardProps {
    onClickFunction: (email: string) => void;
    setEmailForm: React.Dispatch<React.SetStateAction<string>>;
}
