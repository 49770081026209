import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SuccessCard from "./successCard";
import { useTranslation } from "react-i18next";

export function TransactionStatusPage() {
    const { t } = useTranslation();
    const [status, setStatus] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        let statusParam = params.get('orderSuccess') ?? '';
        setStatus(statusParam);
    }, [])

    return (
        <div className="w-[320px] h-[314px] lg:w-[400px] mx-auto my-[50px]">
            {status === 'true' ?
                <SuccessCard success={true} text={t("TransactionSuccessfull")} onClickFunction={() => { navigate('/productsPage') }} />
                :
                (status === 'false' ?
                    <SuccessCard success={false} text={t("TransactionNotExecuted")} onClickFunction={() => { navigate('/productsPage') }} />
                    :
                    <SuccessCard success={false} text={t("SomethingWentWrong")} onClickFunction={() => { navigate('/productsPage') }} />
                )
            }
        </div>
    )
}