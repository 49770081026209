import { useTranslation } from "react-i18next";
import { Typography } from "@material-tailwind/react";
import ssoLogin from "../../Images/ssoLogin.png"
import ssoPickAnAccount from "../../Images/ssoPickAnAccount.png"
import ssoPermission from "../../Images/ssoPermission.png"

function SSOPage() {
    const { t } = useTranslation();

    return (
        <>
            <div className="mb-5 font-roboto">
                <Typography className="normal-case bg-#F6F6F6 font-bold text-lg lg:text-2xl 2xl:text-3xl text-center py-[20px] break-words">
                    {t("B2BSingleSignOn")}
                </Typography>
                <div className="px-[30px] lg:px-[100px]">
                    <Typography className="uppercase font-bold text-base lg:text-lg 2xl:text-xl text-start py-[20px] break-words">
                        {t("RegisterB2BTrust")}
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start py-[20px] break-words">
                        {t("B2BFirstParagraph")}
                    </Typography>
                    <div className="py-[20px]">
                        <img src={ssoLogin} alt="login" />
                    </div>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start py-[20px] break-words">
                        {t("B2BSecondParagraph")}
                    </Typography>
                    <Typography className="uppercase font-bold text-base lg:text-lg 2xl:text-xl text-start py-[20px] break-words">
                        {t("Solution")}:
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start break-words">
                        {t("RegistrationOfClientPrincipals")}
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start break-words">
                        https://login.microsoftonline.com/<span className="font-bold">{'{YOUR-TENANT-ID}'}</span>/v2.0/adminconsent?client_id=8fee51d0-dbf6-4644-90e9-602a209cd210&scope=api://97cd06f6-c9c6-46da-8859-be54b0eda196/access_as_user&redirect_uri=https://www.bimagine.com
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start pt-[20px] break-words">
                        {t("RegistrationOfB2BTrust")}
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start break-words">
                        https://login.microsoftonline.com/<span className="font-bold">{'{YOUR-TENANT-ID}'}</span>/v2.0/adminconsent?client_id=97cd06f6-c9c6-46da-8859-be54b0eda196&scope=api://97cd06f6-c9c6-46da-8859-be54b0eda196/access_as_user&redirect_uri=https://www.bimagine.com
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start py-[20px] break-words">
                        {t("YourTenantIdPartOne")}<span className="font-bold">{'{YOUR-TENANT-ID}'}</span>{t("YourTenantIdPartTwo")}
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start py-[20px] break-words">
                        {t("YouShouldSeeMicrosoftWindow")}
                    </Typography>
                    <div className="py-[20px]">
                        <img src={ssoPickAnAccount} alt="pickAnAccount" />
                    </div>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start py-[20px] break-words">
                        {t("PickYourAccountAndLogIn")}
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start break-words">
                        {t("PickYourAccountFirstOption")}
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start break-words">
                        {t("PickYourAccountSecondOption")}
                    </Typography>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start py-[20px] break-words">
                        {t("B2BThirdParagraphOne")}
                        <br />
                        {t("B2BThirdParagraphTwo")}
                        <br />
                        <br />
                        {t("B2BThirdParagraphThree")}
                    </Typography>
                    <div className="py-[20px]">
                        <img src={ssoPermission} alt="permission" />
                    </div>
                    <Typography className="normal-case font-light text-sm lg:text-base 2xl:text-lg text-start py-[20px] break-words">
                        {t("B2BFourthParagraph")}
                    </Typography>
                </div>
            </div >
        </>
    );
}

export default SSOPage;