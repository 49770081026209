import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { PolicyModel, getCurrentDate } from "../app/common";
import { useEffect, useState } from "react";
import i18n from "../i18n";

function PrivacyAndPolicy() {
    const { t } = useTranslation();

    const [renderingPolicyData, setRenderingPolicyData] = useState<PolicyModel[]>([]);

    useEffect(() => {
        ReactGA.send({ hitType: "PageView", page: window.location.pathname + window.location.search, title: getCurrentDate() });
    }, [])

    useEffect(() => {
        if (i18n.language === 'en') {
            setRenderingPolicyData(privacyAndPolicyEn);
        }
        else {
            setRenderingPolicyData(privacyAndPolicyEn);
            // setRenderingPolicyData(privacyAndPolicyDe);
        }
    }, [i18n.language]);

    return (<>
        <div className="mb-5">
            <Typography className="uppercase bg-#F6F6F6 font-bold text-lg lg:text-2xl 2xl:text-3xl text-center py-[20px] break-words">
                {t("PrivacyPolicy")}
            </Typography>
            <div className="px-[30px] lg:px-[100px]">
                {renderingPolicyData.map((terms) => (
                    <>
                        <Typography className="font-bold text-xl py-[20px] break-words">
                            {terms.mainTitle}
                        </Typography>
                        {terms.terms.map((data) => (
                            <div className="py-2">
                                <Typography className="font-semibold mb-2 break-words">
                                    {data.title}
                                </Typography>
                                {data.points.map((point) => (
                                    <Typography className="break-words">
                                        {point}
                                    </Typography>
                                ))}
                            </div>
                        ))}
                    </>
                ))}
            </div>
        </div>
    </>);
}

export default PrivacyAndPolicy;

const privacyAndPolicyEn: PolicyModel[] = [
    {
        mainTitle: "Privacy Policy (As of: 14.10.2024.)",
        terms: [
            {
                title: "Introduction",
                points: [
                    "Welcome to the website https://www.bimagine.com (the “Website”), operated by BTools GmbH (the 'Company', 'we', 'our', or 'us'). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our Website",
                    "We are committed to protecting your privacy and handling your personal data in compliance with international and EU regulations, including the General Data Protection Regulation (GDPR). Please read this Privacy Policy carefully to understand our practices regarding your personal data.",
                ]
            },
            {
                title: "I. Responsible Party ",
                points: [
                  "BTools GmbH",
                  "Wielandplatz 1/Top 27, 1100 Vienna, Austria",
                  "Email: austria@btools.at ",
                  "Telephone: +43 (0)1 6022222",
                ]
            },
            {
                title: "II.	Data protection officer",
                points: [
                    "If you have any questions about data protection or the processing of personal data, please contact:",
                    "BTools GmbH",
                    "Michael Resch",
                    "Wielandplatz 1/Top 27, 1100 Vienna, Austria",
                    "Email: austria@btools.at",
                    "Telephone: +43 (0)1 6022222 "
                ]
            },
            {
                title: "III. Hosting Provider:",
                points: [
                    "Hetzner Online GmbH",
                    "Industriestr. 25, 91710 Gunzenhausen",
                    "Germany ",
                    "You can find out more about data processing by this provider in the privacy policy of https://www.hetzner.com. "
                ]
            },
            {
                title: "IV. Legal Basis for Processing Personal Data",
                points: [
                    "We process your personal data in compliance with the following legal bases, as outlined by the GDPR:",
                    "Article 6(1)(a) GDPR – Consent: Where you have given your explicit consent to the processing of your personal data for one or more specific purposes.",
                    "Article 6(1)(b) GDPR – Contract: Processing is necessary for the performance of a contract to which you are a party or to take steps at your request prior to entering into a contract.",
                    "Article 6(1)(c) GDPR – Legal Obligation: Processing is necessary for compliance with a legal obligation to which we are subject.",
                    "Article 6(1)(f) GDPR – Legitimate Interests: Processing is necessary for the purposes of the legitimate interests pursued by the Company or a third party, except where such interests are overridden by your fundamental rights and freedoms."
                ]
            },
            
            {
                title: "V. Data We Collect",
                points: [
                    "We may collect the following types of data:",
                    "Personal Identification Information: such as your name, email address, telephone number, and any other information you voluntarily provide to us through contact forms or email communications.",
                    "Usage Data: Information about how you use our Website, such as IP address, browser type, pages visited, time spent on pages, and other analytical data.",
                    "Cookies and Tracking Technologies: We use cookies and similar technologies to improve your experience on our Website. For more information, please refer to our Cookie Policy."
                ]
            },
            
            {
                title: "VI. How We Use Your Data",
                points: [
                    "We use the data we collect for the following purposes:",
                    "To provide and maintain the Website.",
                    "To respond to your inquiries and provide customer support.",
                    "To process your requests and manage your user account.",
                    "To communicate with you regarding updates, promotions, and news related to our services.",
                    "To analyze usage data to improve our Website and services.",
                    "To comply with legal obligations and protect our legal rights.",
                    "Data Sharing and Transfers: We may share your personal data in the following situations:",
                    "Service Providers: We may share your information with trusted third-party service providers who assist us in operating our Website, such as hosting services, analytics, and marketing tools, in compliance with GDPR.",
                    "Legal Requirements: We may disclose your data to comply with a legal obligation or in response to a valid request from a public authority.",
                    "Business Transfers: In the event of a merger, acquisition, or asset sale, your personal data may be transferred."
                ]
            },
            
            {
                title: "VII. Data Transfers Outside the EU",
                points: [
                    "Your data may be transferred and processed outside the European Economic Area (EEA), specifically to the United States.",
                    "We ensure that any such transfers are subject to appropriate safeguards, such as Standard Contractual Clauses (SCCs) approved by the European Commission, to provide a similar level of data protection as required by the GDPR."
                ]
            },
            {
                title: "VIII. Data Retention",
                points: [
                    "We retain your personal data only as long as necessary for the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.",
                    "When your data is no longer needed, we will securely delete or anonymize it."
                ]
            },
            {
                title: "IX. Your Rights",
                points: [
                    "As a data subject, you have the following rights under the GDPR:",
                    "Right to Access: You have the right to request access to the personal data we hold about you.",
                    "Right to Rectification: You have the right to request correction of any inaccurate or incomplete data.",
                    "Right to Erasure (Right to be Forgotten): You can request that we delete your personal data under certain conditions.",
                    "Right to Restrict Processing: You have the right to request that we restrict the processing of your data in specific circumstances.",
                    "Right to Data Portability: You have the right to receive a copy of your personal data in a structured, commonly used, and machine-readable format.",
                    "Right to Object: You have the right to object to the processing of your data where we rely on legitimate interests.",
                    "Right to Withdraw Consent: If we process your data based on your consent, you have the right to withdraw that consent at any time.",
                    "To exercise any of these rights, please contact us at austria@btools.at."
                ]
            },
            {
                title: "X. Security Measures",
                points: [
                    "We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk of processing your personal data.",
                    "However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security."
                ]
            },
            {
                title: "XI. Third-Party Links",
                points: [
                    "Our Website may contain links to third-party websites.",
                    "We are not responsible for the content or privacy practices of these websites.",
                    "We encourage you to read the privacy policies of any third-party websites you visit."
                ]
            },
            {
                title: "XII. Changes to This Privacy Policy",
                points: [
                    "We may update this Privacy Policy from time to time.",
                    "Any changes will be posted on this page with an updated effective date.",
                    "Your continued use of our Website after changes are made constitutes your acceptance of the revised Privacy Policy."
                ]
            },
            {
                title: "XIII. Contact Us",
                points: [
                    "If you have any questions or concerns regarding this Privacy Policy or our data processing practices, please contact us at:",
                    "BTools GmbH",
                    "Wielandplatz 1/Top 27,",
                    "1100 Vienna, Austria",
                    "Email: austria@btools.at",
                    "Telephone: +43 (0)1 6022222"
                ]
            },
                                
            
        ]
    },
    {
        mainTitle: "XIV. Agreement to Privacy Policy",
        terms: [
            {
                title: "By using our Website, you agree to the terms of this Privacy Policy.",
                points: [
                    "This Privacy Policy is designed to comply with the GDPR and other relevant international regulations, providing transparency in how personal data is handled.",
                    "It ensures users' rights are respected and outlines the measures taken to protect their information."
                ]
            },
        ]
    },
    // {
    //     mainTitle: "Communication",
    //     terms: [
    //         {
    //             title: "",
    //             points: [
    //                 "If you contact us and communicate by telephone, e-mail, fax or contact form, personal data may be processed.",
    //                 "If you send us inquiries via the contact form, your details from the contact form, including the contact data you provide there, will be stored by us for the purpose of processing the inquiry and in the event of follow-up questions. ",
    //                 "If you contact us by e-mail, telephone or fax, your details from the inquiry form, including the personal data you provide there (name, inquiry), will be stored and processed by us for the purpose of processing the inquiry.",
    //                 "The processing of the data is based on the following legal bases:",
    //                 "•	Art. 6 para. 1 lit. a GDPR (consent): You give us your consent to store your data and to continue to use it for purposes relating to the business case;",
    //                 "•	Art. 6 para. 1 lit. b GDPR (contract): It is necessary for the performance of a contract with you or a processor, such as the telephone provider, or we need to process the data for pre-contractual activities, such as the preparation of an offer;",
    //                 "•	Art. 6 para. 1 lit. f GDPR (legitimate interests): We want to handle customer inquiries and business communication in a professional manner. This requires certain technical facilities, such as email programs, exchange servers and mobile network operators, in order to operate communication efficiently."
    //             ]
    //         },

    //     ]
    // },
    // {
    //     mainTitle: "Web Analytics",
    //     terms: [
    //         {
    //             title: "Google Analytics",
    //             points: [
    //                 "On our website, we use the analysis tool Google Analytics in the version Google Analytics 4 (GA4) of the American company Google Inc. For the European area, the company Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Ireland) is responsible for all Google services.",
    //                 "Google Analytics collects data about your actions on our website. By combining various technologies such as cookies, device IDs and login information, you can be identified as a user across different devices. This allows your activities to be analyzed across platforms.",
    //                 "Google Analytics is a tracking tool that is used to analyze traffic data on our website. These measurements and analyses are based on a pseudonymous user identification number. This number does not contain any personal data such as name or address but is used to assign events to an end device. GA4 uses an event-based model that captures detailed information on user interactions such as page views, clicks, scrolling and conversion events.",
    //                 "Compared to previous versions such as Google Universal Analytics, GA4 uses significantly fewer cookies due to its focus on event-based data. Nevertheless, there are some specific cookies that are used by GA4. These include, for example:",
    //                 "",
    //                 "Name: _ga",
    //                 "Value: 2.1326744211.152112572734-5",
    //                 "Purpose: Analytics.js uses this cookie by default to store the user ID in order to distinguish website visitors.",
    //                 "Expiration date: after 2 years",
    //                 "",
    //                 "Name: _gid",
    //                 "Value: 2.1687193234.152112572734-1",
    //                 "Purpose: This cookie is also used to differentiate between website visitors.",
    //                 "Expiration date: after 24 hours",
    //                 "",
    //                 "Name: _gat_gtag_UA_<property-id>",
    //                 "Value: 1",
    //                 "Purpose: Used to reduce the request rate. If Google Analytics is provided via the Google Tag Manager, this cookie is given the name _dc_gtm_ <property-id>.",
    //                 "Expiration date: after 1 minute ",
    //                 "",
    //                 "Note: This list is not exhaustive, as Google occasionally changes the selection of its cookies. ",
    //                 "",
    //                 "You can prevent the collection and processing of your data by Google by downloading and installing the browser plugin at the following link: https://tools.google.com/dlpage/gaoptout?hl=de.",
    //                 "If you wish to deactivate, delete or manage cookies, you will find links to the instructions for the most common browsers in the 'Cookies' section.",
    //                 "The use of this analysis tool is based on Art. 6 para. 1 lit. a GDPR and, if we ask for your express consent to the transfer of data to a third country, Art. 49 para. 1 lit. a GDPR; consent can be revoked at any time with effect for the future.",
    //                 "There are also EU Commission standard contractual clauses for the transfer of data to the USA. Further information can be found here: https://privacy.google.com/businesses/controllerterms/mccs/.  ",
    //                 "For more information on data processing by Google, we recommend that you read the company's privacy policy at https://policies.google.com/privacy?hl=de. "

    //             ]
    //         },
    //         {
    //             title: "Google Tag Manager",
    //             points: [
    //                 "We use the Google Tag Manager of the American company Google Inc. For the European area, the company Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Ireland) is responsible for all Google services.",
    //                 "Google Tag Manager is a tool with which we can integrate tracking or statistics tools and other technologies into our website. Google Tag Manager itself does not create any user profiles, does not store any cookies and does not carry out any independent analyses. It is only used to manage and deliver the tools integrated via it. However, Google Tag Manager records your IP address, which may also be transmitted to Google's parent company in the USA.",
    //                 "The Google Tag Manager is used on the basis of Art. 6 para. 1 lit. a GDPR and, if we ask for your express consent to the transfer of data to a third country, Art. 49 para. 1 lit. a GDPR. Consent can be revoked at any time with effect for the future."
    //             ]
    //         },
    //         {
    //             title: "Facebook Pixel",
    //             points: [
    //                 "We use the Facebook pixel from Facebook on our website. We have integrated a code for this on our website. The Facebook pixel is a piece of JavaScript code that loads a collection of functions with which Facebook can track your user actions if you have reached our website via Facebook ads. For example, if you purchase a product on our website, the Facebook pixel is activated and stores your actions on our website in one or more cookies. These cookies enable Facebook to match your user data (customer data such as IP address, user ID) with your Facebook account data. Facebook then deletes this data. The data collected is anonymous and cannot be viewed by us and is only used in the context of advertisements. If you are a Facebook user and are logged in, your visit to our website is automatically assigned to your Facebook user account.",
    //                 "Our aim is to show our services and products only to those people who are actually interested in them. With the help of the Facebook pixel, our advertising measures can be better adapted to your wishes and interests. Facebook users (if they have allowed personalized advertising) see suitable advertising. Facebook also uses the data collected for analysis purposes and for its own advertisements.",
    //                 "If you are logged in to Facebook, you can change your settings for advertisements yourself at https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen. If you are not a Facebook user, you can generally manage usage-based online advertising at http://www.youronlinechoices.com/de/praferenzmanagement/. There you have the option of deactivating or activating providers.You can find more information on data protection at Facebook in the company's privacy policy at https://www.facebook.com/policy.php. "
    //             ]
    //         },
    //         {
    //             title: "LinkedIn Insight-Tag ",
    //             points: [
    //                 "We use the conversion tracking tool LinkedIn Insight Tag on our website. The service provider is the American company LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. LinkedIn Ireland Unlimited (Wilton Place, Dublin 2, Ireland) is responsible for data protection aspects in the European Economic Area (EEA), the EU and Switzerland.",
    //                 "LinkedIn also processes your data in the USA. We would like to point out that, in the opinion of the European Court of Justice, there is currently no adequate level of protection for the transfer of data to the USA. This may entail various risks for the legality and security of data processing. ",
    //                 "LinkedIn uses so-called standard contractual clauses (= Art. 46 para. 2 and 3 GDPR) as the basis for the processing of data by recipients in third countries (outside the European Union, Iceland, Liechtenstein, Norway, in particular in the USA) or for the transfer of data there. Standard Contractual Clauses (SCCs) are model contracts provided by the EU Commission and are intended to ensure that your data complies with European data protection standards even if it is transferred to and stored in third countries (such as the USA). ",
    //                 "Through these clauses, LinkedIn undertakes to comply with the European level of data protection when handling your relevant data, even if the data is stored, processed and managed in the USA. These clauses are based on an implementing decision of the EU Commission. ",
    //                 "You can find the decision and the corresponding standard contractual clauses here: https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de. ",
    //                 "Further information on the standard contractual clauses at LinkedIn can be found at https://de.linkedin.com/legal/l/dpa or https://www.linkedin.com/legal/l/eu-sccs.  ",
    //                 "Further information on the LinkedIn Insight Tag can be found at https://www.linkedin.com/help/linkedin/answer/a427660.  ",
    //                 "Further details on the data processed through the use of the LinkedIn Insight tag can also be found in the privacy policy at https://de.linkedin.com/legal/privacy-policy."
    //             ]
    //         },
    //         {
    //             title: "Email Marketing",
    //             points: [
    //                 "If you would like to subscribe to our newsletter, which is offered on the website, we require your e-mail address and information that allows us to verify that you are the owner of the e-mail address provided and that you agree to receive the newsletter. Further data is either not collected or is only collected on a voluntary basis. To process the newsletter, we use newsletter service providers, which are described below.",
    //             ]
    //         },
    //         {
    //             title: "HubSpot",
    //             points: [
    //                 "We use HubSpot to send our newsletter. The provider is HubSpot Germany GmbH, Am Postbahnhof 17, 10243 Berlin, Germany (hereinafter referred to as 'HubSpot'). HubSpot is a service that enables the organization and evaluation of newsletter distribution. The data you provide to receive the newsletter (e.g. e-mail address) is stored on HubSpot's servers in Germany with backups in Ireland.",
    //                 "The newsletters we send via HubSpot enable us to analyze the behavior of the recipients. This includes, for example, analyzing how many recipients have opened the newsletter message and how often links in the newsletters have been clicked on. Conversion tracking can also be used to determine whether a predefined action (e.g. the purchase of a product on our website) has taken place after clicking on a link in the newsletter.",
    //                 "Data processing takes place on the basis of your consent (Art. 6 para. 1 lit. a GDPR). You can revoke your consent at any time with effect for the future by unsubscribing from the newsletter via the link provided in every newsletter message. The legality of the data processing that has already taken place remains unaffected by the withdrawal of your consent.",
    //                 "The data you provide for the newsletter will be stored until you unsubscribe from the newsletter and deleted from the mailing list after you unsubscribe. After you unsubscribe, we or the newsletter service provider may store your e-mail address in a blacklist to prevent future mailings. The data from the blacklist will only be used for this purpose and will not be merged with other data. This serves both your interest and our interest in complying with the legal requirements for sending newsletters (legitimate interest within the meaning of Art. 6 para. 1 lit. f GDPR). Storage in the blacklist is not limited in time."
    //             ]
    //         }
    //     ]
    // },
    // {
    //     mainTitle: "Audio & Video",
    //     terms: [
    //         {
    //             title: "YouTube ",
    //             points: [
    //                 "We have embedded YouTube videos on our website in order to be able to present interesting videos to you directly on our site. YouTube is a video portal and has been a subsidiary of Google since 2006. The video portal is operated by YouTube, LLC, 901 Cherry Ave, San Bruno, CA 94066, USA. When you access a page on our website on which a YouTube video is embedded, your browser automatically establishes a connection to the YouTube or Google servers. Depending on your settings, various data will be transmitted. Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is responsible for all data processing in Europe.",
    //                 "When you visit our pages with embedded YouTube videos, YouTube sets at least one cookie that stores your IP address and our URL. If you are logged into your YouTube account, YouTube can usually assign your interactions on our website to your profile using cookies. This may include data such as the duration of your visit, the bounce rate, your approximate location, technical information such as browser type, screen resolution or your internet service provider. Other data may include contact details, ratings, sharing content on social media or adding videos to your favorites on YouTube.",
    //                 "If you are not signed in to a Google or YouTube account, Google stores data with a unique identifier associated with your device, browser or app. For example, your preferred language setting will be retained. However, a lot of interaction data cannot be stored because fewer cookies are set.",
    //                 "The data collected by Google is stored for different lengths of time. Some data can be deleted by you at any time, other data is automatically deleted after a certain period of time, and other data is stored by Google for a longer period of time. Some data (such as items in 'My activity', photos or documents, products) that are stored in your Google account are stored until you delete them. Even if you are not signed in to a Google Account, you can delete some data associated with your device, browser or app",
    //                 "If you have consented to the processing and storage of your data by embedded YouTube elements, the data processing is based on your consent (Art. 6 para. 1 lit. a GDPR). In principle, your data is also stored and processed on the basis of our legitimate interest (Art. 6 para. 1 lit. f GDPR) in fast and effective communication with you and other customers and business partners.",

    //             ]
    //         },
    //     ]
    // },
    // {
    //     mainTitle: "Plugins & Tools",
    //     terms: [
    //         {
    //             title: "Google reCAPTCHA  ",
    //             points: [
    //                 "Our primary goal is to secure and protect our website in the best possible way for you and for us. To this end, we use Google reCAPTCHA, which is provided by Google Inc. For the European area, the company Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is responsible for all Google services.",
    //                 "The purpose of reCAPTCHA is to check whether the data entered on this website (for example in a contact form) originates from a human or from an automated program. For this purpose, reCAPTCHA analyzes the behavior of website visitors based on various characteristics. This analysis begins automatically as soon as a visitor enters the website. Various information is analyzed (e.g. IP address, time spent on the website and mouse movements made by the user) and the data collected is forwarded to Google.",
    //                 "The reCAPTCHA analyses take place entirely in the background, without website visitors being informed.",
    //                 "The storage and analysis of the data is based on your consent (Article 6(1)(a) GDPR), which you can revoke at any time with effect for the future.",
    //                 "Further information on Google reCAPTCHA can be found in Google's privacy policy and Google's terms of use at the following links: https://policies.google.com/privacy?hl=de and https://policies.google.com/terms?hl=de.  "
    //             ]
    //         },
    //         {
    //             title: "Font Awesome",
    //             points: [
    //                 "On our website we use Font Awesome, a product of the American company Fonticons (307 S. Main St., Suite 202, Bentonville, AR 72712, USA). When you access one of our websites, the web fonts from Font Awesome (in particular icons) are loaded via the Font Awesome Content Delivery Network (CDN). This ensures that texts, fonts and icons are displayed correctly on every device.",
    //                 "In order to load the web fonts, your browser must establish a connection to the servers of Fonticons, Inc. Your IP address is recorded in the process. Font Awesome also collects information about which icon files are downloaded and when. In addition, technical data such as your browser version, screen resolution and the time of the page view are transmitted.",
    //                 "This data is collected and stored for a variety of reasons, including optimizing content delivery networks, detecting and fixing technical errors, protecting against abuse and attacks on CDNs, billing Font Awesome Pro customers, determining the popularity of icons, and collecting information about your computer and software.",
    //                 "If your browser does not allow web fonts, a default font from your computer will be used automatically. As far as we are currently aware, no cookies are set.",
    //                 "The legal basis for the processing of your data by Font Awesome is your consent (Article 6(1)(a) GDPR), which you have given by using Font Awesome. In addition, we have a legitimate interest in using Font Awesome to optimize our online services. The legal basis for this is Article 6(1)(f) GDPR (legitimate interests). However, we only use Font Awesome if you have given your prior consent.",
    //                 "For more information on data processing by Font Awesome and its handling of data, we recommend the privacy policy at https://fontawesome.com/privacy and the help page at https://fontawesome.com/support. "
    //             ]
    //         },
    //         {
    //             title: "Google Fonts Local ",
    //             points: [
    //                 "We use Google Fonts on our website, which are provided by Google Inc. Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is responsible for the European region. We have integrated the Google fonts locally on our web server, not on Google's servers. This means that there is no connection to Google's servers and no data is transferred or stored.",
    //                 "For more information about Google Web Fonts, please visit https://developers.google.com/fonts/faq and read Google's privacy policy at https://policies.google.com/privacy?hl=de. "
    //             ]
    //         },
    //         {
    //             title: "Google Maps ",
    //             points: [
    //                 "We use Google Maps on our website, which is provided by Google Inc. For the European area, the company Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Ireland) is responsible for all Google services. By using Google Maps, we can better display locations and adapt our service to your needs. When you use Google Maps, data is transmitted to Google and stored on Google servers.",
    //                 "In order to use the functions of Google Maps, it is necessary to process your IP address. This information may be transferred to a Google server in the USA and stored there. The provider of this website has no influence on this further data processing. If Google Maps is activated, Google may use Google Web Fonts for the uniform display of fonts. When you access Google Maps, your browser loads the required web fonts into your browser cache in order to display texts and fonts correctly.",
    //                 "The use of Google Maps is based on your consent (Article 6(1)(a) GDPR), and any data transfer to the USA is based on your express consent when activating the plugin (Article 49(1)(a) GDPR). You can withdraw your consent at any time with effect for the future.",
    //                 "Standard contractual clauses of the EU Commission also exist for the transfer of data to the USA. Further details can be found here: https://privacy.google.com/businesses/DSGVOcontrollerterms/ and https://privacy.google.com/businesses/DSGVOcontrollerterms/sccs/.  ",
    //                 "Further information on data processing by Google and the handling of user data can be found in Google's privacy policy: https://policies.google.com/privacy?hl=de. "
    //             ]
    //         },
    //     ]
    // },
    // {
    //     mainTitle: "Payment Providers ",
    //     terms: [
    //         {
    //             title: "Stripe",
    //             points: [
    //                 "We offer the option of processing the payment transaction via the payment service provider Stripe, ℅ Legal Process, 510 Townsend St., San Francisco, CA 94103 (Stripe). This corresponds to our legitimate interest in offering an efficient and secure payment method (Art. 6 para. 1 lit. f GDPR). In this context, we pass on the following data to Stripe insofar as it is necessary for the fulfillment of the contract (Art. 6 para. 1 lit. b. GDPR).",
    //                 "•	Name of the cardholder",
    //                 "•	e-mail address",
    //                 "•	Customer number",
    //                 "•	Order number",
    //                 "•	Bank details",
    //                 "•	Credit card details",
    //                 "•	Credit card expiry date",
    //                 "•	Credit card verification number (CVC)",
    //                 "•	Date and time of the transaction",
    //                 "•	Transaction amount",
    //                 "•	Name of the provider",
    //                 "•	Location",
    //                 "",
    //                 "The processing of the data provided in this section is not required by law or contract. Without the transmission of your personal data, we cannot process a payment via Stripe. ",
    //                 "Stripe assumes a dual role as controller and processor for data processing activities. As a controller, Stripe uses your submitted data to fulfill regulatory obligations. This corresponds to Stripe's legitimate interest (pursuant to Art. 6 para. 1 lit. f GDPR) and serves the performance of the contract (pursuant to Art. 6 para. 1 lit. b GDPR). We have no influence on this process.",
    //                 "Stripe acts as a processor in order to complete transactions within the payment networks. Within the scope of the order processing relationship, Stripe acts exclusively in accordance with our instructions and has been contractually obliged to comply with data protection regulations within the meaning of Art. 28 GDPR.",
    //                 "Stripe has implemented compliance measures for international data transfers. These apply to all global activities where Stripe processes personal data of natural persons in the EU. These measures are based on the EU Standard Contractual Clauses (SCCs).",
    //                 "You can find further information on objection and removal options vis-à-vis Stripe at: https://stripe.com/privacy-center/legal",
    //                 "Your data will be stored by us until payment processing is completed. This also includes the period required for the processing of refunds, receivables management and fraud prevention."
    //             ]
    //         },
    //     ]
    // },
    // {
    //     mainTitle: "Single Sign-On Logins ",
    //     terms: [
    //         {
    //             title: "Microsoft Single Sign-On",
    //             points: [
    //                 "We use the Microsoft Single Sign-On authentication service to log in to our website. This service is provided by Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA.",
    //                 "Microsoft processes data about you, including data that is processed in the USA. Microsoft is an active participant in the EU-US Data Privacy Framework, which regulates the secure and correct transfer of personal data from EU citizens to the USA. Further information on this can be found at https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en. ",
    //                 "In addition, Microsoft uses so-called Standard Contractual Clauses (SCC) in accordance with Article 46, paragraphs 2 and 3 of the General Data Protection Regulation (GDPR). These clauses are model documents provided by the EU Commission to ensure that your data complies with European data protection standards even if it is transferred to and stored in third countries (such as the USA). Through the EU-US Data Privacy Framework and the Standard Contractual Clauses, Microsoft undertakes to comply with the European level of data protection, even if the data is stored, processed and managed in the USA. These clauses are based on an implementing decision of the EU Commission. Further information on the standard contractual clauses at Microsoft can be found at https://learn.microsoft.com/en-us/compliance/regulatory/offering-eu-model-clauses. ",
    //                 "Further details about the processing of your data through the use of Microsoft Single Sign-On can be found in the privacy policy at https://privacy.microsoft.com/de-de/privacystatement. "
    //             ]
    //         },
    //         {
    //             title: "Obligation to provide data",
    //             points: [
    //                 "We require some of the above personal data for the performance of our services or the contract with you and/or to comply with legal obligations to which we are subject. If you refuse to provide personal data that is necessary for the performance of the contract or that we must process due to legal obligations, it may not be possible for us to fulfill these services and/or in particular the above-mentioned purposes and/or it would significantly slow down the relevant purpose."]
    //         },
    //         {
    //             title: "Objection to advertising emails",
    //             points: [
    //                 "We hereby object to the use of the contact data published as part of our obligation to provide a legal notice for the purpose of sending unsolicited advertising emails and information material. The operators of this website expressly reserve the right to take legal action in the event of unsolicited advertising information being sent, for example in the form of spam e-mails."]
    //         },
    //         {
    //             title: "Updates",
    //             points: [
    //                 "We reserve the right to update this notice to reflect legal, technical or business changes. In the event of updates, appropriate measures will be taken to inform you of the changes made. The date of the last update ('as of') can be found at the beginning of this notice."]
    //         },
    //         {
    //             title: "Disclaimer",
    //             points: [
    //                 "The Website contains links to third party websites. We have no control over the content or privacy practices of these other websites. Please read the respective privacy policies of the other websites you visit."
    //             ]
    //         }
    //     ]
    // },
]

const privacyAndPolicyDe: PolicyModel[] = [
    {
        mainTitle: "Datenschutzerklärung (Stand: 21.11.2023.)",
        terms: [
            {
                title: "I. Verantwortlicher",
                points: [
                    "Der Verantwortliche für diese Website ist: BIMEXPERTS GmbH Laxenburger Straße 151a/2.OG, 1100 Wien, Österreich. austria@bimexperts.com",
                    "Hosting Anbieter für diese Website: HubSpot, Inc. 25 First Street, Cambridge, MA 02141 USA",
                    "Mehr über die Datenverarbeitung bei diesem Provider erfahren Sie in der Datenschutzerklärung."
                ]
            },
            {
                title: "II. Datenschutzbeauftragter",
                points: [
                    "Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten sowie für alle personenbezogenen Daten, die von uns beauftragte Unternehmen (Auftragsverarbeiter) verarbeiten. Unter personenbezogenen Daten verstehen wir Informationen gemäß Art. 4 Nr. 1 DSGVO, wie beispielsweise Namen, E-Mail-Adressen und Postanschriften von Personen. Die Verarbeitung personenbezogener Daten ermöglicht es uns, unsere Dienstleistungen und Produkte, sei es online oder offline, anzubieten und abzurechnen. Der Anwendungsbereich dieser Datenschutzerklärung erstreckt sich auf:",
                    "•	Alle Online-Präsenzen (Websites, Online-Shops), die von uns betrieben werden.",
                    "•	Social-Media-Präsenzen und E-Mail-Kommunikation",
                    "•	Mobile Apps für Smartphones und andere Geräte.",
                    "Kurz gesagt: Diese Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten in unserem Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Falls wir außerhalb dieser Kanäle mit Ihnen in rechtliche Beziehungen treten, werden wir Sie gegebenenfalls gesondert informieren.",
                ]
            },
            {
                title: "III. Anwendungsbereich",
                points: [
                    "Falls Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, wenden Sie sich bitte an:",
                    "BIMEXPERTS GmbH",
                    "Michael Resch",
                    "Laxenburger Straße 151a / 2.OG, 1100 Wien, Österreich",
                    "E-Mail: austria@bimexperts.com",
                    "Telefon: +43(0)1 6022222",
                    "Impressum: https://bimexperts.com/impressum",
                ]
            },
            {
                title: "IV. Speicherdauer",
                points: [
                    "Wir speichern personenbezogene Daten nur so lange, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, was als allgemeines Kriterium für uns gilt. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr besteht.",
                    "In einigen Fällen sind wir gesetzlich verpflichtet, bestimmte Daten auch nach dem Wegfall des ursprünglichen Zwecks zu speichern, beispielsweise aus Buchführungszwecken.",
                    "Wenn Sie die Löschung Ihrer Daten wünschen oder Ihre Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so schnell wie möglich gelöscht, sofern keine gesetzliche Aufbewahrungspflicht besteht.",
                    "Weitere Informationen zur konkreten Dauer der jeweiligen Datenverarbeitung finden Sie weiter unten, sofern zusätzliche Informationen verfügbar sind."
                ]
            },
            {
                title: "V. Rechte gemäß Datenschutzgrundverordnung",
                points: [
                    "Gemäß den Bestimmungen der DSGVO und des österreichischen Datenschutzgesetzes (DSG) stehen Ihnen grundsätzlich folgende Rechte zu:",
                    "• Recht auf Berichtigung (Artikel 16 DSGVO)",
                    "• Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)",
                    "• Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)",
                    "• Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)",
                    "• Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)",
                    "• Widerspruchsrecht (Artikel 21 DSGVO)",
                    "• Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)",
                    "Wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche auf andere Weise verletzt wurden, können Sie sich bei der Aufsichtsbehörde beschweren.",
                    "In Österreich ist dies die Datenschutzbehörde, deren Website Sie unter https://www.dsb.gv.at/ finden.",
                    "In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:",
                    "Österreich Datenschutzbehörde",
                    "Adresse: Barichgasse 40-42, 1030 Wien",
                    "Telefonnr.: +43 1 52 152-0",
                    "E-Mail-Adresse: dsb@dsb.gv.at",
                    "Website: https://www.dsb.gv.at/"
                ]
            },
            {
                title: "VI. Datenübertragung in Drittländer",
                points: [
                    "Wir übertragen oder verarbeiten Daten in Drittländern nur dann, wenn Sie in diese Verarbeitung einwilligen oder eine sonstige gesetzliche Erlaubnis vorliegt. Dies ist insbesondere dann der Fall, wenn die Verarbeitung gesetzlich vorgeschrieben ist oder zur Erfüllung eines Vertragsverhältnisses notwendig ist und nur soweit dies allgemein erlaubt ist. Ihre Zustimmung ist in den meisten Fällen der wichtigste Grund für die Verarbeitung von Daten in Drittländern.",
                    "Die Verarbeitung personenbezogener Daten in Drittländern, wie den USA, in denen viele Softwareanbieter Dienstleistungen anbieten und ihre Serverstandorte haben, kann bedeuten, dass personenbezogene Daten in unerwarteter Weise verarbeitet und gespeichert werden. Wir weisen ausdrücklich darauf hin, dass nach Ansicht des Europäischen Gerichtshofs derzeit nur dann ein angemessenes Schutzniveau für den Datentransfer in die USA besteht, wenn ein US-Unternehmen, das personenbezogene Daten von EU-Bürgern in den USA verarbeitet, aktiver Teilnehmer des EU-US-Datenschutzrahmens ist. Weitere Informationen dazu finden Sie unter: https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en"
                ]
            },
            {
                title: "VII. Benutzer und länderspezifische Abweichungen",
                points: [
                    "Diese Datenschutzerklärung richtet sich an Nutzer weltweit."
                ]
            },
        ]
    },
    {
        mainTitle: "Cookies",
        terms: [
            {
                title: "",
                points: [
                    "Diese Website verwendet Cookies. Wir nutzen Cookies vor allem, um Ihre Erfahrung auf unseren Websites zu verbessern und zu analysieren sowie zu Marketingzwecken. Da wir Ihr Recht auf Privatsphäre respektieren, können Sie einige Arten von Cookies ablehnen. ",
                    "Wir verwenden die folgenden Arten von Cookies auf unserer Website:"
                ]
            },
            {
                title: "•	Notwendige Cookies",
                points: [
                    "Diese Cookies sind für die Website erforderlich und können nicht in unseren Systemen deaktiviert werden. Für sie ist in der Regel festgelegt, dass sie nur nach Aktionen platziert werden, bei denen Sie einen Service anfordern, z. B. das Festlegen Ihrer Datenschutzeinstellungen, das Anmelden oder das Ausfüllen von Formularen. Ohne notwendige Cookies wäre das ordnungsgemäße Funktionieren unserer Website nicht oder nur in eingeschränktem Maße möglich. Der Einsatz notwendiger Cookies auf unserer Website ist ohne Ihre Zustimmung möglich. Sie können die Verwendung von Cookies jedoch jederzeit durch eine Änderung Ihrer Browsereinstellungen deaktivieren. Rechtsgrundlage: § 25 Abs. 2 Nr. 2 Telekommunikation-Telemedien-Datenschutz-Gesetz („TTDSG“), Art. 6 Abs. 1 lit. f DSGVO.",
                ]
            },
            {
                title: "•	Analytics Cookies",
                points: [
                    "Diese Cookies helfen uns zu verstehen, wie Besucher mit der Website interagieren. Wir verwenden möglicherweise eine Reihe von Cookies, um Informationen zu erfassen und Berichte mit Statistiken zur Website-Nutzung zu erstellen. Zusätzlich zu diesen Statistiken können erfasste Daten auch verwendet werden, um zusammen mit einigen der beschriebenen Werbe-Cookies weitere relevante Online-Anzeigen zu schalten und die Interaktionen mit der Werbung zu messen, die wir Ihnen zeigen. Analytics Cookies werden nur mit Ihrer Zustimmung verwendet, die Sie erteilen können, indem Sie auf dem Cookie-Banner unserer Website auf „Akzeptieren“ klicken. Diese Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden.",
                ]
            },
            {
                title: "•	Werbeanzeige Cookies",
                points: [
                    "Werbe-Cookies sind Anzeigen-Pixel-Cookies (z. B. Facebook, LinkedIn und Google). Wir verwenden Cookies, um unsere Anzeigen ansprechender und gewinnbringender für Website-Besucher zu gestalten. Cookies werden unter anderen dafür genutzt, um Werbeanzeigen auszuwählen, die von Relevanz für einen Benutzer sind, um die Berichterstattung zur Performance der Anzeigenkampagne zu verbessern und um Anzeigen zu vermeiden, die der Benutzer bereits gesehen hat. Werbeanzeige Cookies werden nur mit Ihrer Zustimmung verwendet, die Sie erteilen können, indem Sie auf dem Cookie-Banner unserer Website auf „Akzeptieren“ klicken. Diese Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden."
                ]
            },
            {
                title: "•	Funktionelle Cookies",
                points: [
                    "Wir verwenden eine Reihe von optionalen Cookies, die für eine ordnungsgemäße Funktionsweise der Website nicht zwingend sind. Diese werden in der Regel nur bei der Bereitstellung von Informationen auf der Website platziert, um Ihr Nutzererlebnis zu personalisieren und optimieren sowie um Ihren Chat-Verlauf zu speichern. Funktionelle Cookies werden nur mit Ihrer Zustimmung verwendet, die Sie erteilen können, indem Sie auf dem Cookie-Banner unserer Website auf „Akzeptieren“ klicken. Diese Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden."
                ]
            },
            {
                title: "Notwendige Cookies",
                points: [
                    "Dies sind notwendige Cookies, die keine Einwilligung erfordern.",

                    "__hs_opt_out",
                    "•	Dieses Cookie wird von der Opt-in-Datenschutzrichtlinie verwendet, um den Besucher zu bitten, Cookies erneut zu akzeptieren.",
                    "•	Es enthält die Zeichenfolge „Ja“ oder „Nein“.",
                    "•	Es ist 6 Monate gültig.",
                    "__hs_opt_out",

                    "__hs_d_not_tracking",
                    "•	Dieses Cookie kann so eingestellt werden, dass der Tracking-Code keine Informationen an HubSpot sendet.",
                    "•	Es enthält die Zeichenfolge „Ja“.",
                    "•	Es ist 6 Monate gültig.",

                    "__hs_initial_opt_",
                    "•	Dieses Cookie wird verwendet, um zu verhindern, dass Banner jedes Mal angezeigt werden, wenn Besucher im strengen Modus die Website besuchen.",
                    "•	Es enthält die Zeichenfolge „Ja“ oder „Nein“.",
                    "•	Es ist sieben Tage gültig.",

                    "__hs_cookie_cat_pref",
                    "•	Dieses Cookie wird verwendet, um die Kategorien zu erfassen, zu denen ein Besucher eingewilligt hat.",
                    "•	Es enthält Daten zu diesen Kategorien.",
                    "•	Es ist 6 Monate gültig.",

                    "__hs_gpc_banner_dismiss",
                    "•	Dieses Cookie wird verwendet, wenn das Banner Global Privacy Control verworfen wird.",
                    "•	Es enthält die Zeichenfolge „Ja“ oder „Nein“.",
                    "•	Es ist 180 Tage gültig.",

                    "hs_ab_test",
                    "•	Dieses Cookie wird verwendet, um Besuchern stets die gleiche Version einer A/B-Testseite anzuzeigen, die bereits zuvor angezeigt wurde.",
                    "•	Es enthält die ID der A/B-Testseite und die ID der für den Besucher ausgewählten Variante.",
                    "•	Es läuft am Ende der Sitzung ab.",

                    "<id>_key",
                    "•	Beim Besuch einer passwortgeschützten Seite wird dieses Cookie gesetzt, damit bei künftigen Besuchen der Seite mit demselben Browser keine Anmeldung mehr erforderlich ist",
                    "•	Der Cookie-Name ist für jede passwortgeschützte Seite eindeutig.",
                    "•	Es enthält eine verschlüsselte Version des Passworts, damit zukünftige Besuche auf der Seite nicht erneut das Passwort verlangen.",
                    "•	Es ist 14 Tage gültig.",

                    "hs-messages-hide-welcome-message",
                    "•	Dieses Cookie sorgt dafür, dass die Willkommensnachricht nach dem Schließen einen Tag lang nicht wieder angezeigt wird.",
                    "•	Es enthält den booleschen Wert Wahr oder Falsch.",
                    "•	Es ist einen Tag gültig.",

                    "hs_langswitcher_choice",
                    "•	Dieses Cookie wird verwendet, um die Sprachauswahl eines Besuchers zu speichern, wenn Seiten in mehreren Sprachen aufgerufen werden.",
                    "•	Es wird gesetzt, wenn ein Besucher eine Sprache vom Sprachumschalter auswählt, und wird als Spracheinstellung zum zukünftigen Weiterleiten des Benutzers zu Websites in dessen ausgewählter Sprache, sofern verfügbar, verwendet.",
                    "•	Es enthält eine durch einen Doppelpunkt getrennte Zeichenfolge mit der ISO639-Sprachcodeauswahl links und der privaten Top-Level-Domain rechts. Ein Beispiel ist „DE-DE:HubSpot.com“.",
                    "•	Es ist zwei Jahre gültig.",

                    "__cfruid",
                    "Dieses Cookie wird durch den CDN-Anbieter von HubSpot aufgrund von dessen Richtlinien für Ratenbeschränkungen festgelegt. Es läuft am Ende der Sitzung ab. Erfahren Sie mehr über Cloudflare-Cookies.",

                    "__cf_bm",
                    "Dieses Cookie wird durch den CDN-Anbieter von HubSpot festgelegt und ist ein notwendiges Cookie für den Bot-Schutz. Es läuft nach 30 Minuten ab. Erfahren Sie mehr über Cloudflare-Cookies."

                ]
            },
            {
                title: "Analytics Cookies",
                points: [
                    "Dies sind nicht unbedingt notwendige Cookies, die vom Cookie-Banner gesteuert werden. Sie können sich von diesen Cookies abmelden, indem Sie keine Einwilligung erteilen.",
                    "__hstc",
                    "•	Das Haupt-Cookie für das Besucher-Tracking.",
                    "•	Es enthält die Domain, das Benutzertoken (HubSpotutk), den ersten Zeitstempel (des ersten Besuchs), den letzten Zeitstempel (des letzten Besuchs), den aktuellen Zeitstempel (für diesen Besuch) und die Sitzungszahl (erhöht sich mit jeder nachfolgenden Sitzung).",
                    "•	Es ist 6 Monate gültig."
                ]
            },
            {
                title: "HubSpotutk",
                points: [
                    "•	Dieses Cookie verfolgt die Identität eines Besuchers. Dieses Cookie wird bei der Einsendung eines Formulars an die HubSpot-Software übergeben und beim De-duplizieren von Kontakten verwendet.",
                    "•	Es enthält eine undurchsichtige GUID, um den aktuellen Besucher darzustellen.",
                    "•	Es enthält die Domain, das Benutzertoken (HubSpotutk), den ersten Zeitstempel (des ersten Besuchs), den letzten Zeitstempel (des letzten Besuchs), den aktuellen Zeitstempel (für diesen Besuch) und die Sitzungszahl (erhöht sich mit jeder nachfolgenden Sitzung).",
                    "•	Es ist 6 Monate gültig.",

                    "__hssc",
                    "•	Dieses Cookie verfolgt Sitzungen.",
                    "•	Es wird verwendet, um zu ermitteln, ob die HubSpot-Software die Sitzungszahl und die Zeitstempel im __hstc-Cookie erhöhen muss.",
                    "•	Es enthält die Domain, die Zahl der Seitenaufrufe (viewCount, erhöht sich mit jedem Seitenaufruf [pageView] in einer Sitzung) und den Sitzungsbeginn-Zeitstempel",
                    "•	Es läuft nach 30 Minuten ab.",

                    "__hssrc",
                    "•	Immer dann, wenn die HubSpot-Software das Sitzungscookie ändert, wird auch dieses Cookie gesetzt. Damit wird bestimmt, ob der Besucher den Browser erneut gestartet hat.",
                    "•	Wenn dieses Cookie nicht vorhanden ist, wenn HubSpot Cookies verwaltet, wird es als neue Sitzung betrachtet.",
                    "•	Es enthält den Wert „1“, wenn vorhanden.",
                    "•	Es läuft am Ende der Sitzung ab."

                ]
            },
            {
                title: "Wie Sie die Verwendung von Cookies kontrollieren und verwalten können",
                points: [
                    "•	Durch Anklicken des „Akzeptieren“-Buttons im Cookie-Banner der Website stimmen Sie der Verwendung der oben aufgeführten optionalen Cookies auf unserer Website zu. Ihre Zustimmung kann (für alle oder einzelne Cookies) jederzeit mit Wirkung für die Zukunft widerrufen werden.",
                    "•	Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen. Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:",
                    "Chrome: Cookies in Chrome löschen, aktivieren und verwalten",
                    "Safari: Verwalten von Cookies und Websitedaten mit Safari",
                    "Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben",
                    "Internet Explorer: Löschen und Verwalten von Cookies",
                    "Microsoft Edge: Löschen und Verwalten von Cookies",
                    "•	Sie sollten sich darüber im Klaren sein, dass alle Präferenzen verloren gehen, wenn Sie alle Cookies löschen, und dass viele Websites nicht mehr richtig funktionieren oder Sie einige Funktionen verlieren werden. Aus diesen Gründen raten wir davon ab, Cookies bei der Nutzung unserer Website zu deaktivieren."

                ]
            },
            {
                title: "Server Log Files",
                points: [
                    "Wir erheben und speichern automatisch Informationen in sogenannten Server Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:",
                    "•	die Adresse (URL) der aufgerufenen Website",
                    "•	Browsertyp und Browserversion",
                    "•	das verwendete Betriebssystem",
                    "•	die Adresse (URL) der zuvor besuchten Seite (Referrer-URL)",
                    "•	den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird",
                    "•	Datum und Uhrzeit",
                    "Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.",
                    "Die Erhebung dieser Daten beruht auf unserem berechtigten Interesse (Art. 6 Abs. 1 lit. f DSGVO) an der technisch fehlerfreien Darstellung und Optimierung unserer Website. Die Verarbeitung dieser Logfiles ist für uns erforderlich, um die Funktionalität, Stabilität und Sicherheit unserer Website aufrechtzuerhalten.",
                    "In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden."
                ]
            },
        ]
    },
    {
        mainTitle: "Kommunikation",
        terms: [
            {
                title: "",
                points: [
                    "Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail, Fax oder Kontaktformular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.",
                    "Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert.",
                    "Wenn Sie per E-Mail, Telefon oder Fax Kontakt mit uns aufnehmen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung der Anfrage bei uns gespeichert und verarbeitet.",
                    "Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:",
                    "•	Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;",
                    "•	Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;",
                    "•	Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können."
                ]
            }
        ]
    },
    {
        mainTitle: "Web Analytics",
        terms: [
            {
                title: "Google Analytics",
                points: [
                    "Auf unserer Webseite verwenden wir das Analysetool Google Analytics in der Version Google Analytics 4 (GA4) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.",
                    "Google Analytics erfasst Daten zu Ihren Handlungen auf unserer Webseite. Durch die Kombination verschiedener Technologien wie Cookies, Geräte-IDs und Anmeldeinformationen können Sie als Benutzer über verschiedene Geräte hinweg identifiziert werden. Dadurch können Ihre Aktivitäten plattformübergreifend analysiert werden.",
                    "Google Analytics ist ein Tracking-Tool, das der Verkehrsdatenanalyse unserer Webseite dient. Die Grundlage dieser Messungen und Analysen bildet eine pseudonyme Nutzeridentifikationsnummer. Diese Nummer enthält keine personenbezogenen Daten wie Name oder Adresse, sondern dient dazu, Ereignisse einem Endgerät zuzuordnen. GA4 verwendet ein ereignisbasiertes Modell, das detaillierte Informationen zu Benutzerinteraktionen wie Seitenaufrufen, Klicks, Scrollen und Konversionsereignissen erfasst.",
                    "Im Vergleich zu früheren Versionen wie Google Universal Analytics verwendet GA4 aufgrund des Fokus auf ereignisbasierten Daten deutlich weniger Cookies. Dennoch gibt es einige spezifische Cookies, die von GA4 verwendet werden. Dazu gehören beispielsweise:",
                    "Name: _ga",
                    "Wert: 2.1326744211.152112572734-5",
                    "Verwendungszweck: Analytics.js verwendet dieses Cookie standardmäßig zur Speicherung der Benutzer-ID, um Webseitenbesucher zu unterscheiden.",
                    "Ablaufdatum: nach 2 Jahren",
                    "Name: _gid",
                    "Wert: 2.1687193234.152112572734-1",
                    "Verwendungszweck: Dieses Cookie dient ebenfalls zur Unterscheidung von Webseitenbesuchern.",
                    "Ablaufdatum: nach 24 Stunden",
                    "Name: _gat_gtag_UA_<property-id>",
                    "Wert: 1",
                    "Verwendungszweck: Wird verwendet, um die Anforderungsrate zu verringern. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieses Cookie den Namen _dc_gtm_ <property-id>.",
                    "Ablaufdatum: nach 1 Minute",
                    "Anmerkung: Diese Auflistung erhebt keinen Anspruch auf Vollständigkeit, da Google gelegentlich die Auswahl seiner Cookies ändert.",
                    "Die Speicherdauer der Daten hängt von den verwendeten Properties ab und wird individuell für jede Property festgelegt. Google Analytics bietet vier Optionen zur Kontrolle der Speicherdauer:",
                    "•	2 Monate: Die kürzeste Speicherdauer.",
                    "•	14 Monate: Standardmäßig werden die Daten bei GA4 für 14 Monate gespeichert.",
                    "•	26 Monate: Die Daten können auch 26 Monate lang gespeichert werden.",
                    "•	Daten werden erst gelöscht, wenn wir sie manuell löschen.",
                    "Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google verhindern, indem Sie das Browser-Plugin unter folgendem Link herunterladen und installieren:",
                    "https://tools.google.com/dlpage/gaoptout?hl=de.",
                    `Wenn Sie grundsätzlich Cookies deaktivieren, löschen oder verwalten möchten, finden Sie im Abschnitt "Cookies" entsprechende Links zu den Anleitungen für die gängigsten Browser.`,
                    "Die Verwendung dieses Analyse-Tools basiert auf Art. 6 Abs. 1 lit. a DSGVO und, sofern wir Sie um Ihre ausdrückliche Einwilligung zur Übermittlung von Daten in ein Drittland bitten, Art. 49 Abs. 1 lit. a DSGVO; die Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden.",
                    "Für die Übermittlung von Daten in die USA gibt es auch EU-Kommissions-Standardvertragsklauseln. Weitere Informationen finden Sie hier: https://privacy.google.com/businesses/controllerterms/mccs/.",
                    "Für weitere Informationen zur Datenverarbeitung durch Google empfehlen wir Ihnen die Datenschutzerklärung des Unternehmens unter https://policies.google.com/privacy?hl=de."
                ]
            },
            {
                title: "Google Tag Manager",
                points: [
                    "Wir verwenden den Google Tag Manager des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.",
                    "Der Google Tag Manager ist ein Tool, mit dem wir Tracking- oder Statistik-Tools und andere Technologien in unsere Webseite integrieren können. Der Google Tag Manager erstellt selbst keine Benutzerprofile, speichert keine Cookies und führt keine eigenständigen Analysen durch. Er dient lediglich der Verwaltung und Auslieferung der über ihn integrierten Tools. Der Google Tag Manager erfasst jedoch Ihre IP-Adresse, die auch an die Muttergesellschaft von Google in den USA übertragen werden kann.",
                    "Die Verwendung des Google Tag Managers erfolgt auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und, sofern wir Sie um Ihre ausdrückliche Einwilligung zur Übermittlung von Daten in ein Drittland bitten, Art. 49 Abs. 1 lit. a DSGVO. Die Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden.",
                ]
            },
            {
                title: "Facebook Pixel",
                points: [
                    "Wir verwenden auf unserer Webseite das Facebook-Pixel von Facebook. Dafür haben wir einen Code auf unserer Webseite integriert. Das Facebook-Pixel ist ein Stück JavaScript-Code, das eine Sammlung von Funktionen lädt, mit denen Facebook Ihre Benutzeraktionen verfolgen kann, sofern Sie über Facebook-Anzeigen auf unsere Webseite gelangt sind. Wenn Sie beispielsweise ein Produkt auf unserer Webseite kaufen, wird das Facebook-Pixel aktiviert und speichert Ihre Aktionen auf unserer Webseite in einem oder mehreren Cookies. Diese Cookies ermöglichen es Facebook, Ihre Benutzerdaten (Kundendaten wie IP-Adresse, Benutzer-ID) mit den Daten Ihres Facebook-Kontos abzugleichen. Anschließend löscht Facebook diese Daten. Die erhobenen Daten sind für uns anonym und nicht einsehbar und werden nur im Rahmen von Werbeanzeigen verwendet. Wenn Sie ein Facebook-Benutzer sind und angemeldet sind, wird Ihr Besuch auf unserer Webseite automatisch Ihrem Facebook-Benutzerkonto zugeordnet.",
                    "Unser Ziel ist es, unsere Dienstleistungen und Produkte nur denjenigen Personen zu zeigen, die sich tatsächlich dafür interessieren. Mithilfe des Facebook-Pixels können unsere Werbemaßnahmen besser an Ihre Wünsche und Interessen angepasst werden. So sehen Facebook-Benutzer (sofern sie personalisierte Werbung erlaubt haben) passende Werbung. Facebook verwendet die erhobenen Daten auch zu Analysezwecken und eigenen Werbeanzeigen.",
                    "Wenn Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen selbst ändern unter https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen. Wenn Sie kein Facebook-Benutzer sind, können Sie grundsätzlich die nutzungsbasierte Online-Werbung unter http://www.youronlinechoices.com/de/praferenzmanagement/ verwalten. Dort haben Sie die Möglichkeit, Anbieter zu deaktivieren oder zu aktivieren.",
                    "Weitere Informationen zum Datenschutz bei Facebook finden Sie in der Datenschutzrichtlinie des Unternehmens unter https://www.facebook.com/policy.php."
                ]
            },
            {
                title: "LinkedIn Insight-Tag",
                points: [
                    "Wir verwenden auf unserer Webseite das Conversion-Tracking-Tool LinkedIn Insight-Tag. Der Dienstanbieter ist das amerikanische Unternehmen LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. Für Datenschutzaspekte im Europäischen Wirtschaftsraum (EWR), der EU und der Schweiz ist das Unternehmen LinkedIn Ireland Unlimited (Wilton Place, Dublin 2, Irland) verantwortlich.",
                    "LinkedIn verarbeitet u.a. auch Daten von Ihnen in den USA. Wir möchten darauf hinweisen, dass nach Ansicht des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau für die Übertragung von Daten in die USA gewährleistet ist. Dies kann verschiedene Risiken für die Rechtmäßigkeit und Sicherheit der Datenverarbeitung mit sich bringen.",
                    "LinkedIn verwendet als Grundlage für die Verarbeitung von Daten durch Empfänger in Drittländern (außerhalb der Europäischen Union, Islands, Liechtensteins, Norwegens, insbesondere in den USA) oder für die Weitergabe von Daten dorthin sogenannte Standardvertragsklauseln (= Art. 46 Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses - SCC) sind von der EU-Kommission bereitgestellte Musterverträge und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn sie in Drittländer (wie beispielsweise die USA) übertragen und dort gespeichert werden.",
                    "Durch diese Klauseln verpflichtet sich LinkedIn, beim Umgang mit Ihren relevanten Daten das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln beruhen auf einem Durchführungsbeschluss der EU-Kommission.",
                    "Den Beschluss und die entsprechenden Standardvertragsklauseln finden Sie unter anderem hier: ",
                    "https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de",
                    "Weitere Informationen zu den Standardvertragsklauseln bei LinkedIn finden Sie unter",
                    "https://de.linkedin.com/legal/l/dpa oder https://www.linkedin.com/legal/l/eu-sccs",
                    "Weitere Informationen zum LinkedIn Insight-Tag finden Sie unter",
                    "https://www.linkedin.com/help/linkedin/answer/a427660",
                    "Nähere Details zu den Daten, die durch die Verwendung des LinkedIn Insight-Tags verarbeitet werden, finden Sie auch in der Datenschutzerklärung unter https://de.linkedin.com/legal/privacy-policy"
                ]
            }
        ]
    },
    {
        mainTitle: "E-Mail-Marketing",
        terms: [
            {
                title: "",
                points: [
                    "Wenn Sie unseren Newsletter, der auf der Website angeboten wird, abonnieren möchten, benötigen wir Ihre E-Mail-Adresse sowie Informationen, die es uns ermöglichen zu überprüfen, ob Sie der Inhaber der angegebenen E-Mail-Adresse sind und dem Erhalt des Newsletters zustimmen. Weitere Daten werden entweder nicht erfasst oder nur auf freiwilliger Basis. Zur Abwicklung des Newsletters bedienen wir uns von Newsletter-Dienstleistern, die im Folgenden beschrieben werden.",
                ]
            },
            {
                title: "HubSpot",
                points: [
                    `Für den Versand unserer Newsletter nutzen wir HubSpot. Der Anbieter ist die HubSpot Germany GmbH, Am Postbahnhof 17, 10243 Berlin, Deutschland (im Folgenden "HubSpot"). HubSpot ist ein Dienst, der die Organisation und Auswertung des Newsletterversands ermöglicht. Die Daten, die Sie für den Erhalt des Newsletters angeben (z. B. E-Mail-Adresse), werden auf den Servern von HubSpot in Deutschland mit Backups in Irland gespeichert.`,
                    "Die von uns über HubSpot versendeten Newsletter ermöglichen uns die Analyse des Verhaltens der Empfänger. Dies umfasst beispielsweise die Auswertung, wie viele Empfänger die Newsletter-Nachricht geöffnet haben und wie oft Links in den Newslettern angeklickt wurden. Mithilfe des sogenannten Conversion-Trackings kann auch ermittelt werden, ob nach dem Klicken auf einen Link im Newsletter eine vordefinierte Aktion (z. B. der Kauf eines Produkts auf unserer Website) stattgefunden hat.",
                    "Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie den Newsletter, über den in jeder Newsletter-Nachricht angegebenen Link abbestellen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf Ihrer Einwilligung unberührt.",
                    "Die von Ihnen für den Newsletter angegebenen Daten werden bis zu Ihrer Abmeldung vom Newsletter gespeichert und nach der Abmeldung aus dem Verteiler gelöscht. Nach Ihrer Abmeldung wird Ihre E-Mail-Adresse von uns oder dem Newsletter-Dienstleister möglicherweise in einer Blacklist gespeichert, um zukünftige Zusendungen zu verhindern. Die Daten aus der Blacklist werden nur für diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben für den Versand von Newslettern (berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht begrenzt."
                ]
            }

        ]
    },
    {
        mainTitle: "Audio & Video",
        terms: [
            {
                title: "YouTube",
                points: [
                    "Wir haben YouTube-Videos auf unserer Website eingebettet, um Ihnen interessante Videos direkt auf unserer Seite präsentieren zu können. YouTube ist ein Videoportal und seit 2006 eine Tochtergesellschaft von Google. Das Videoportal wird von YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA, betrieben. Wenn Sie eine Seite unserer Website aufrufen, auf der ein YouTube-Video eingebettet ist, stellt Ihr Browser automatisch eine Verbindung zu den Servern von YouTube bzw. Google her. Dabei werden, abhängig von Ihren Einstellungen, verschiedene Daten übertragen. Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) ist für die gesamte Datenverarbeitung im europäischen Raum verantwortlich.",
                    "Beim Besuch unserer Seiten mit eingebetteten YouTube-Videos setzt YouTube mindestens ein Cookie, das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube Ihre Interaktionen auf unserer Website normalerweise mithilfe von Cookies Ihrem Profil zuordnen. Dies kann Daten wie die Dauer Ihres Besuchs, die Absprungrate, Ihren ungefähren Standort, technische Informationen wie den Browsertyp, die Bildschirmauflösung oder Ihren Internetanbieter umfassen. Weitere Daten können Kontaktdaten, Bewertungen, das Teilen von Inhalten in sozialen Medien oder das Hinzufügen von Videos zu Ihren Favoriten auf YouTube sein.",
                    "Wenn Sie nicht in einem Google- oder YouTube-Konto angemeldet sind, speichert Google Daten mit einer eindeutigen Kennung, die mit Ihrem Gerät, Browser oder Ihrer App verknüpft ist. Zum Beispiel wird Ihre bevorzugte Spracheinstellung beibehalten. Viele Interaktionsdaten können jedoch nicht gespeichert werden, da weniger Cookies gesetzt werden.",
                    `Die von Google erfassten Daten werden unterschiedlich lange gespeichert. Einige Daten können von Ihnen jederzeit gelöscht werden, andere werden automatisch nach einer bestimmten Zeit gelöscht, und wieder andere werden von Google über einen längeren Zeitraum gespeichert. Einige Daten (wie Elemente in "Meine Aktivität", Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto gespeichert sind, werden so lange aufbewahrt, bis Sie sie löschen. Auch wenn Sie nicht in einem Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem Gerät, Browser oder Ihrer App verknüpft sind, löschen.`,
                    "Sofern Sie der Verarbeitung und Speicherung Ihrer Daten durch eingebettete YouTube-Elemente zugestimmt haben, erfolgt die Datenverarbeitung auf der Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Grundsätzlich werden Ihre Daten auch auf der Grundlage unseres berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO) an einer schnellen und effektiven Kommunikation mit Ihnen und anderen Kunden sowie Geschäftspartnern gespeichert und verarbeitet."
                ]
            },
        ]
    },
    {
        mainTitle: "Plugins & Tools",
        terms: [
            {
                title: "Google reCAPTCHA",
                points: [
                    "Unser vorrangiges Ziel ist, unsere Website bestmöglich für Sie und für uns abzusichern und zu schützen. Hierzu nutzen wir Google reCAPTCHA, das von der Firma Google Inc. bereitgestellt wird. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) für sämtliche Google-Dienste verantwortlich.",
                    "Der Zweck von reCAPTCHA besteht darin, zu überprüfen, ob die auf dieser Website eingegebenen Daten (zum Beispiel in einem Kontaktformular) von einem Menschen oder von einem automatisierten Programm stammen. Zu diesem Zweck analysiert reCAPTCHA das Verhalten der Website-Besucher anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald ein Besucher die Website betritt. Dabei werden verschiedene Informationen ausgewertet (zum Beispiel IP-Adresse, Verweildauer auf der Website und Mausbewegungen des Nutzers), und die gesammelten Daten werden an Google weitergeleitet.",
                    "Die reCAPTCHA-Analysen erfolgen vollständig im Hintergrund, ohne dass die Besucher der Website darüber informiert werden.",
                    "Die Speicherung und Auswertung der Daten basieren auf Ihrer Einwilligung (Artikel 6 Absatz 1 Buchstabe a DSGVO), die Sie jederzeit mit Wirkung für die Zukunft widerrufen können.",
                    "Weitere Informationen zu Google reCAPTCHA finden Sie in den Datenschutzbestimmungen von Google und den Nutzungsbedingungen von Google unter den folgenden Links: ",
                    "https://policies.google.com/privacy?hl=de und https://policies.google.com/terms?hl=de"
                ]
            },
            {
                title: "Font Awesome",
                points: [
                    "Auf unserer Website verwenden wir Font Awesome, ein Produkt des amerikanischen Unternehmens Fonticons (307 S. Main St., Suite 202, Bentonville, AR 72712, USA). Wenn Sie eine unserer Webseiten aufrufen, werden die Web-Schriftarten von Font Awesome (insbesondere Icons) über das Font Awesome Content Delivery Network (CDN) geladen. Dadurch werden Texte, Schriften und Icons auf jedem Endgerät korrekt angezeigt.",
                    "Um die Web-Schriftarten laden zu können, muss Ihr Browser eine Verbindung zu den Servern des Unternehmens Fonticons, Inc. herstellen. Dabei wird Ihre IP-Adresse erfasst. Font Awesome sammelt außerdem Informationen darüber, welche Icon-Dateien zu welchem Zeitpunkt heruntergeladen werden. Darüber hinaus werden technische Daten wie Ihre Browser-Version, Bildschirmauflösung und der Zeitpunkt des Seitenaufrufs übertragen.",
                    "Diese Daten werden aus verschiedenen Gründen gesammelt und gespeichert, darunter die Optimierung von Content Delivery Netzwerken, die Erkennung und Behebung technischer Fehler, der Schutz vor Missbrauch und Angriffen auf CDNs, die Abrechnung von Font Awesome Pro-Kunden, die Ermittlung der Beliebtheit von Icons und die Erfassung von Informationen über Ihren Computer und Ihre Software.",
                    "Sofern Ihr Browser keine Web-Schriftarten zulässt, wird automatisch eine Standardschrift von Ihrem Computer verwendet. Nach unserem derzeitigen Kenntnisstand werden keine Cookies gesetzt.",
                    "Die Rechtsgrundlage für die Verarbeitung Ihrer Daten durch Font Awesome ist Ihre Einwilligung (Artikel 6 Absatz 1 Buchstabe a DSGVO), die Sie durch die Verwendung von Font Awesome erteilt haben. Zusätzlich liegt ein berechtigtes Interesse unsererseits vor, Font Awesome zur Optimierung unserer Online-Services zu nutzen. Hierfür dient die Rechtsgrundlage gemäß Artikel 6 Absatz 1 Buchstabe f DSGVO (berechtigte Interessen). Wir setzen Font Awesome jedoch nur ein, wenn Sie zuvor Ihre Einwilligung gegeben haben.",
                    "Für weitere Informationen zur Datenverarbeitung durch Font Awesome und deren Umgang mit Daten empfehlen wir Ihnen die Datenschutzerklärung unter https://fontawesome.com/privacy und die Hilfe-Seite unter https://fontawesome.com/support."
                ]
            },
            {
                title: "Google Fonts Lokal",
                points: [
                    "Auf unserer Website verwenden wir Google Fonts, die von der Firma Google Inc. bereitgestellt werden. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) verantwortlich. Wir haben die Google-Schriftarten lokal auf unserem Webserver eingebunden, nicht auf den Servern von Google. Dadurch findet keine Verbindung zu den Servern von Google statt, und es erfolgt keine Datenübertragung oder -speicherung.",
                    "Für weitere Informationen zu Google Web Fonts besuchen Sie bitte https://developers.google.com/fonts/faq und lesen Sie die Datenschutzbestimmungen von Google unter https://policies.google.com/privacy?hl=de."
                ]
            },
            {
                title: "Google Maps",
                points: [
                    "Auf unserer Website verwenden wir Google Maps, das von der Firma Google Inc. bereitgestellt wird. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) für sämtliche Google-Dienste verantwortlich. Durch die Verwendung von Google Maps können wir Standorte besser darstellen und unseren Service an Ihre Bedürfnisse anpassen. Bei der Nutzung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert.",
                    "Um die Funktionen von Google Maps nutzen zu können, ist die Verarbeitung Ihrer IP-Adresse erforderlich. Diese Informationen können an einen Server von Google in den USA übertragen und dort gespeichert werden. Der Anbieter dieser Website hat keinen Einfluss auf diese weitere Datenverarbeitung. Wenn Google Maps aktiviert ist, kann Google zur einheitlichen Darstellung von Schriftarten Google Web Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die benötigten Webfonts in Ihren Browsercache, um Texte und Schriftarten korrekt darzustellen.",
                    "Die Nutzung von Google Maps erfolgt auf Grundlage Ihrer Einwilligung (Artikel 6 Absatz 1 Buchstabe a DSGVO), und eine etwaige Datenübermittlung in die USA erfolgt auf Grundlage Ihrer ausdrücklichen Einwilligung bei Aktivierung des Plugins (Artikel 49 Absatz 1 Buchstabe a DSGVO). Ihre Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen.",
                    "Für die Datenübermittlung in die USA existieren auch Standardvertragsklauseln der EU-Kommission. Weitere Details finden Sie hier: https://privacy.google.com/businesses/DSGVOcontrollerterms/ und https://privacy.google.com/businesses/DSGVOcontrollerterms/sccs/",
                    "Weitere Informationen zur Datenverarbeitung durch Google und den Umgang mit Nutzerdaten finden Sie in den Datenschutzbestimmungen von Google: https://policies.google.com/privacy?hl=de"
                ]
            }
        ]
    },
    {
        mainTitle: "Verpflichtung zur Bereitsstellung von Daten",
        terms: [
            {
                title: "",
                points: [
                    "Einige der oben genannten personenbezogenen Daten benötigen wir für die Erfüllung unserer Dienstleistungen oder des Vertrags mit Ihnen und/oder zur Erfüllung gesetzlicher Verpflichtungen, denen wir unterliegen. Wenn Sie sich weigern, personenbezogene Daten bereitzustellen, die für die Erfüllung des Vertrags erforderlich sind oder die wir aufgrund gesetzlicher Verpflichtungen verarbeiten müssen, kann es sein, dass es uns nicht möglich ist, diese Dienstleistungen und/oder insbesondere die oben genannten Zwecke zu erfüllen, und/oder es würde den entsprechenden Zweck erheblich verlangsamen."
                ]
            }
        ]
    },
    {
        mainTitle: "Widerspruch gegen Werbe-E-Mails",
        terms: [
            {
                title: "",
                points: [
                    "Der Nutzung der im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von unaufgeforderten Werbe-E-Mails und Informationsmaterialien wird hiermit widersprochen. Die Betreiber dieser Website behalten sich ausdrücklich rechtliche Schritte im Falle unverlangt zugesandter Werbeinformationen, beispielsweise durch Spam-E-Mails, vor."
                ]
            }
        ]
    },
    {
        mainTitle: "Aktualisierungen",
        terms: [
            {
                title: "",
                points: [
                    "Wir behalten uns das Recht vor, diesen Hinweis zu aktualisieren, um rechtlichen, technischen oder geschäftlichen Änderungen gerecht zu werden. Bei Aktualisierungen werden angemessene Maßnahmen ergriffen, um Sie über die durchgeführten Änderungen zu informieren. Das Datum der letzten Aktualisierung („Stand“) finden Sie am Anfang dieses Hinweises."
                ]
            }
        ]
    },
    {
        mainTitle: "Haftungsausschluss",
        terms: [
            {
                title: "",
                points: [
                    "Die Website enthält Links zu Websites Dritter. Wir haben keine Kontrolle über den Inhalt oder die Datenschutzpraktiken dieser anderen Websites. Bitte lesen Sie die jeweiligen Datenschutzbestimmungen der anderen Websites, die Sie besuchen."
                ]
            }
        ]
    }
]
