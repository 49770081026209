// src/SignInButton.tsx

import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { getTokenFromSSO, setErrorMessage } from '../Authentification/authentificationSlice';
import { Typography } from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';
import { WebShopBimTAccessToken } from '../../app/common';

const defaulthError = 'The app is trying to access a service (CentralServices-WebShopBackend)that your organization lacks a service principal for. Contact your IT Admin to review the configuration of your service subscriptions or consent to the application in order to create the required Service Principal.'

const SignInButton: React.FC = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const dispatch = useDispatch<AppDispatch>();
    const setSliceForLogInData = async () => {
        dispatch(getTokenFromSSO())
    };
    const setErrorMessageForSSO = async(errorMessage : any) => {
        dispatch(setErrorMessage(errorMessage))
    }

    const handleLogin = (loginType: string) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).then(() => {
                getAccessToken();
            }).catch(e => {
                setErrorMessageForSSO(e)
                console.error(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).then(() => {
                getAccessToken();
            }).catch(e => {
                setErrorMessageForSSO(e)
                console.error(e);
            });
        }
    };

    const getAccessToken = async () => {
        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
            try {
                const response = await instance.acquireTokenSilent(request);
                if(!response.accessToken)
                {
                    setErrorMessageForSSO(defaulthError)
                }
                localStorage.setItem(WebShopBimTAccessToken, response.accessToken);
                setSliceForLogInData();
                return response.accessToken;
            } catch (error) {
                // Handle error, you might need to use acquireTokenPopup or acquireTokenRedirect
                console.error(error);
                setErrorMessageForSSO(error)
                return null;
            }
        }
        return null;
    };

    return (
        <Typography className="mt-6 flex justify-center items-center gap-[10px] text-#FF6104 text-base 2xl:text-lg uppercase font-[500] cursor-pointer" onClick={() => handleLogin("popup")}>
            <svg className='w-4 h-4 2xl:w-5 2xl:h-5' viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.70296 5.70296H0V0H5.70296V5.70296Z" fill="#F1511B" />
                <path d="M11.9998 5.70296H6.29688V0H11.9998V5.70296Z" fill="#80CC28" />
                <path d="M5.70282 11.9998H0V6.29688H5.70282V11.9998Z" fill="#00ADEF" />
                <path d="M11.9998 11.9998H6.29688V6.29688H11.9998V11.9998Z" fill="#FBBC09" />
            </svg>
            {t("ContinueWithMicrosoft")}
        </Typography>
    );
};

export default SignInButton;
