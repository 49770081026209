import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { LogInRequest } from "../authModel";
import { postRegister } from "../authentificationSlice";
import { ValidateEmail, ValidatePasswordContent } from "../../../app/validation";
import { LoadingPage } from "../../CommonPages/loadingPage";
import RegisterCard from "./registerCard";
import { Alert, Dialog, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import SuccessCard from "../../CommonPages/successCard";

export function Register() {
    const [errorMesssage, seterrorMesssage] = useState('');
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const registerData = useSelector((state: RootState) => state.AuthentificationSlice);
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [openDialog, setOpenDialog] = useState(false);
    const [success, setSuccess] = useState(false);
    const [messsage, setMesssage] = useState('');

    const handleOpenDialog = () => setOpenDialog(!openDialog);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        submitFunction(email, password);
    };

    const submitFunction = (email: string, password: string) => {
        if (!ValidateEmail(email)) {
            seterrorMesssage(t("YouHaveEnteredAnInvalidEmailAddress"));
            setOpen(true);

        }
        else if (!ValidatePasswordContent(password)) {
            seterrorMesssage(t("PasswordFormat"));
            setOpen(true);
        }
        else {
            const registerReq: LogInRequest = { username: email, password: password };
            dispatch(postRegister(registerReq))
        }
    }

    useEffect(() => {
        if (registerData.statusCode === '200' || registerData.statusCode === 'OK') {
            setSuccess(true);
            setMesssage(t("RequestForAccountCreationWasSentCheckYourEmail"));
            handleOpenDialog();
        }
        else if (registerData.statusCode !== '') {
            setSuccess(false);
            setMesssage(registerData.error ?? t("AnErrorOccurred"));
            handleOpenDialog();
        }
    }, [registerData.statusCode])

    if (registerData.isLoading) {
        return (LoadingPage(registerData.isLoading))
    }

    return (
        <div className="flex justify-center m-[5%]">
            <form className="space-y-5" onSubmit={handleSubmit}>
                {errorMesssage !== "" ?
                    <Alert color="red" open={open} onClose={() => setOpen(false)}>
                        <Typography className="text-[14px] flex-nowrap overflow-hidden text-ellipsis max-w-[320px] max-lg:w-[400px] max-2xl:w-[500px]">
                            {errorMesssage}
                        </Typography>
                    </Alert>
                    : <></>}
                <RegisterCard onClickFunction={submitFunction} setEmailForm={setEmail} setPasswordForm={setPassword} />
                <input type="submit" style={{ display: "none" }} />
            </form>
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
            </Dialog>
        </div>
    )
}
