import { Typography } from "@material-tailwind/react";
import aboutPhoto from "../Images/aboutPhoto.jpg";
import { BlogComponent } from "../UIComponents/Blogs/blog";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { useEffect } from "react";
import { GetAllBlogs } from "../features/Blogs/BlogSlice";
import { LoadingPage } from "../features/CommonPages/loadingPage";
import ReactGA from "react-ga4";
import { getCurrentDate } from "../app/common";

export function AboutPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const blogData = useSelector((state: RootState) => state.BlogsSlice);
  const blogsLength = blogData.blogSliceData.blogData.blogs.length;

  const first_paragraph = t("AboutPageFirstParagraph");
  const second_paragraph = t("AboutPageSecondParagraph");
  const title = t("AboutPageTitle");

  const text = [
    t("AboutPageText1"),
    t("AboutPageText2"),
    t("AboutPageText3"),
    t("AboutPageText4"),
  ];

  useEffect(() => {
    ReactGA.send({ hitType: "PageView", page: window.location.pathname + window.location.search, title: getCurrentDate() });
  }, [])

  useEffect(() => {
    dispatch(GetAllBlogs());
  }, []);

  return (
    <div className="bg-#FFFFFF">
      <Typography className="uppercase bg-#F6F6F6 font-bold text-lg lg:text-2xl 2xl:text-4xl text-center py-[20px]">
        {t("About")}
      </Typography>
      <div className="mt-[30px] lg:mt-[60px] pb-[20px] lg:pb-[40px] px-[20px] lg:px-[100px]">
        <div className="grid grid-cols-1 xl:grid-cols-2 mb-[30px] gap-[14px] xl:gap-[80px]">
          <div className="w-full">
            <img src={aboutPhoto} alt="" className="w-full h-full object-cover" />
          </div>
          <div className="mx-auto h-fit my-auto basis-0 grow-0 overflow-auto">
            <Typography className="text-base 2xl:text-2xl font-light tracking-[0.8px] 2xl:tracking-[1.4px] p-0 whitespace-break-spaces">
              {first_paragraph}
              <br />
              <br />
              {second_paragraph}
            </Typography>
          </div>
        </div>

        <hr className=" bg-#A0A0A0 my-[20px] lg:my-[40px]" />
        <Typography className="p-0 font-bold text-lg lg:text-2xl 2xl:text-4xl tracking-[0.9px] lg:tracking-[1.2px] text-center mb-[38px]">
          {title}
        </Typography>
        {text.map((t) => (
          <Typography key={t} className="text-base 2xl:text-2xl font-light text-center pt-[22px] lg:pt-[9px] 2xl:pt-[15px] tracking-[0.8px]">
            {t}
          </Typography>
        ))}
        <hr className="bg-#A0A0A0 my-[20px] lg:my-[40px]" />
        <section className="flex flex-col justify-center items-center">
          <Typography className="uppercase text-2xl font-bold tracking-[1.2px] text-center pb-[0px] lg:pb-[16px]">
            {t("Blog")}
          </Typography>
          {
            !blogData.blogSliceData.statusValues.isLoading ?
              <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-10 2xl:gap-[200px] w-fit mx-auto">
                <div className="mx-auto">
                  {blogsLength > 0 ?
                    <BlogComponent key={blogData.blogSliceData.blogData.blogs[blogsLength - 1].title} {...blogData.blogSliceData.blogData.blogs[blogsLength - 1]} /> : <></>}
                </div>
                <div className="">
                  {blogsLength > 1 ?
                    <BlogComponent key={blogData.blogSliceData.blogData.blogs[blogsLength - 2].title} {...blogData.blogSliceData.blogData.blogs[blogsLength - 2]} /> : <></>}
                </div>
                <div className="xl:hidden 2xl:block">
                  {blogsLength > 2 ?
                    <BlogComponent key={blogData.blogSliceData.blogData.blogs[blogsLength - 3].title} {...blogData.blogSliceData.blogData.blogs[blogsLength - 3]} /> : <></>}
                </div>
              </div> : LoadingPage(blogData.blogSliceData.statusValues.isLoading)
          }
        </section>
      </div>
    </div>
  );
}
