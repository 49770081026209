import { Middleware } from 'redux';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { postRefreshAccessToken, postRefreshMicrosoftAuth } from './features/Authentification/authentificationSlice';
import { WebShopBimRefreshToken, WebShopBimTAccessToken } from './app/common';


export const tokenExpirationMiddleware: Middleware = (storeAPI) => (next) => (action) => {
    //None of the AuthentificationSlice should refresh token,
    //Persist are the ones for persisting data in redux
    //no_ are the methods in slices that don't need to be refreshed, so the name has to match
    if (action.type.startsWith('AuthentificationSlice') || action.type.startsWith('persist') || action.type.startsWith('no_')) {
        return next(action)
    }

    const state = storeAPI.getState();
    const isRefreshingToken = state.AuthentificationSlice.isRefreshingToken

    if (isRefreshingToken === true) {
        return next(action);
    }
    // Check if the action requires a token and if the token is expired
    let token = localStorage.getItem(WebShopBimTAccessToken);

    if (token) {
        const tokenExpired = isTokenExpired(token); // You need to implement isTokenExpired
        if (tokenExpired === true) {

            //If it's from Microsoft:
            if (IsMicrosoftToken(token)) {
                storeAPI.dispatch(postRefreshMicrosoftAuth() as any).then((result: any) => {
                    if (result.payload === 200 || result.payload === "200")
                        return next(action);
                    else {
                        localStorage.clear();
                        window.location.replace("/login")

                    }
                })
            }
            else {
                //It's our token:
                
                // Dispatch an action to refresh the token
                storeAPI.dispatch(postRefreshAccessToken() as any).then((result: any) => {
                    if (result.payload === 200 || result.payload === "200")
                        return next(action);
                    else {
                        localStorage.clear();
                        window.location.replace("/login")

                    }
                })
            }

        }
        else {
            return next(action)
        }
    }
    else {
        // If the action does not require a token or the token is not expired, proceed as usual
        return next(action);
    }
};



function isTokenExpired(token: string | null): boolean {
    if (token !== null && token !== undefined) {
        const decoded = jwtDecode<JwtPayload>(token);
        let dateTime = Math.floor(Date.now() / 1000);
        if (decoded.exp && decoded.exp < dateTime) {
            return true;
        }
    }

    return false;
}

function IsMicrosoftToken(token: string | null): boolean {
    if (token !== null && token !== undefined) {
        const decoded = jwtDecode<JwtPayload>(token);
        if (decoded.iss !== null && decoded.iss !== undefined) {
            if (decoded.iss.startsWith("https://login.microsoftonline.com"))
                return true;
        }
    }
    return false;
}

