import HeroImage from "../Images/heroImage.png";
import { BlogComponent } from "../UIComponents/Blogs/blog";
import HomePageAboutComponent from "../UIComponents/HomePageComponents/HomePageAboutComponent";
import SolutionsComponent from "../UIComponents/HomePageComponents/SolutionsComponent";
import { Button, Input, Textarea, Typography } from "@material-tailwind/react";
import Address from "../Images/address.png";
import SentArrow from "../Images/SentArrow.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PostSendContactEmail, resetStatusCode } from "../features/ContactEmail/ContactEmailSlice";
import { GetAllBlogs } from "../features/Blogs/BlogSlice";
import { LoadingPage } from "../features/CommonPages/loadingPage";
import ReactGA from "react-ga4";
import { getCurrentDate } from "../app/common";

export function HomePage() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const blogData = useSelector((state: RootState) => state.BlogsSlice);
  const accountData = useSelector((state: RootState) => state.AccountSlice);
  const contactData = useSelector((state: RootState) => state.ContactSlice);
  const blogsLength = blogData.blogSliceData.blogData.blogs.length;
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const blogText = t("BlogText");

  function handleSubmitButtonClick(): void {
    if (title === "") {
      setTitleError(true);
    }
    if (message === "") {
      setMessageError(true);
    }
    if (title !== "" && message !== "") {
      dispatch(PostSendContactEmail({ title: title, message: message }))
      setIsSuccess(true);
    }
  }

  const setTitleOnChange = (title: string) => {
    setTitle(title);
    setTitleError(false);
  }

  const setMessageOnChange = (message: string) => {
    setMessage(message);
    setMessageError(false);
  }

  useEffect(() => {
    ReactGA.send({ hitType: "PageView", page: window.location.pathname + window.location.search, title: getCurrentDate() });
  }, [])

  useEffect(() => {
    if (contactData.statusValues.statusCode === '200') {
      setTitle('');
      setMessage('');
      setIsVisible(true);
      setIsSuccess(true);
      setIsDisabled(true);
    }
    else if (contactData.statusValues.statusCode !== '') {
      setIsSuccess(false);
    }
    dispatch(resetStatusCode());
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
      setIsDisabled(false)
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, [contactData.statusValues.statusCode])

  useEffect(() => {
    dispatch(GetAllBlogs())
  }, [])

  useEffect(() => {
    if (location.hash === "#contact") {
      const element = document.getElementById("contact");
      element?.scrollIntoView()
    }
  }, [location])

  useEffect(() => {
    if (accountData.hasUserData) {
      setIsLoggedIn(true);
    }
    else {
      setIsLoggedIn(false);
      setTitle('');
      setMessage('');
      setIsSuccess(true);
    }
  }, [accountData.hasUserData])

  if (accountData.accountSliceData.statusValues.isLoading) {
    return (LoadingPage(accountData.accountSliceData.statusValues.isLoading))
  }

  return (
    <div className="bg-#FFFFFF">
      <section className="w-fit h-fit">
        <div className="relative">
          <img src={HeroImage} className="object-cover w-screen h-auto" alt=""
          />
          <div className="absolute w-full h-full top-1/3 text-#FFFFFF text-center uppercase">
            <p className="font-bold text-3xl sm:text-5xl lg:text-6xl 2xl:text-8xl p-0">
              BIMAGINE
            </p>
            <p className="font-bold text-[9px] sm:text-[14px] lg:text-[18px] 2xl:text-[26px] p-0 lg:pt-2 xl:pt-4 2xl:pt-6 whitespace-pre-line">
              {t("BimagineShortDescription")}
            </p>
          </div>
          <div className="hidden xl:block absolute w-full h-full top-[65%]">
            <div className="flex justify-center">
              <a href="#about" className="bg-#FFFFFF opacity-80 hover:opacity-90 rounded-full w-[44px] 2xl:w-[53px] h-[59px] 2xl:h-[71px] font-semibold pt-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="60%" height="60%" viewBox="0 0 14 22" fill="none" className="mx-auto">
                  <path d="M7 21L13 15.375M7 21L1 15.375M7 21V11.1562M7 6V8.34375M7 1V3.34375" stroke="#FF8136" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <HomePageAboutComponent />
      <SolutionsComponent />
      <section className="px-[20px] lg:px-[100px] xl:pr-[200px] pt-[16px] lg:pt-[60px]">
        <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-10 2xl:gap-[200px] w-fit mx-auto">
          <div className="mt-6 max-w-[280px] lg:max-w-[398px]">
            <Typography className="text-2xl font-bold tracking-[1.2px] ">
              BLOG
            </Typography>
            <Typography className="text-base font-light tracking-[0.8px] mt-[30px] whitespace-pre-line">
              {blogText}
            </Typography>
            <a href="/blogPage" className="flex items-center mt-[38px]">
              <Typography className="text-base font-bold uppercase">
                {t("SeeMoreArticles")}
              </Typography>
              <svg className="ml-[15px]" xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                <path d="M7.81106 6.56386L1.02225 0.166377C0.908977 0.0595782 0.758483 8.63542e-08 0.601985 8.82204e-08C0.445487 9.00867e-08 0.294995 0.0595782 0.18172 0.166377L0.174411 0.173606C0.119307 0.22538 0.0754294 0.2877 0.0454459 0.356775C0.0154624 0.42585 4.82802e-07 0.500235 4.83699e-07 0.575407C4.84595e-07 0.650578 0.0154624 0.724964 0.0454459 0.794038C0.0754294 0.863113 0.119307 0.925433 0.174411 0.977207L6.56732 7.0012L0.174411 13.0228C0.119307 13.0746 0.0754296 13.1369 0.0454461 13.206C0.0154626 13.275 6.36027e-07 13.3494 6.36924e-07 13.4246C6.3782e-07 13.4998 0.0154626 13.5741 0.0454461 13.6432C0.0754296 13.7123 0.119307 13.7746 0.174411 13.8264L0.18172 13.8336C0.294995 13.9404 0.445487 14 0.601985 14C0.758483 14 0.908977 13.9404 1.02225 13.8336L7.81106 7.43614C7.87077 7.37988 7.9183 7.31221 7.95078 7.23724C7.98325 7.16227 8 7.08156 8 7C8 6.91844 7.98325 6.83773 7.95078 6.76276C7.9183 6.68779 7.87077 6.62012 7.81106 6.56386Z" fill="#333333" />
              </svg>
            </a>
          </div>
          <div className="flex justify-center items-center">
            {blogsLength > 0 ?
              <BlogComponent key={blogData.blogSliceData.blogData.blogs[blogsLength - 1].title} {...blogData.blogSliceData.blogData.blogs[blogsLength - 1]} /> : LoadingPage(blogData.blogSliceData.statusValues.isLoading)}
          </div>
          <div className="lg:hidden 2xl:block">
            {blogsLength > 1 ?
              <BlogComponent key={blogData.blogSliceData.blogData.blogs[blogsLength - 2].title} {...blogData.blogSliceData.blogData.blogs[blogsLength - 2]} /> : <></>}
          </div>
        </div>
      </section>
      <hr className="bg-#A0A0A0 my-[30px] lg:my-[60px] mx-[20px] lg:mx-[100px]" />
      <section id="contact" className="w-full">
        <div className="grid lg:grid-cols-2 grid-cols-1">
          <div className="mx-auto content-center grid grid-flow-row h-[500px]">
            <Typography className="uppercase text-2xl font-bold text-center lg:text-left pt-[30px] tracking-[1.2px]">
              {t("ContactUs")}
            </Typography>
            <div className="mt-[30px]">
              {!isLoggedIn &&
                <Typography className="text-sm mb-1">{t("MustBeLoggedIn")}</Typography>
              }
              <div className="w-[320px] space-y-[23px] flex flex-col items-center lg:items-start ">
                <Input id="title" type="text" label={t("Title")} name="title" color="orange" error={titleError} disabled={isDisabled || !isLoggedIn} value={title} onChange={(event) => setTitleOnChange(event.target.value)} />
                <Textarea id="message" label={t("Message")} name="message" color="orange" error={messageError} disabled={isDisabled || !isLoggedIn} value={message} onChange={(event) => setMessageOnChange(event.target.value)} />
                <hr />
                {!isSuccess &&
                  <Typography className="text-sm mb-1 text-#EA4247">{t("SomethingWentWrong") + " " + t("PleaseTryAgain")}</Typography>
                }
                {
                  !isVisible ? <Button id="contactSubmitBtn" disabled={!isLoggedIn} className="bg-#FF8136 shadow-none hover:bg-#FF6104 hover:shadow-none w-[187px] h-[40px] text-base font-medium tracking-[0.8px] p-0" onClick={handleSubmitButtonClick}>
                    {t("Submit")}
                  </Button> : <Button id="contactSentBtn" disabled={!isLoggedIn} ripple={false} className="bg-#A3B974 cursor-default shadow-none hover:shadow-none w-[187px] h-[40px] text-base font-medium tracking-[0.8px] p-0 flex flex-row items-center justify-center gap-[6px]">
                    {t("Sent")}
                    <img src={SentArrow} alt=""></img>
                  </Button>
                }
              </div>
            </div>

          </div>
          <div className="relative h-[617] hidden lg:block">
            <img src={Address} className="object-cover w-screen lg:h-[617px]" alt="" />
            <div className="absolute top-1/3 left-[15%] xl:left-1/4 font-medium text-#FFFFFF">
              <div className="flex gap-[12px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                  <path d="M1.44648 8.85247C3.83601 14.3655 8.08188 18.735 13.3527 21.1054L14.1991 21.5049C15.1363 21.9471 16.1908 22.0246 17.1767 21.7238C18.1626 21.423 19.0167 20.7632 19.5884 19.8606L20.6949 18.1147C20.868 17.8409 20.9381 17.5083 20.8913 17.1825C20.8446 16.8566 20.6843 16.5612 20.4422 16.3543L16.6933 13.15C16.5628 13.0384 16.4123 12.956 16.2509 12.9077C16.0895 12.8593 15.9206 12.846 15.7543 12.8685C15.5879 12.8911 15.4276 12.949 15.2829 13.0388C15.1382 13.1286 15.0121 13.2485 14.9123 13.3913L13.7522 15.0488C10.7746 13.4908 8.36435 10.9367 6.89428 7.78172L8.45755 6.55274C8.59227 6.44693 8.70543 6.31337 8.79022 6.16007C8.875 6.00677 8.92968 5.8369 8.95095 5.66066C8.97222 5.48442 8.95966 5.30544 8.91401 5.13447C8.86836 4.9635 8.79058 4.80407 8.68532 4.66574L5.66084 0.693963C5.46555 0.437423 5.18667 0.26766 4.87913 0.218115C4.57158 0.168569 4.25768 0.242832 3.99924 0.426276L2.34014 1.60515C1.48308 2.21408 0.857881 3.12558 0.575923 4.17727C0.293965 5.22897 0.373555 6.35256 0.800516 7.34788L1.44648 8.85247Z" fill="white" />
                </svg>
                <a href="tel:+4316022222">
                  <Typography className="text-base font-medium">
                    +43 1 6022222
                  </Typography>
                </a>
              </div>
              <div className="flex my-[25px] gap-[12px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" viewBox="0 0 26 22" fill="none">
                  <g clipPath="url(#clip0_1596_11303)">
                    <path d="M22.8674 0.742188H2.49742C1.09698 0.742188 -0.0360969 1.91404 -0.0360969 3.3463L-0.0488281 18.971C-0.0488281 20.4032 1.09698 21.5751 2.49742 21.5751H22.8674C24.2678 21.5751 25.4136 20.4032 25.4136 18.971V3.3463C25.4136 1.91404 24.2678 0.742188 22.8674 0.742188ZM22.3581 6.27593L13.3571 12.031C12.9497 12.2914 12.415 12.2914 12.0076 12.031L3.00666 6.27593C2.879 6.20263 2.76721 6.10361 2.67806 5.98486C2.5889 5.8661 2.52423 5.73008 2.48796 5.58502C2.4517 5.43996 2.44458 5.28888 2.46706 5.14093C2.48953 4.99298 2.54113 4.85123 2.61872 4.72427C2.69631 4.5973 2.79828 4.48775 2.91847 4.40226C3.03865 4.31677 3.17455 4.2571 3.31794 4.22688C3.46132 4.19665 3.60921 4.1965 3.75266 4.22642C3.8961 4.25635 4.03212 4.31572 4.15247 4.40096L12.6824 9.85658L21.2123 4.40096C21.3327 4.31572 21.4687 4.25635 21.6121 4.22642C21.7556 4.1965 21.9035 4.19665 22.0468 4.22688C22.1902 4.2571 22.3261 4.31677 22.4463 4.40226C22.5665 4.48775 22.6685 4.5973 22.7461 4.72427C22.8236 4.85123 22.8752 4.99298 22.8977 5.14093C22.9202 5.28888 22.9131 5.43996 22.8768 5.58502C22.8405 5.73008 22.7759 5.8661 22.6867 5.98486C22.5976 6.10361 22.4858 6.20263 22.3581 6.27593Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1596_11303">
                      <rect width="25.3178" height="20.4953" fill="white" transform="translate(0 0.933594)" />
                    </clipPath>
                  </defs>
                </svg>
                <a href="mailto:tga@bimexperts.com" >
                  <Typography className="text-base font-medium">
                    tga@bimexperts.com
                  </Typography>
                </a>
              </div>
              <div className="flex gap-[12px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" viewBox="0 0 22 25" fill="none">
                  <g clipPath="url(#clip0_1596_11295)">
                    <path d="M12.5928 21.2623C15.2195 18.9506 19.8927 14.2276 19.8927 9.89343C19.8927 7.49533 18.9401 5.19545 17.2443 3.49974C15.5486 1.80402 13.2488 0.851379 10.8506 0.851379C8.45255 0.851379 6.15267 1.80402 4.45695 3.49974C2.76124 5.19545 1.80859 7.49533 1.80859 9.89343C1.80859 14.2276 6.48032 18.9506 9.10855 21.2623C9.58795 21.6902 10.2081 21.9267 10.8506 21.9267C11.4932 21.9267 12.1134 21.6902 12.5928 21.2623ZM7.83663 9.89343C7.83663 9.09407 8.15418 8.32744 8.71942 7.7622C9.28466 7.19696 10.0513 6.87942 10.8506 6.87942C11.65 6.87942 12.4166 7.19696 12.9819 7.7622C13.5471 8.32744 13.8647 9.09407 13.8647 9.89343C13.8647 10.6928 13.5471 11.4594 12.9819 12.0247C12.4166 12.5899 11.65 12.9075 10.8506 12.9075C10.0513 12.9075 9.28466 12.5899 8.71942 12.0247C8.15418 11.4594 7.83663 10.6928 7.83663 9.89343Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1596_11295">
                      <rect width="21.7009" height="24.1122" fill="white" transform="translate(0 0.550781)" />
                    </clipPath>
                  </defs>
                </svg>
                <a href="https://www.google.com/maps/search/?api=1&query=Laxenburger%20Str.%20151a/2.OG%2C+Vienna%2C+Austria">
                  <Typography className="text-base font-medium">
                    Laxenburger Str. 151a/2.OG, 1100 {t("Vienna")}, {t("Austria")}
                  </Typography>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
}
