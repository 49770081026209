import { useEffect, useRef, useState } from "react";
import { BlogPostComponent } from "../../UIComponents/Blogs/blogPost";
import { Button, CardHeader, Dialog, IconButton, Typography } from "@material-tailwind/react";
import LeftArrow from "../../Images/leftArrow.png";
import RightArrow from "../../Images/rightArrow.png";
import LeftEndArrow from "../../Images/leftEndArrow.png";
import RightEndArrow from "../../Images/rightEndArrow.png.png";
import BlogPostBg from "../../Images/blogPostBg.png";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { GetBlogResponse, BlogPostModel } from "../../features/Blogs/BlogModel";
import { GetAllBlogs, DeleteBlog, resetStatusCode } from "../../features/Blogs/BlogSlice";
import { createSearchParams, useNavigate } from "react-router-dom";
import DeleteCard from "../../features/CommonPages/deleteCard";
import SuccessCard from "../../features/CommonPages/successCard";
import { LoadingPage } from "../../features/CommonPages/loadingPage";

function AllBlogPostsPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const blogData = useSelector((state: RootState) => state.BlogsSlice);
    const navigate = useNavigate();

    let sixBlogs: number = 6;

    const startIndexRef = useRef<number>(1);
    const endIndexRef = useRef<number>(1);
    const pagesCountRef = useRef<number>(1);

    const [active, setActive] = useState(1);
    const [blogs, setBlogs] = useState<GetBlogResponse[]>([]);
    const [blogsToShow, setBlogsToShow] = useState<GetBlogResponse[]>([]);
    const [blogsPerPage, setBlogsPerPage] = useState<number>(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [messsage, setMesssage] = useState(t("SuccessText"));
    const [success, setSuccess] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedBlogId, setSelectedBlogId] = useState<string>("");

    const handleDeleteDialog = () => setOpenDialog(!openDialog);
    const handleSuccessDialog = () => setOpenDialog(!openDialog);

    const getItemProps = (index: number) =>
    ({
        className:
            active === index
                ? "w-[20px] h-[20px] rounded-[1px] text-[16px] text-#FFFFFF bg-#4E4E4E shadow-none hover:shadow-none"
                : "w-[20px] h-[20px] rounded-[1px] text-[16px] text-#4E4E4E bg-#FFFFFF shadow-none hover:shadow-none hover:bg-transparent active:bg-transparent",
        variant: active === index ? "filled" : "text",
        onClick: () => select(index),
    } as any);

    const next = (step: number) => {
        const newActive = Math.min(active + step, pagesCountRef.current);
        const newStartIndex = (newActive - 1) * blogsPerPage;
        const newEndIndex = Math.min(newStartIndex + blogsPerPage, blogs.length);

        setActive(newActive);

        startIndexRef.current = newStartIndex;
        endIndexRef.current = newEndIndex;

        setBlogsToShow(blogs.slice(newStartIndex, newEndIndex));
    };

    const prev = (step: number) => {
        const newActive = Math.max(active - step, 1);
        const newStartIndex = (newActive - 1) * blogsPerPage;
        const newEndIndex = newStartIndex + blogsPerPage;

        setActive(newActive);

        startIndexRef.current = newStartIndex;
        endIndexRef.current = newEndIndex;

        setBlogsToShow(blogs.slice(newStartIndex, newEndIndex));
    };

    const select = (step: number) => {
        setActive(step);

        startIndexRef.current = (step - 1) * blogsPerPage;
        endIndexRef.current = startIndexRef.current + blogsPerPage;

        setBlogsToShow(blogs.slice(startIndexRef.current, endIndexRef.current));
    };

    const createNewBlogPost = () => {
        setIsDelete(false);
        navigate("/createBlogPage");
    }

    const editBlogPost = (id: string) => {
        setSelectedBlogId(id);
        setIsDelete(false);
        navigate({
            pathname: "/createBlogPage",
            search: createSearchParams({ blogId: id.toLowerCase() }).toString()
        });
        window.scrollTo(0, 0);
    }

    const removeBlogPost = (id: string) => {
        setSelectedBlogId(id);
        setIsDelete(true);
        setMesssage(t("AreYouSureYouWantToDeleteThisBlog"));
        handleDeleteDialog();
    }

    const deleteBlog = () => {
        setIsDelete(false);
        dispatch(DeleteBlog(selectedBlogId));
        setMesssage(t("BlogDeleted"));
        handleDeleteDialog();
    }

    const convertBlogModelToBlogPostModel = (blog: GetBlogResponse) => {
        const model: BlogPostModel = {
            id: blog.id,
            image: blog.image,
            tags: blog.tags,
            minutesToRead: blog.minutesToRead,
            title: blog.title,
            text: blog.text,
            description: blog.description,
            creator: blog.creator,
            creationTimestampUnix: blog.creationTimestampUnix,
            deleteBlogFunc: removeBlogPost,
            editBlogFunc: editBlogPost,
        }
        return model;
    }

    function CreateBlogPosts() {
        return (
            <div className="container justify-center max-w-[90%] mx-auto mt-[60px] rounded-b-[5px] bg-#FFFFFF h-auto space-y-5 shadow-4s">
                <div className="max-w-[95%] mx-auto">
                    <CardHeader color="transparent" floated={false} shadow={false} className="mx-0 flex items-center gap-5 py-6 transition-transform transform hover:scale-[1.01]" >
                        <Button id="createBlogBtn" variant="filled" size="lg" className="flex justify-center items-center w-[137px] h-[77px] bg-#D9D9D9 shadow-none hover:bg-#D9D9D9 hover:shadow-none duration-300 rounded-[5px]" onClick={createNewBlogPost}>
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 1V18M1 9.5H18" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Button>
                        <Typography className="text-#333333 text-[16px] font-bold">
                            {t("CreateANewBlog")}
                        </Typography>
                    </CardHeader>
                    {blogsToShow.map((blog, index) => {
                        const isHidden = (index >= sixBlogs)
                        return (
                            <div key={index} className={`flex-col ${isHidden ? "hidden" : ""}`}>
                                <div className="mx-auto h-[1px] w-full bg-#D9D9D9"></div>
                                <BlogPostComponent {...convertBlogModelToBlogPostModel(blog)} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    function CreatePages() {
        const pagesToCreate = [];

        for (let i = 1; i <= pagesCountRef.current; i++) {
            pagesToCreate.push(
                <IconButton key={i} {...getItemProps(i)}>
                    {i}
                </IconButton>
            );
        }

        return (
            <div className="flex justify-center items-center gap-2">
                {pagesToCreate}
            </div>
        );
    }

    function PagesReload() {
        pagesCountRef.current = blogData.blogSliceData.blogData.blogs.length <= sixBlogs ? 1 : Math.ceil(blogData.blogSliceData.blogData.blogs.length / sixBlogs);
        setBlogsPerPage(sixBlogs);
    }

    useEffect(() => {
        dispatch(GetAllBlogs());
    }, []);

    useEffect(() => {
        setBlogs(blogData.blogSliceData.blogData.blogs); //set blogs from blogs slice
        setBlogsToShow(blogData.blogSliceData.blogData.blogs); //set blogsToShow from blogs
        PagesReload();
    }, [blogData.blogSliceData]);

    useEffect(() => {
        if (blogData.blogSliceData.statusValues.statusCode === '200') {
            setSuccess(true);
            handleSuccessDialog();
            dispatch(GetAllBlogs());
        }
        else if (blogData.blogSliceData.statusValues.statusCode !== '') {
            setMesssage(t("UnsuccessText"));
            setSuccess(false);
            handleSuccessDialog();
        }
        dispatch(resetStatusCode());
    }, [blogData.blogSliceData.statusValues.statusCode]);

    return (
        <div className="font-roboto pb-[40px] bg-#FFFFFF">
            <div className="bg-#F6F6F6 h-[60px] xl:2xl:h-[70px] flex justify-center items-center">
                <h1 className="uppercase text-#333333 font-bold sm:text-[18px] lg:text-[20px] xl:text-[24px] 2xl:text-[36px]">
                    {t("AllBlogPosts")}
                </h1>
            </div>
            {
                !blogData.blogSliceData.statusValues.isLoading ? <CreateBlogPosts /> : <div className="flex justify-center py-[10%]">{LoadingPage(blogData.blogSliceData.statusValues.isLoading)}</div>
            }
            {
                blogData.blogSliceData.statusValues.isLoading ? <></> :
                    <div className="flex justify-center items-center mt-[40px] xl:2xl:mt-[60px]">
                        <div className="flex justify-center items-center gap-5 mr-3">
                            <Button id="leftEndArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => prev(pagesCountRef.current)} disabled={active === 1}>
                                <img src={LeftEndArrow} className="h-[14px] w-[16px]" alt=""></img>
                            </Button>
                            <Button id="leftArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => prev(1)} disabled={active === 1}>
                                <img src={LeftArrow} className="h-[14px] w-[8px]" alt=""></img>
                            </Button>
                        </div>
                        <CreatePages />
                        <div className="flex justify-center items-center gap-5 ml-3">
                            <Button id="rightArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => next(1)} disabled={active === pagesCountRef.current}>
                                <img src={RightArrow} className="h-[14px] w-[8px]" alt=""></img>
                            </Button>
                            <Button id="rightEndArrowBtn" variant="text" className="flex items-center shadow-none hover:shadow-none hover:bg-transparent focus:bg-transparent active:bg-transparent p-0" onClick={() => next(pagesCountRef.current)} disabled={active === pagesCountRef.current}>
                                <img src={RightEndArrow} className="h-[14px] w-[16px]" alt=""></img>
                            </Button>
                        </div>
                    </div>
            }
            <Dialog open={openDialog} handler={handleDeleteDialog || handleSuccessDialog} size="xs" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}>
                {
                    isDelete ? (
                        <DeleteCard onDoClickFunction={deleteBlog} onDoNotClickFunction={handleDeleteDialog} />) : (
                        <SuccessCard text={messsage} success={success} onClickFunction={handleSuccessDialog} />)
                }
            </Dialog>
        </div>
    );
}

export default AllBlogPostsPage;
