import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiAddress } from "../../app/apiModel";
import { CompletePasswordResetRequest, AccountActivation, ErrorResponse, GetLoginResponse, LogInRequest, InitPasswordReset, } from "../Authentification/authModel";
import { ConvertDateToString, getCurrentDate, handleFulfilled, handlePending, handleRejected, RejectedValue, WebShopBimRefreshToken, WebShopBimTAccessToken, } from "../../app/common";
import { AuthenticationParameters, AuthResponse, UserAgentApplication } from "msal";
import jwtDecode from "jwt-decode";
import { UserRole } from "../../app/common";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

// const msalInstance = new UserAgentApplication(msalConfig);

export const getMicrosoftAuth = createAsyncThunk<any, void, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/getMicrosoftAuth",
  async (_, { rejectWithValue }) => {
    try {
      const { instance } = useMsal();
      // const request: AuthenticationParameters = {
      //   scopes: [azureScope],
      //   prompt: 'select_account'
      // };

      // Prompt the user to log in
      const logIn = await instance.loginPopup(loginRequest);
      const response = await instance.acquireTokenSilent(loginRequest);
      localStorage.setItem(WebShopBimTAccessToken, response.accessToken);

      return response.accessToken === '' ? '' : response.accessToken;
    }
    catch (error) {
      let errorMessage = 'Authentication error, microsoft problem';
      let errorStatus = 406;
      if (error instanceof Error) {
        errorMessage = error.message;
        errorStatus = 405;
        errorMessage = 'You organization has restrictions set up, that prohibit you from log in in with this application.Please contact your IT-Admin.(ID: 76ab0d33-923b-4fba-9d8f-1e67ba197353) (Directory ID: 21cd9f45-8f33-49a1-8dd7-6a73c768ea1d)'
        //window.location.replace('https://login.microsoftonline.com/21cd9f45-8f33-49a1-8dd7-6a73c768ea1d/v2.0/adminconsent?client_id=1746931c-ced8-4a86-929f-0ddd3beba3be&redirect_uri=http%3A%2F%2Flocalhost%3A61126%2F&&state=eyJpZCI6IjY4ODI5NTMzLTZlYTMtNDcxZi1iMjVjLWUwNGJjMThmM2M4ZiIsInRzIjoxNjg1OTUzMTA4LCJtZXRob2QiOiJwb3B1cEludGVyYWN0aW9uIn0%3D&scope=api%3A%2F%2F76ab0d33-923b-4fba-9d8f-1e67ba197353%2Faccess_user_data%20openid%20profile');
      }

      return rejectWithValue({ message: errorMessage, status: errorStatus });

    }

  }
);

export const postRefreshMicrosoftAuth = createAsyncThunk<any, void, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/postRefreshMicrosoftAuth",
  async (_, { rejectWithValue }) => {

    let errorMessage = 'Authentication error, microsoft problem refresh token problem';
    let errorStatus = 406;

    try {

      const { instance } = useMsal();
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        const request = {
          ...loginRequest,
          account: accounts[0]
        };

        try {
          const response = await instance.acquireTokenSilent(request);
          let acessToken = response.accessToken;

          if (acessToken === null || acessToken === undefined) {
            return rejectWithValue({ message: errorMessage, status: errorStatus });
          }

          localStorage.setItem(WebShopBimTAccessToken, acessToken);
          return "200";

        } catch (error) {
          // Handle error
          return rejectWithValue({ message: errorMessage, status: errorStatus });
        }
      }
    }
    catch (error) {

      if (error instanceof Error) {
        errorMessage = error.message;
        errorStatus = 405;
      }
      return rejectWithValue({ message: errorMessage, status: errorStatus });
    }

  }
);

export const getTokenFromSSO = createAsyncThunk<GetLoginResponse, void, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/TokenFromSSO",
  async (_, { rejectWithValue }) => {

    const accessToken = localStorage.getItem(WebShopBimTAccessToken);
    const response = await fetch(apiAddress + "auth/ssotoken", {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
    if (response.status === 200) {
      const logInResponse = (await response.json()) as GetLoginResponse;
      localStorage.clear();
      localStorage.setItem(WebShopBimTAccessToken, logInResponse.accessToken);
      localStorage.setItem(WebShopBimRefreshToken, logInResponse.refreshToken);

      return logInResponse;
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail ?? response.statusText, status: response.status });
    }
  }
);

export const postActivateAccount = createAsyncThunk<string, AccountActivation, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/verifyaccount",
  async (user: AccountActivation, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    const response = await fetch(apiAddress + "auth/verifyaccount", {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        username: user.username,
        code: user.code,
      }),
    });

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const postForgotPassword = createAsyncThunk<string, InitPasswordReset, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/ResetPassword",
  async (user: InitPasswordReset, { rejectWithValue }) => {
    const response = await fetch(
      apiAddress + "auth/resetpassword?username=" + user.username,
      {
        method: "POST",
      }
    );

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const postInitActivateAccount = createAsyncThunk<string, string, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/InitActivateAccount",
  async (username: string, { rejectWithValue }) => {
    const response = await fetch(
      apiAddress + "auth/initverifyaccount?username=" + username,
      {
        method: "POST",
      }
    );

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const getLogInRequest = createAsyncThunk<GetLoginResponse, LogInRequest, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/Login",
  async (user: LogInRequest, { rejectWithValue }) => {

    const response = await fetch(apiAddress + "auth/signin", {
      headers: {
        Authorization: "Basic " + btoa(user.username + ":" + user.password),
      },
    });
    if (response.status === 200) {
      const logInResponse = (await response.json()) as GetLoginResponse;
      localStorage.setItem(WebShopBimTAccessToken, logInResponse.accessToken);
      localStorage.setItem(WebShopBimRefreshToken, logInResponse.refreshToken);

      const token = localStorage.getItem("webShopBimTAccessToken");
      let itemName: string | undefined;
      if (token !== null) {
        const decodedToken = jwtDecode(token) as { [key: string]: string };

        if (decodedToken) {
          const admin = UserRole.BlogAdmin.toString();
          itemName = decodedToken['role'];
        }
      }
      localStorage.setItem("role", itemName!);

      return logInResponse;
    }
    // else if (response.status === 401 || response.status === 402) {
    //   return rejectWithValue({ message: response.statusText, status: response.status })
    // }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: response.status });
    }
  }
);

export const postRefreshAccessToken = createAsyncThunk<string, void, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/RefreshAccessToken",
  async (_, { rejectWithValue }) => {
    const refreshToken = localStorage.getItem("webShopBimRefreshToken");
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Authorization", "Bearer " + refreshToken);

    const response = await fetch(apiAddress + "auth/refresh", {
      method: "POST",
      headers: requestHeaders,
    });

    if (response.status === 200) {
      let refreshToken = (await response.json()) as string;
      localStorage.setItem("webShopBimTAccessToken", refreshToken);
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const postRegister = createAsyncThunk<string, LogInRequest, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/Register",
  async (user: LogInRequest, { rejectWithValue }) => {
    const response = await fetch(apiAddress + "auth/signup", {
      method: "POST",
      headers: {
        Authorization: "Basic " + btoa(user.username + ":" + user.password),
      },
    });

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const postResetPassword = createAsyncThunk<string, CompletePasswordResetRequest, { rejectValue: RejectedValue }>(
  "AuthentificationSlice/CompleteResetPassword",
  async (user: CompletePasswordResetRequest, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    const response = await fetch(apiAddress + "auth/completeresetpassword", {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        username: user.username,
        password: user.password,
        code: user.code,
      }),
    });

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const AuthentificationSlice = createSlice({
  name: "authentificationSlice",
  initialState: {
    statusCode: "",
    isLoading: false,
    error: "",
    hasLoginData: false,
    tokenRefreshed: ConvertDateToString(getCurrentDate()),
    //shouldRefresh: false,
    shouldRedirectToLogin: false,
    repeatLastAction: false,
    isRefreshingToken: false,
  },
  reducers: {
    setShouldRefreshToken: (state) => {
      state.isRefreshingToken = true;
    },
    resetAuthentificationData: (state) => {
      localStorage.clear();
      state.error = "";
      state.isLoading = false;
      state.statusCode = "";
      state.hasLoginData = false;
      //   state.tokenRefreshed = ConvertDateToString(getCurrentDate());
      state.isRefreshingToken = false;
      state.shouldRedirectToLogin = false;
      state.repeatLastAction = false;
    },
    restartShouldRedirectToLogin: (state) => {
      state.shouldRedirectToLogin = false;
    },
    setHasLoginData: (state) => {
      state.hasLoginData = true;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload
    }
  },
  extraReducers(builder) {

    //getMicrosoftAuth
    builder
      .addCase(getMicrosoftAuth.fulfilled, (state, action) => {
        state.statusCode = handleFulfilled(state, '200');
        state.hasLoginData = true;
      })
      .addCase(getMicrosoftAuth.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(getMicrosoftAuth.pending, (state) => {
        handlePending(state);
      });
    //Activate account
    builder
      .addCase(postActivateAccount.fulfilled, (state, action) => {
        state.statusCode = handleFulfilled(state, action.payload);
      })
      .addCase(postActivateAccount.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(postActivateAccount.pending, (state) => {
        handlePending(state);
      });

    //Fortog password
    builder
      .addCase(postForgotPassword.fulfilled, (state, action) => {
        state.statusCode = handleFulfilled(state, action.payload);
      })
      .addCase(postForgotPassword.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(postForgotPassword.pending, (state) => {
        handlePending(state);
      });
    //InitActivateAccount
    builder
      .addCase(postInitActivateAccount.fulfilled, (state, action) => {
        state.statusCode = handleFulfilled(state, action.payload);
      })
      .addCase(postInitActivateAccount.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(postInitActivateAccount.pending, (state) => {
        handlePending(state);
      });

    //Login
    builder
      .addCase(
        getLogInRequest.fulfilled,
        (state, action: PayloadAction<GetLoginResponse>) => {
          state.isLoading = false;
          state.hasLoginData = true;
        }
      )
      .addCase(getLogInRequest.pending, (state) => {
        handlePending(state);
      })
      .addCase(getLogInRequest.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      });
    //getTokenFromSSO
    builder
      .addCase(getTokenFromSSO.fulfilled, (state, action: PayloadAction<GetLoginResponse>) => {
        state.isLoading = false;
        state.hasLoginData = true;
      }
      )
      .addCase(getTokenFromSSO.pending, (state) => {
        handlePending(state);
      })
      .addCase(getTokenFromSSO.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      });

    // .addMatcher((action) => getTokenFromSSO.rejected.match(action) && action.error.message === 'Unauthorized',
    //         (action, state) => {
    //             state.isLoading = false;
    //             state.error = 'Unauthorized';
    // })

    //Register
    builder
      .addCase(postRegister.fulfilled, (state, action) => {
        state.statusCode = handleFulfilled(state, action.payload);
      })
      .addCase(postRegister.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(postRegister.pending, (state) => {
        handlePending(state);
      });

    //Reset password
    builder
      .addCase(postResetPassword.fulfilled, (state, action) => {
        state.statusCode = handleFulfilled(state, action.payload);
      })
      .addCase(postResetPassword.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(postResetPassword.pending, (state) => {
        handlePending(state);
      });
    //Refresh Access Token
    builder
      .addCase(postRefreshAccessToken.fulfilled, (state, action) => {
        state.statusCode = handleFulfilled(state, action.payload);
        state.tokenRefreshed = ConvertDateToString(getCurrentDate());
        state.isRefreshingToken = false;
        state.shouldRedirectToLogin = false;
        state.repeatLastAction = true;
        state.isRefreshingToken = false;
      })
      .addCase(postRefreshAccessToken.pending, (state, action) => {
        handlePending(state);
        state.isRefreshingToken = true;
      })
      .addCase(postRefreshAccessToken.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
        state.shouldRedirectToLogin = true;
        state.isRefreshingToken = false;
        state.repeatLastAction = false;
        state.isRefreshingToken = false;
      });
    //Refresh Microsoft Token
    builder
      .addCase(postRefreshMicrosoftAuth.fulfilled, (state, action) => {
        state.statusCode = handleFulfilled(state, action.payload);
        state.tokenRefreshed = ConvertDateToString(getCurrentDate());
        state.isRefreshingToken = false;
        state.shouldRedirectToLogin = false;
        state.repeatLastAction = true;
        state.isRefreshingToken = false;
      })
      .addCase(postRefreshMicrosoftAuth.pending, (state, action) => {
        handlePending(state);
        state.isRefreshingToken = true;
      })
      .addCase(postRefreshMicrosoftAuth.rejected, (state, action) => {
        handleRejected(state, action.payload ? action.payload : { message: 'Error in application', status: -1 })
        state.shouldRedirectToLogin = true;
        state.isRefreshingToken = false;
        state.repeatLastAction = false;
        state.isRefreshingToken = false;
      });
  },
});

export const { setShouldRefreshToken, resetAuthentificationData, restartShouldRedirectToLogin, setHasLoginData, setErrorMessage } =
  AuthentificationSlice.actions;

export default AuthentificationSlice.reducer;
