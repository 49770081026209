import { Typography } from "@material-tailwind/react";
import PriceCard from "./PriceCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { GetAllValidAndActiveProducts, GetPricesForProductFamily, PostCreateCheckoutSession, resetSessionUrl, resetStripeSlice, } from "../../features/stripe/stripeSlice";
import { useEffect, useState } from "react";
import { CreateCheckoutSessionRequest, ProductItemModelNew, } from "../../features/Product/productModel";
import { GetBrowserLanguage, GetCurrencyIndex, GetFormattedPrice, GetUserCurrency, } from "../../app/common";
import { useNavigate } from "react-router-dom";
import { GetUser, resetStatusCode } from "../../features/Accounts/accountSlice";
import { useTranslation } from "react-i18next";

const productState: ProductItemModelNew[] = [];

function PricesComponent(props: { familyTypeValue: string }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const stripeSliceData = useSelector((state: RootState) => state.StripeSlice);
  const accountSliceData = useSelector(
    (state: RootState) => state.AccountSlice
  );
  const [familyType, setFamilyType] = useState(props.familyTypeValue);
  const [productItems, setproductItems] = useState(productState);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetUser());
    dispatch(GetAllValidAndActiveProducts());
  }, []);

  useEffect(() => {
    if (stripeSliceData.stripeSliceData.statusValues.statusCode === "401") {
      dispatch(resetStripeSlice());
      dispatch(resetStatusCode());
      navigate("/login");
    }
  }, [stripeSliceData.stripeSliceData.statusValues.statusCode]);

  useEffect(() => {
    if (familyType === "none") {
      navigate("/");
    } else if (familyType !== "") {
      dispatch(GetPricesForProductFamily(familyType));
    }
  }, [familyType]);

  useEffect(() => {
    let products: ProductItemModelNew[] = [];
    let productItemsByType =
      stripeSliceData.stripeSliceData.stripeData.productValue.products.filter(
        (x) => x.productFamily.toLowerCase() === familyType.toLowerCase()
      );

    productItemsByType.forEach((product) => {
      let prices = stripeSliceData.stripeSliceData.stripeData.currentFamilyPrices;
      prices.prices.forEach((price, index) => {
        if (price.productId === product.id) {
          let userLocale = GetUserCurrency(
            accountSliceData.accountSliceData.accountData.loggedUserData
              .currency
          );
          let curencyIndex = GetCurrencyIndex(
            price.currencyOptions,
            userLocale
          );
          let productWithPrice: ProductItemModelNew = {
            productId: product.id,
            priceId: price.id,
            currency: price.currencyOptions[curencyIndex].currency,
            amount: price.currencyOptions[curencyIndex].unitAmount,
            locale: GetBrowserLanguage(),
            title: price.intervalCount + t(`${price.interval + "Title"}`),
            description: price.intervalCount > 1 ? t("FirstPartOfDescriptionS") + price.intervalCount + t(`${price.interval + "s"}`) : t("FirstPartOfDescription") + t(`${price.interval}`),
            intervalCount: price.intervalCount,
            interval: price.interval,
            priceDescription: GetFormattedPrice(price.currencyOptions[curencyIndex].unitAmount, t(`${price.currencyOptions[curencyIndex].currency}`)) + " / " +
              (price.intervalCount > 1 ? price.intervalCount + " " + t(`${price.interval + "sCL"}`) : t(`${price.interval + "CL"}`)),
            name: product.name
          };
          products.push(productWithPrice);
        }
      });
    });
    setproductItems(products);
  }, [stripeSliceData.stripeSliceData.stripeData.currentFamilyPrices, i18n.language]);

  useEffect(() => {
    if (stripeSliceData.stripeSliceData.stripeData.sessionUrl !== "") {
      window.open(
        stripeSliceData.stripeSliceData.stripeData.sessionUrl,
        "_blank",
        "noopener,noreferrer"
      );
    }
    dispatch(resetSessionUrl());
  }, [stripeSliceData.stripeSliceData.stripeData.sessionUrl]);

  function onRemoveClick(
    locale: string,
    productId: string,
    priceId: string,
    currency: string
  ) {
    const request: CreateCheckoutSessionRequest = {
      locale: locale,
      productId: productId,
      quantity: 1,
      priceId: priceId,
      currency: currency,
    };
    dispatch(PostCreateCheckoutSession(request));
  }

  return (
    <div>
      <Typography className="uppercase text-lg 2xl:text-3xl font-bold tracking-[0.9px] text-center my-[30px] lg:my-[60px]">
        {t("Prices")}
      </Typography>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-[40px]">
        {productItems.map((product, index) => (
          <PriceCard
            key={index + 100}
            name={product.name}
            locale={product.locale}
            priceId={product.priceId}
            productId={product.productId}
            currency={product.currency}
            title={product.title}
            description={product.description}
            price={product.priceDescription}
            onClickFunction={(locale, productId, priceId, currency) => onRemoveClick(locale, productId, priceId, currency)
            }
          />
        ))}
      </div>
    </div>
  );
}

export default PricesComponent;
