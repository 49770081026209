import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAddress } from "../../app/apiModel";
import { ErrorResponse } from "../Authentification/authModel";
import { AccountData, AccountSliceData, CheckUserDataResponse, CreateUserDataRequest, GetUserResponse } from "./accountModel";
import { RejectedValue, StatusValue, handleFulfilled, handlePending, handleRejected } from "../../app/common";

export const CreateAccount = createAsyncThunk<string, void, { rejectValue: RejectedValue }>(
    'AccountSlicer/CreateAccount',
    async (_, { rejectWithValue }) => {
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        const response = await fetch(apiAddress + 'users/create', {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + accessToken
            },
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const CheckUserData = createAsyncThunk<CheckUserDataResponse, void, { rejectValue: RejectedValue }>(
    'AccountSlicer/CheckUserdata',
    async (_, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);
        const response = await fetch(apiAddress + 'users/checkuserdata', {
            method: 'GET',
            headers: requestHeaders
        })
        if (response.status === 200) {

            const checkUserDataResponse = await response.json() as CheckUserDataResponse;
            return checkUserDataResponse;
        }
        else if (response.status === 403) {
            response.headers.forEach(function (val, key) { console.log(key + ' -> ' + val); });
            let account = response.headers.get('Content-Type');
            console.log(account);
            return rejectWithValue({ message: account ?? 'Account does not exist', status: response.status });
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: response.status });
        }
    }
);

export const GetUser = createAsyncThunk<GetUserResponse, void, { rejectValue: RejectedValue }>(
    'AccountSlicer/GetUser',
    async (_, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);
        const response = await fetch(apiAddress + 'users/getuser', {
            method: 'GET',
            headers: requestHeaders
        })
        if (response.status === 200) {
            const getUserResponse = await response.json() as GetUserResponse;
            return getUserResponse;
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
        }
    }
);

export const PostCreateUserData = createAsyncThunk<string, CreateUserDataRequest, { rejectValue: RejectedValue }>(
    'AccountSlicer/CreateUserData',
    async (userData: CreateUserDataRequest, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'users/createuserdata', {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(userData)
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
        }
    }
);

export const PostEditUserData = createAsyncThunk<string, CreateUserDataRequest, { rejectValue: RejectedValue }>(
    'AccountSlicer/EditUserData',
    async (userData: CreateUserDataRequest, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'users/edituserdata', {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(userData)
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 401 || response.status === 402) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
        }
    }
);

const initialLoggedUserData: GetUserResponse = {
    userId: "",
    issuer: "",
    subject: "",
    email: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    phoneNumber: "",
    gender: "",
    city: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    country: "",
    isActive: false,
    customerId: "",
    currency: "",
    locale: ""
}

const initStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    error: "",
}

const initialAccountData: AccountData = {
    loggedUserData: initialLoggedUserData
}

const initialAccountSliceData: AccountSliceData = {
    accountData: initialAccountData,
    statusValues: initStatusValues
}

const AccountSlice = createSlice({
    name: 'AccountSlicer',
    initialState: {
        accountSliceData: initialAccountSliceData,
        shouldCreateData: false,
        hasUserData: false
    },
    reducers: {
        resetAccount: (state) => {
            state.accountSliceData.statusValues.error = '';
            state.accountSliceData.statusValues.statusCode = '';
            state.accountSliceData.statusValues.isLoading = false;
            state.hasUserData = false;
            state.shouldCreateData = false;
            state.accountSliceData.accountData.loggedUserData = initialLoggedUserData;
        },
        resetStatusCode: (state) => {
            state.accountSliceData.statusValues.statusCode = '';
            state.accountSliceData.statusValues.error = '';
        },
        setLoggedUser: (state, action) => {
            state.accountSliceData.accountData.loggedUserData = action.payload;
        }
    },
    extraReducers(builder) {
        //CreateAccount
        builder.addCase(CreateAccount.fulfilled, (state, action) => {
            state.accountSliceData.statusValues.statusCode = handleFulfilled(state.accountSliceData.statusValues, action.payload);
        })
            .addCase(CreateAccount.rejected, (state, action) => {
                handleRejected(state.accountSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(CreateAccount.pending, (state) => {
                handlePending(state.accountSliceData.statusValues);
            })
        //CheckUserData
        builder.addCase(CheckUserData.fulfilled, (state, action) => {
            state.hasUserData = handleFulfilled(state.accountSliceData.statusValues, action.payload.dataComplete);
            if (state.hasUserData === false) {
                state.shouldCreateData = true;
            }
        })
            .addCase(CheckUserData.rejected, (state, action) => {
                handleRejected(state.accountSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })

            })
            .addCase(CheckUserData.pending, (state) => {
                handlePending(state.accountSliceData.statusValues);
            })
        //CreateUserData
        builder.addCase(PostCreateUserData.fulfilled, (state, action) => {
            state.accountSliceData.statusValues.statusCode = handleFulfilled(state.accountSliceData.statusValues, action.payload);
            state.shouldCreateData = false;
            state.hasUserData = true;
        })
            .addCase(PostCreateUserData.rejected, (state, action) => {
                handleRejected(state.accountSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PostCreateUserData.pending, (state) => {
                handlePending(state.accountSliceData.statusValues);
            })
        //GetUser
        builder.addCase(GetUser.fulfilled, (state, action) => {
            state.accountSliceData.accountData.loggedUserData = handleFulfilled(state.accountSliceData.statusValues, action.payload);
            state.shouldCreateData = false;
        })
            .addCase(GetUser.rejected, (state, action) => {
                handleRejected(state.accountSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(GetUser.pending, (state) => {
                handlePending(state.accountSliceData.statusValues);
            })
        //EditUserData
        builder.addCase(PostEditUserData.fulfilled, (state, action) => {
            state.accountSliceData.statusValues.statusCode = handleFulfilled(state.accountSliceData.statusValues, action.payload);
            state.shouldCreateData = false;
        })
            .addCase(PostEditUserData.rejected, (state, action) => {
                handleRejected(state.accountSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PostEditUserData.pending, (state) => {
                handlePending(state.accountSliceData.statusValues);
            })
    },
})

export const { resetAccount, resetStatusCode, setLoggedUser } = AccountSlice.actions;
export default AccountSlice.reducer;
