import { Button, Typography } from "@material-tailwind/react";
import tgaLogoNew from "../../Images/tgaLogoNew.png";
import { useTranslation } from "react-i18next";

function SolutionsComponent() {
  const { t } = useTranslation();

  const title = "TGA " + t("Concept");
  const desc = t("TheUltimateEngineeringSolution");
  const text = t("SolutionsText");

  return (
    <div className="mt-[35px]">
      <Typography className="uppercase font-bold text-lg 2xl:text-2xl text-center py-[30px] xl:py-[60px]">
        {t("Solutions")}
      </Typography>
      <div className="bg-tgaFrameMobile md:bg-tgaFrameMd lg:bg-tgaFrameLg xl:bg-tgaFrameXl 2xl:bg-tgaFrame2xl bg-cover bg-center px-[20px] md:px-[100px] w-full items-center text-#2C2C2C grid grid-cols-1 md:grid-cols-3">
        <div className="hidden md:block">
          <img src={tgaLogoNew} className="w-auto h-full max-h-[300px] mx-auto my-[20px]" alt="" />
        </div>
        <div className="md:col-span-2 flex flex-col items-center md:items-start md:pl-[15%] lg:pl-[20%] py-[20px] md:py-[55px]">
          <Typography className="uppercase text-base 2xl:text-xl text-center md:text-left font-semibold mt-[20px]">
            {title}
          </Typography>
          <Typography className="uppercase text-base 2xl:text-xl font-light text-center md:text-left mt-[15px] max-w-[250px] md:max-w-[1000px]">
            {desc}
          </Typography>
          <Typography className="text-sm 2xl:text-lg font-light tracking-[0.7px] text-center md:text-left mt-[15px] max-w-[600px] md:max-w-[1000px]">
            {text}
          </Typography>
          <a href="/productsPage">
            <Button id="seeMoreBtn" className="my-[25px] bg-#FF8136 shadow-none hover:bg-#FF6104 hover:shadow-none w-[186px] h-[40px] text-base font-bold p-0">
              {t("SeeMore")}
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default SolutionsComponent;
