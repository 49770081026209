import { Button, Card, CardFooter, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

interface PriceProps {
  priceId: string;
  name: string;
  productId: string
  locale: string,
  currency: string
  title: string;
  description: string;
  price: string;
  onClickFunction: (locale: string, priceId: string, productId: string, currency: string) => void;
}

function PriceCard({ priceId, name, productId, locale, currency, title, description, price, onClickFunction }: PriceProps) {
  const { t } = useTranslation();
  return (
    <>
      <Card className="w-[284px] h-[350px] 2xl:w-[426px] 2xl:h-[400px] flex flex-col items-center mx-auto text-#333333 shadow-orange">
        <Typography className="mt-[40px] 2xl:mt-[60px] uppercase text-lg 2xl:text-2xl font-semibold tracking-[0.9px] 2xl:tracking-[1.4px] p-0 px-[10px] text-center">
          {name}
        </Typography>
        <Typography className="mt-[17px] 2xl:mt-[20px] uppercase text-lg 2xl:text-2xl font-semibold tracking-[0.9px] 2xl:tracking-[1.4px] p-0">
          {title}
        </Typography>
        <Typography className="mt-[17px] 2xl:mt-[20px] text-base 2xl:text-xl font-light tracking-[0.8px] 2xl:tracking-[1.3px] p-0 px-5 text-center">
          {description}
        </Typography>
        <Typography className="mt-[17px] 2xl:mt-[20px] text-base 2xl:text-xl font-bold tracking-[0.8px] 2xl:tracking-[1.3px] p-0">
          {price} + {t("tax")}
        </Typography>
        <Typography className="mt-[17px] 2xl:mt-[20px] text-base 2xl:text-xl font-extralight italic tracking-[0.8px] 2xl:tracking-[1.3px] p-0 px-5 text-center">
          {t("PricePerSeat")}
        </Typography>
        <CardFooter className="flex absolute bottom-[10px]">
          <Button id="buyNowBtn" disabled={false} className="hover:bg-#FF6104 bg-#FF8136 h-[40px] w-[170px] text-sm font-semibold shadow-none hover:shadow-none flex justify-center items-center" onClick={() => onClickFunction(locale, productId, priceId, currency)}>
            {t("BuyNow")}
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}

export default PriceCard;
