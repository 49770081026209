import AboutCard from "./AboutCard";
import About1 from "../../Images/about1.png";
import About2 from "../../Images/about2.png";
import About3 from "../../Images/about3.png";
import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

function HomePageAboutComponent() {
  const { t } = useTranslation();
  const abouts = [
    {
      photo: About1,
      title: t("Collaboration"),
      text: t("CollaborationText"),
    },
    {
      photo: About2,
      title: t("Innovation"),
      text: t("InnovationText"),
    },
    {
      photo: About3,
      title: t("Dedication"),
      text: t("DedicationText"),
    },
  ];

  return (
    <section id="about" className="px-[20px] xl:px-[100px] xl:scroll-mt-[60px] 2xl:scroll-mt-[80px]">
      <Typography className="uppercase font-bold text-lg 2xl:text-2xl text-center py-[30px] xl:py-[60px]">
        {t("About")} BIMAGINE
      </Typography>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-[70px] xl:gap-0 justify-between mx-auto">
        {abouts.map((about, index) => (
          <AboutCard key={index} aboutCard={about} />
        ))}
      </div>
    </section>
  );
}

export default HomePageAboutComponent;
