import { Tab, Tabs, TabsHeader } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ProductPageProps {
  page: string;
}

const productTabs = [
  {
    label: "TGA Concept",
    value: "tgaconcept",
  },
  {
    label: "Calculation Tools",
    value: "calculationtools",
  },
];

export function ProductsPage(props: ProductPageProps) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");

  const tabClicked = (product: string) => {
    navigate({
      pathname: "/productsPage/" + product,
    });
    setActiveTab(product);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    setActiveTab(props.page);
  }, []);

  return (
    <div className="bg-#FFFFFF h-[40px] fixed z-10 w-full">
      <Tabs value={activeTab} className="w-full h-[50px]">
        <TabsHeader className="bg-#F6F6F6 shadow-circle sticky mb-[30px] lg:mb-[60px] 2xl:mb-[100px] justify-center h-[40px] rounded-t-[0px] rounded-b-[0px]" indicatorProps={{ className: "bg-transparent shadow-none", }}>
          <div className="flex flex-wrap">
            {productTabs.map(({ label, value }, index) => (
              <div key={value} className="flex flex-row justify-center items-center h-[40px]">
                <Tab id={value + index} key={index} value={value} className={`w-auto mx-[10px] sm:mx-[30px] 2xl:mx-[100px] text-[12px] sm:text-[16px] 2xl:text-[18px] leading-6 font-medium uppercase text-#333333 ${activeTab === value ? "text-#FF6104" : ""}`} onClick={() => tabClicked(value)}>{label}</Tab>
                {index < productTabs.length - 1 ?
                  <div className="w-[1px] h-[20px] sm:h-[26px] bg-#989898"></div> : <></>
                }
              </div>))}
          </div>
        </TabsHeader>
      </Tabs>
    </div >
  );
}
