import { Typography } from "@material-tailwind/react";
import FAQComponent from "./FAQComponent";
import { useTranslation } from "react-i18next";

function TGAFAQsComponent() {
  const { t } = useTranslation();
  const faqs = [
    {
      id: "1",
      question: t("WhatIsTGAConcept"),
      answer: t("WhatIsTGAConceptAnswer"),
    },
    {
      id: "2",
      question: t("HowDoICreateAnAccountAndLicense"),
      answer: t("HowDoICreateAnAccountAndLicenseAnswer"),
    },
    {
      id: "3",
      question: t("WhatAreTemplatesInTGAConcept"),
      answer: t("WhatAreTemplatesInTGAConceptAnswer"),
    },
    {
      id: "4",
      question: t("HowDoIDefineRoomsInTGAConcept"),
      answer: t("HowDoIDefineRoomsInTGAConceptAnswer"),
    },
  ];

  return (
    <>
      <div>
        <Typography className="uppercase text-lg 2xl:text-3xl font-bold tracking-[0.9px] text-center my-[30px] lg:my-[60px]">
          {t("FAQ")}
        </Typography>
        <div className="mx-[20px] lg:mx-[100px] mt-[30px] lg:mt-[40px]">
          {faqs.map((faq) => (
            <FAQComponent key={faq.id} faq={faq} />
          ))}
        </div>
      </div>
    </>
  );
}

export default TGAFAQsComponent;
