export function ValidateEmail(mail: string | undefined) {
    if (mail === undefined) {
        return
    }
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    return (false)
}

export function ValidatePhoneNumber(phoneNumber: string | undefined) {
    let phoneNumberMaxLength: number = 15;

    if (phoneNumber === undefined) {
        return
    }
    if (/^(\+[0-9]+)?[0-9]*$/.test(phoneNumber) && phoneNumber.length < phoneNumberMaxLength) {
        return (true)
    }
    return (false)
}

export function ValidateBirthDate(birthDate: string | undefined, minDate: string, maxDate: string) {
    if (birthDate === undefined) {
        return
    }

    var inputDate = new Date(birthDate)
    var sixteenYearsBeforeNow = new Date(maxDate)
    var hundredYearsBeforeNow = new Date(minDate)


    if (inputDate < sixteenYearsBeforeNow && inputDate > hundredYearsBeforeNow) {
        return (true)
    }

    return (false)
}

export function ValidatePassword(newPassword: string, confirmPassword: string) {
    if (newPassword === confirmPassword) {
        return true;
    }
    else {
        return false;
    }
}

export function ValidatePasswordContent(password?: string) {
    if (password === undefined) {
        return true;
    }
    else if (/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/.test(password)) {
        return true;
    }
    else {
        return false;
    }
}