import { Button, Card, CardBody, CardFooter, CardHeader, Chip, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useNavigate } from "react-router-dom";
import { GetBlogResponse } from "../../features/Blogs/BlogModel";

export const BlogComponent: React.FC<GetBlogResponse> = ({ id, image, creator, minutesToRead, title, description, tags }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const buttonClicked = () => {
    navigate({
      pathname: "/singleBlogPage",
      search: createSearchParams({ blogId: id.toLowerCase() }).toString(),
    });
    window.scrollTo(0, 0);
  }

  return (
    <Card color="transparent" className="mt-6 font-roboto w-[280px] h-[445px] lg:w-[398px] lg:h-[489px] xl:2xl:w-[398px] xl:2xl:h-[489px] shadow-none">
      <CardHeader floated={false} shadow={false} className="w-[280px] h-[159px] lg:w-[397px] lg:h-[230px] xl:2xl:w-[397px] xl:2xl:h-[230px] m-0 bg-transparent rounded-[5px] cursor-pointer" onClick={buttonClicked}>
        <img src={image} alt="blog" />
      </CardHeader>
      <CardBody className="pl-0 pr-0 pt-[10px] m-0">
        <div className="flex justify-between">
          <Typography className="text-#333333 text-[16px]">
            {creator}
          </Typography>
          <Typography className="pl-0 uppercase text-#FF6104 text-[16px]">
            {minutesToRead} {t("MinToRead")}
          </Typography>
        </div>
        <Typography className="mt-1 text-#333333 text-[18px] lg:text-[20px] xl:2xl:text-[20px] font-bold flex-nowrap overflow-hidden text-ellipsis max-w-[397px] max-h-[60px] cursor-pointer under hover:underline" onClick={buttonClicked}>
          {title}
        </Typography>
        <Typography className="mb-1 text-#333333 text-[14px] flex-nowrap overflow-hidden text-ellipsis max-w-[397px] max-h-[60px]">
          {description}
        </Typography>
        <div className="flex py-6 md:py-3">
          {tags.map((tag) => (
            <Chip variant="outlined" size="sm" value={tag} color="gray" className="mr-[10px] rounded-[5px] lowercase text-[14px]" key={tag} />
          ))}
        </div>
      </CardBody>
      <CardFooter className="p-0 absolute bottom-0 left-0">
        <Button id="readMoreBtn" className="bg-#4E4E4E hover:bg-#6F6F6F text-#FFFFFF rounded-[5px] w-[186px] h-[40px] shadow-none hover:shadow-none" onClick={buttonClicked}>
          {t("ReadMore")}
        </Button>
      </CardFooter>
    </Card>
  );
}