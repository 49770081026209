import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { LogInRequest } from "../authModel";
import { getLogInRequest } from "../authentificationSlice";
import { CheckUserData, CreateAccount, resetStatusCode } from "../../Accounts/accountSlice";
import { useNavigate } from "react-router-dom";
import { ValidateEmail } from "../../../app/validation";
import { LoadingPage } from "../../CommonPages/loadingPage";
import LoginCard from "./loginCard";
import { Alert, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export function LogIn() {
    const [errorMesssage, seterrorMesssage] = useState("");
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const logInData = useSelector((state: RootState) => state.AuthentificationSlice);
    const userData = useSelector((state: RootState) => state.AccountSlice);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        submitFunction(email, password);
    };

    const submitFunction = (email: string, password: string) => {
        if (email && ValidateEmail(email)) {
            if (password && password !== "") {
                const logInReq: LogInRequest = {
                    username: email,
                    password: password
                };
                dispatch(getLogInRequest(logInReq));
                // setEmail('');
                // setPassword('');
            } else {
                seterrorMesssage(t("InsertPassword"));
                setOpen(true);
            }
        } else {
            seterrorMesssage(t("InvalidEmail"));
            setOpen(true);
        }
    };

    useEffect(() => {
        if (logInData.error !== null && logInData.error !== '') {
            seterrorMesssage(t("ValidationFailed") ?? t("AnErrorOccurred"));
            setOpen(true);
        }
        if (logInData.hasLoginData) {
            dispatch(CheckUserData());
        }
    }, [logInData]);

    useEffect(() => {
        if (userData.accountSliceData.statusValues.statusCode === "403") {
            dispatch(CreateAccount());
        } else if (userData.accountSliceData.statusValues.statusCode === "OK" || userData.accountSliceData.statusValues.statusCode === "200") {
            dispatch(CheckUserData());
        }
    }, [userData.accountSliceData.statusValues.statusCode]);

    useEffect(() => {
        if (userData.accountSliceData.statusValues.error !== null) {
            seterrorMesssage(userData.accountSliceData.statusValues.error ?? t("AnErrorOccurred"));
            setOpen(true);
        }
    }, [userData.shouldCreateData]);

    useEffect(() => {
        if (logInData.hasLoginData && userData.hasUserData && !userData.shouldCreateData) {
            navigate('/');
        }
    }, [userData]);

    if (logInData.isLoading) {
        return (LoadingPage(logInData.isLoading))
    }

    if (userData.accountSliceData.statusValues.isLoading) {
        return (LoadingPage(userData.accountSliceData.statusValues.isLoading))
    }

    if (logInData.hasLoginData && !userData.hasUserData && userData.shouldCreateData) {
        navigate("/createUserData");
    }

    return (
        <div className="flex justify-center m-[5%]">
            <form className="space-y-5" onSubmit={handleSubmit}>
                {errorMesssage !== "" ?
                    <Alert color="red" open={open} onClose={() => setOpen(false)} className="">
                        <Typography className="text-[14px] flex-nowrap overflow-hidden text-ellipsis max-w-[320px] max-lg:w-[400px] max-2xl:w-[500px]">
                            {errorMesssage}
                        </Typography>
                    </Alert>
                    : <></>}
                <LoginCard onClickFunction={submitFunction} setEmailForm={setEmail} setPasswordForm={setPassword} />
                <input type="submit" style={{ display: "none" }} />
            </form>
        </div>
    );
}
