import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAddress } from "../../app/apiModel";
import { RejectedValue, StatusValue, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { ErrorResponse } from "../Authentification/authModel";
import { CreateCheckoutSessionRequest, CreateCheckoutSessionResponse } from "../Product/productModel";
import {
  CustomerSubscriptionsResponse, GetAllValidAndActiveProductsResponse, GetPricesForProductFamilyResponse,
  GetSubscriptionScheduleResponse, GetSubscriptionSchedulesRequest, GetSubscriptionSchedulesResponse, StripeData, StripeSliceData, SubcriptionToUpdateIntervalResponse,
  SubcriptionToUpdateTierResponse, UpdatePaymentIntervalRequest, UpdateValues,
} from "./stripeModel";

export const GetAllValidAndActiveProducts = createAsyncThunk<GetAllValidAndActiveProductsResponse, void, { rejectValue: RejectedValue }>(
  "no_StripeSlice/GetAllValidAndActiveProducts",
  async (_, { rejectWithValue }) => {
    const response = await fetch(apiAddress + "products/getall", {
      method: "GET",
    });

    if (response.status === 200) {
      const responseModel = (await response.json()) as GetAllValidAndActiveProductsResponse;
      return responseModel;
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const GetPricesForProductFamily = createAsyncThunk<GetPricesForProductFamilyResponse, string, { rejectValue: RejectedValue }>(
  "no_StripeSlice/GetPricesForProductFamily",
  async (productFamily: string, { rejectWithValue }) => {
    const response = await fetch(apiAddress + "prices/getprices?productFamily=" + productFamily,
      {
        method: "GET",
      }
    );

    if (response.status === 200) {
      const responseModel = (await response.json()) as GetPricesForProductFamilyResponse;
      return responseModel;
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const PostCreateCheckoutSession = createAsyncThunk<CreateCheckoutSessionResponse, CreateCheckoutSessionRequest, { rejectValue: RejectedValue }>(
  "StripeSlice/PostCreateCheckoutSession",
  async (
    checkoutSesionParam: CreateCheckoutSessionRequest,
    { rejectWithValue }
  ) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");

    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "payments/checkout", {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify(checkoutSesionParam),
    });

    if (response.status === 200) {
      const responseModel = (await response.json()) as CreateCheckoutSessionResponse;
      return responseModel;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const PostCreateCustomerPortalSession = createAsyncThunk<CreateCheckoutSessionResponse, void, { rejectValue: RejectedValue }>(
  "StripeSlice/PostCreateCustomerPortalSession",
  async (_, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");

    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress + "customer/create-customer-portal-session",
      {
        method: "POST",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const responseModel = (await response.json()) as CreateCheckoutSessionResponse;
      return responseModel;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const GetCustomerSubscriptions = createAsyncThunk<CustomerSubscriptionsResponse, void, { rejectValue: RejectedValue }>(
  "StripeSlice/GetCustomerSubscriptions", async (_, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress + "subscriptions/getcustomersubscriptions",
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const responseModel = (await response.json()) as CustomerSubscriptionsResponse;
      return responseModel;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  });

export const GetSusbcriptionToUpdateInterval = createAsyncThunk<SubcriptionToUpdateIntervalResponse, string, { rejectValue: RejectedValue }>(
  "StripeSlice/GetSusbcriptionToUpdateInterval",
  async (subscriptionId: string, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress + "subscriptions/getsubscriptiontoupdateinterval?subscriptionId=" + subscriptionId,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const responseModel = (await response.json()) as SubcriptionToUpdateIntervalResponse;
      return responseModel;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const PostUpdatePaymentInterval = createAsyncThunk<string, UpdatePaymentIntervalRequest, { rejectValue: RejectedValue }>(
  "StripeSlice/PostUpdatePaymentInterval",
  async (updatePaymentModel: UpdatePaymentIntervalRequest, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress + "subscriptions/updatepaymentinterval",
      {
        method: "POST",
        headers: requestHeaders,
        body: JSON.stringify(updatePaymentModel),
      }
    );

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const GetSusbcriptionToUpdateTier = createAsyncThunk<SubcriptionToUpdateTierResponse, string, { rejectValue: RejectedValue }>(
  "StripeSlice/GetSusbcriptionToUpdateTier",
  async (subscriptionId: string, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress + "subscriptions/getsubscriptiontoupdatetier?subscriptionId=" + subscriptionId,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const responseModel = (await response.json()) as SubcriptionToUpdateTierResponse;
      return responseModel;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status, });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const PostUpdateSubscriptionTier = createAsyncThunk<string, UpdatePaymentIntervalRequest, { rejectValue: RejectedValue }>(
  "StripeSlice/PostUpdateSubscriptionTier",
  async (updatePaymentModel: UpdatePaymentIntervalRequest, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "subscriptions/updateprice", {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify(updatePaymentModel),
    });

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const GetSubscriptionSchedule = createAsyncThunk<GetSubscriptionScheduleResponse, string, { rejectValue: RejectedValue }>(
  "StripeSlice/GetSubscriptionSchedule",
  async (subscriptionScheduleId: string, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "schedule/get?subscriptionScheduleId=" + subscriptionScheduleId,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const responseModel = (await response.json()) as GetSubscriptionScheduleResponse;
      return responseModel;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status, });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const GetSubscriptionSchedules = createAsyncThunk<GetSubscriptionSchedulesResponse, GetSubscriptionSchedulesRequest, { rejectValue: RejectedValue }>(
  "StripeSlice/GetSubscriptionSchedules",
  async (subscriptionScheduleIds: GetSubscriptionSchedulesRequest, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Authorization", "Bearer " + accessToken);
    requestHeaders.set("Content-Type", "application/json");

    let bodyData = JSON.stringify(subscriptionScheduleIds);
    const response = await fetch(apiAddress + "schedule/getall",
      {
        method: "POST",
        headers: requestHeaders,
        body: bodyData
      }
    );

    if (response.status === 200) {
      const responseModel = await response.json() as GetSubscriptionSchedulesResponse;
      return responseModel;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

export const RemoveSubscriptionSchedule = createAsyncThunk<string, string, { rejectValue: RejectedValue }>(
  "StripeSlice/RemoveSubscriptionSchedule",
  async (subscriptionScheduleId: string, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "schedule/remove?subscriptionScheduleId=" + subscriptionScheduleId,
      {
        method: "DELETE",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status });
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
    }
  }
);

const initialProductsValue: GetAllValidAndActiveProductsResponse = {
  products: [],
};
const initialFamilyPricesValue: GetPricesForProductFamilyResponse = {
  prices: [],
};
const initialSusbcriptionToUpdateIntervalResponse: SubcriptionToUpdateIntervalResponse =
{
  subscription: {
    subscriptionId: "",
    subscriptionItemId: "",
    subscriptionScheduleId: "",
    startDate: "",
    currentPeriodEnd: "",
    licenseQuantity: 0,
    product: {
      id: "",
      name: "",
      images: [],
      description: "",
      productFamily: "",
      tier: 0,
    },
    price: {
      id: "",
      currencyOptions: [],
      productId: "",
      interval: "",
      intervalCount: 0,
    },
    userCurrency: "",
  },
  availablePriceUpdates: [],
};

const initialSusbcriptionToUpdateTierResponse: SubcriptionToUpdateTierResponse =
{
  subscription: {
    subscriptionId: "",
    subscriptionItemId: "",
    subscriptionScheduleId: "",
    startDate: "",
    currentPeriodEnd: "",
    licenseQuantity: 0,
    product: {
      id: "",
      name: "",
      images: [],
      description: "",
      productFamily: "",
      tier: 0,
    },
    price: {
      id: "",
      currencyOptions: [],
      productId: "",
      interval: "",
      intervalCount: 0,
    },
    userCurrency: "",
  },
  availableProductUpdates: [],
  availablePriceUpdates: [],
};
const initialCustomerSubscriptions: CustomerSubscriptionsResponse = {
  subscriptions: [],
};

const initialGetSubscriptionSchedules: GetSubscriptionSchedulesResponse = {
  subscriptionScheduleResponses: []
};

const initStatusValues: StatusValue = {
  statusCode: "",
  isLoading: false,
  error: "",
};

const initialStripeData: StripeData = {
  productValue: initialProductsValue,
  currentFamilyPrices: initialFamilyPricesValue,
  sessionUrl: "",
  customerSubscriptions: initialCustomerSubscriptions,
  susbcriptionToUpdateIntervalResponse: initialSusbcriptionToUpdateIntervalResponse,
  susbcriptionToUpdateTierResponse: initialSusbcriptionToUpdateTierResponse,
  subscriptionSchedules: initialGetSubscriptionSchedules,
};

const initialUpdateValues: UpdateValues = {
  updateSubscriptions: false,
  updateSchedules: false
}

const initialStripeSliceData: StripeSliceData = {
  stripeData: initialStripeData,
  statusValues: initStatusValues,
  updateValues: initialUpdateValues
};

export const StripeSlice = createSlice({
  name: "StripeSlice",
  initialState: { stripeSliceData: initialStripeSliceData },
  reducers: {
    resetSessionUrl: (state) => {
      state.stripeSliceData.stripeData.sessionUrl = "";
    },
    resetStripeSlice: (state) => {
      state.stripeSliceData.stripeData.productValue = initialProductsValue;
      state.stripeSliceData.stripeData.currentFamilyPrices = initialFamilyPricesValue;
      state.stripeSliceData.statusValues.isLoading = false;
      state.stripeSliceData.statusValues.error = "";
      state.stripeSliceData.statusValues.statusCode = "";
      state.stripeSliceData.stripeData.sessionUrl = "";
      state.stripeSliceData.stripeData.customerSubscriptions = initialCustomerSubscriptions;
      state.stripeSliceData.stripeData.susbcriptionToUpdateIntervalResponse = initialSusbcriptionToUpdateIntervalResponse;
      state.stripeSliceData.stripeData.susbcriptionToUpdateTierResponse = initialSusbcriptionToUpdateTierResponse;
      state.stripeSliceData.stripeData.subscriptionSchedules = initialGetSubscriptionSchedules;
    },
    updateSubscriptionStatus: (state, action: PayloadAction<boolean>) => {
      state.stripeSliceData.updateValues.updateSubscriptions = action.payload;
    },
    updateSchedulesStatus: (state, action: PayloadAction<boolean>) => {
      state.stripeSliceData.updateValues.updateSchedules = action.payload;
    },
    resetSchedules: (state) => {
      state.stripeSliceData.stripeData.subscriptionSchedules = initialGetSubscriptionSchedules
    },
    resetStatusCode: (state) => {
      state.stripeSliceData.stripeData.sessionUrl = "";
    },
  },
  extraReducers(builder) {
    //GetAllValidAndActiveProducts
    builder
      .addCase(GetAllValidAndActiveProducts.fulfilled, (state, action) => {
        state.stripeSliceData.stripeData.productValue = handleFulfilled(state.stripeSliceData.statusValues, action.payload);
      })
      .addCase(GetAllValidAndActiveProducts.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(GetAllValidAndActiveProducts.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      //GetPricesForProductFamily
      .addCase(GetPricesForProductFamily.fulfilled, (state, action) => {
        state.stripeSliceData.stripeData.currentFamilyPrices = handleFulfilled(state.stripeSliceData.statusValues, action.payload);
      })
      .addCase(GetPricesForProductFamily.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(GetPricesForProductFamily.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      //PostCreateCheckoutSession
      .addCase(PostCreateCheckoutSession.fulfilled, (state, action) => {
        state.stripeSliceData.stripeData.sessionUrl = handleFulfilled(state.stripeSliceData.statusValues, action.payload.sessionUrl);
      })
      .addCase(PostCreateCheckoutSession.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(PostCreateCheckoutSession.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      //PostCreateCustomerPortalSession
      .addCase(PostCreateCustomerPortalSession.fulfilled, (state, action) => {
        state.stripeSliceData.stripeData.sessionUrl = handleFulfilled(state.stripeSliceData.statusValues, action.payload.sessionUrl);
      })
      .addCase(PostCreateCustomerPortalSession.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(PostCreateCustomerPortalSession.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      //GetCustomerSubscriptions
      .addCase(GetCustomerSubscriptions.fulfilled, (state, action) => {
        state.stripeSliceData.stripeData.customerSubscriptions =
          handleFulfilled(state.stripeSliceData.statusValues, action.payload);
      })
      .addCase(GetCustomerSubscriptions.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(GetCustomerSubscriptions.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      //GetSusbcriptionToUpdateInterval
      .addCase(GetSusbcriptionToUpdateInterval.fulfilled, (state, action) => {
        state.stripeSliceData.stripeData.susbcriptionToUpdateIntervalResponse = handleFulfilled(state.stripeSliceData.statusValues, action.payload);
      })
      .addCase(GetSusbcriptionToUpdateInterval.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(GetSusbcriptionToUpdateInterval.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      //PostUpdatePaymentInterval
      .addCase(PostUpdatePaymentInterval.fulfilled, (state, action) => {
        state.stripeSliceData.statusValues.statusCode = handleFulfilled(state.stripeSliceData.statusValues, action.payload);
        state.stripeSliceData.updateValues.updateSubscriptions = true;
      })
      .addCase(PostUpdatePaymentInterval.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(PostUpdatePaymentInterval.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      //GetSusbcriptionToUpdateTier
      .addCase(GetSusbcriptionToUpdateTier.fulfilled, (state, action) => {
        state.stripeSliceData.stripeData.susbcriptionToUpdateTierResponse = handleFulfilled(state.stripeSliceData.statusValues, action.payload);
      })
      .addCase(GetSusbcriptionToUpdateTier.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(GetSusbcriptionToUpdateTier.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      //PostUpdateSubscriptionTier
      .addCase(PostUpdateSubscriptionTier.fulfilled, (state, action) => {
        state.stripeSliceData.statusValues.statusCode = handleFulfilled(state.stripeSliceData.statusValues, action.payload);
      })
      .addCase(PostUpdateSubscriptionTier.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(PostUpdateSubscriptionTier.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      // //GetSubscriptionSchedules
      // .addCase(GetSubscriptionSchedules.fulfilled, (state, action) => {
      //   state.stripeSliceData.stripeData.SubscriptionSchedule = handleFulfilled(
      //     state.stripeSliceData.statusValues,
      //     action.payload
      //   );
      // })
      // .addCase(GetSubscriptionSchedules.pending, (state, action) => {
      //   handlePending(state.stripeSliceData.statusValues);
      // })
      // .addCase(GetSubscriptionSchedules.rejected, (state, action) => {
      //   handleRejected(
      //     state.stripeSliceData.statusValues,
      //     action.payload
      //       ? action.payload
      //       : { message: "Error in application", status: -1 }
      //   );
      // })

      //GetSubscriptionSchedules
      .addCase(GetSubscriptionSchedules.fulfilled, (state, action) => {
        state.stripeSliceData.stripeData.subscriptionSchedules = handleFulfilled(state.stripeSliceData.statusValues, action.payload);
      })
      .addCase(GetSubscriptionSchedules.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(GetSubscriptionSchedules.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      //RemoveSubscriptionSchedule
      .addCase(RemoveSubscriptionSchedule.fulfilled, (state, action) => {
        state.stripeSliceData.statusValues.statusCode = handleFulfilled(state.stripeSliceData.statusValues, action.payload);
        state.stripeSliceData.updateValues.updateSchedules = true;
      })
      .addCase(RemoveSubscriptionSchedule.pending, (state, action) => {
        handlePending(state.stripeSliceData.statusValues);
      })
      .addCase(RemoveSubscriptionSchedule.rejected, (state, action) => {
        handleRejected(state.stripeSliceData.statusValues, action.payload ? action.payload : { message: "Error in application", status: -1 });
      })
      ;
  },
});

export default StripeSlice.reducer;
export const { resetSessionUrl, resetStripeSlice, resetStatusCode, updateSubscriptionStatus, updateSchedulesStatus, resetSchedules } =
  StripeSlice.actions;
