import { Button, Dialog, Typography } from "@material-tailwind/react";
import { CompareUserData, ResponsiveWidthProfile } from "../../../app/common";
import NoImage from "../../../Images/NoImage.png"
import { useEffect, useRef, useState } from "react";
import AccountFormComponent from "./accountFormComponent";
import { AccountSliceData, CreateUserDataRequest, GetUserResponse } from "../accountModel";
import SuccessCard from "../../CommonPages/successCard";
import { useTranslation } from "react-i18next";
import { PostEditUserData, resetStatusCode, setLoggedUser } from "../accountSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { LoadingPage } from "../../CommonPages/loadingPage";
import { postForgotPassword } from "../../Authentification/authentificationSlice";
import { InitPasswordReset } from "../../Authentification/authModel";

export function ProfileInfo({ accountData, statusValues }: AccountSliceData) {
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [showEditProfile, setShowEditProfile] = useState(false)
    const dispatch = useDispatch<AppDispatch>();
    const [messsage, setMesssage] = useState("");
    const [success, setSuccess] = useState(false);
    const loggedUserDataRef = useRef<GetUserResponse>(accountData.loggedUserData);
    const userData = useRef<CreateUserDataRequest>();
    const [isUserChanged, setIsUserChanged] = useState(false);

    const onEditProfile = () => { setShowEditProfile(true) }

    const handleOpenDialog = () => setOpenDialog(!openDialog);

    const onChangePassword = () => {
        const initPasswordReset: InitPasswordReset = {
            username: accountData.loggedUserData.email
        }
        dispatch(postForgotPassword(initPasswordReset));
        setSuccess(true);
        setMesssage(t("RequestForPasswordResetWasSentCheckYourEmail"));
        handleOpenDialog();
    }

    const onSaveChanges = (newUserData: CreateUserDataRequest) => {
        setIsUserChanged(false);
        //Change data only if the user changed properties
        if (CompareUserData(accountData.loggedUserData, newUserData)) {
            setIsUserChanged(true);
            dispatch(PostEditUserData(newUserData));
            userData.current = newUserData;
        }
        else {
            setShowEditProfile(false);
        }
    }

    const setCurrentLoggedUserData = () => {
        if (loggedUserDataRef.current && userData.current) {
            const updatedUserData = {
                ...loggedUserDataRef.current,
                email: accountData.loggedUserData.email,
                firstName: userData.current.FirstName,
                lastName: userData.current.LastName,
                birthDate: userData.current.BirthDate,
                phoneNumber: userData.current.PhoneNumber,
                gender: userData.current.Gender,
                city: userData.current.City,
                street: userData.current.Street,
                houseNumber: userData.current.HouseNumber,
                postalCode: userData.current.PostalCode,
                country: userData.current.Country,
            };

            loggedUserDataRef.current = updatedUserData;
            dispatch(setLoggedUser(loggedUserDataRef.current));
        }
    }

    useEffect(() => {
        if (statusValues.statusCode === '200') {
            setSuccess(true);
            setMesssage(t("UserDataChanged"));
            handleOpenDialog();
            setCurrentLoggedUserData();
            setShowEditProfile(false);
            setIsUserChanged(true);
        }
        else if (statusValues.statusCode !== '') {
            setSuccess(false);
            setMesssage(statusValues.error ?? t("AnErrorOccurred"));
            handleOpenDialog();
        }
        dispatch(resetStatusCode());
    }, [statusValues])

    if (statusValues.isLoading) {
        return (LoadingPage(statusValues.isLoading))
    }

    let profilePicutre = NoImage

    return (
        <div className={"rounded-[5px] bg-#FFFFFF h-auto shadow-4s mt-[60px]" + ResponsiveWidthProfile}>
            <div className="grid grid-cols-1 xl:grid-cols-6 2xl:grid-cols-5 3xl:grid-cols-6 p-[20px] lg:px-[50px] mt-[50px]">
                {/* Picture: */}
                <div className="">
                    <Typography variant="lead" className="font-semibold text-sm xl:hidden">{t("WelcomeToYourProfile")}</Typography>
                    <div className="h-[1px] bg-gradient-to-r from-#2C2C2C to-#2C2C2C-10 my-1 xl:hidden"></div>
                    <div className="flex items-center justify-center">
                        <div className="h-[104px] w-[104px] flex items-center justify-center rounded-full bg-#D9D9D9 font-bold text-[28px]">
                            {loggedUserDataRef.current.firstName.charAt(0) + " " + loggedUserDataRef.current.lastName.charAt(0)}
                        </div>
                    </div>
                    {/* <img className="h-[104px] w-[104px] rounded-full object-cover object-center mx-auto xl:ml-0 my-3 xl:m-0" src={profilePicutre} alt="user" /> */}
                </div>
                {/* Left side: */}
                <div className="xl:col-span-4 2xl:col-span-2">
                    {showEditProfile ?
                        <AccountFormComponent isEdit={true} isUserChanged={isUserChanged} buttonText={t("SaveChanges")} onClickFunction={onSaveChanges} userData={accountData.loggedUserData} currentUserData={userData.current} />
                        :
                        <div className="grid grid-cols-1 sm:grid-cols-2">
                            <Typography variant="lead" className="hidden xl:flex col-span-2 font-semibold">{t("WelcomeToYourProfile")}</Typography>
                            <div className="flex flex-col">
                                <Typography variant="paragraph" className="uppercase font-[400] text-base text-center xl:text-left break-words sm:mt-2">{accountData.loggedUserData.firstName + " " + accountData.loggedUserData.lastName}</Typography>
                                <Typography variant="paragraph" className="mt-2 font-[300] text-sm text-center xl:text-left break-words">{accountData.loggedUserData.email}</Typography>
                            </div>
                            <div className="flex flex-col place-content-center">
                                <Button id="ChangePassword" size="sm" onClick={onChangePassword} className="px-2 py-0 mt-2 mx-auto bg-white hover:bg-#FF6104-20 hover:cursor-pointer underline normal-case text-#333333 font-[300] text-[16px] hover:shadow-none shadow-none">{t("ChangePassword")}</Button>
                                <Button id="EditProfile" size="sm" className="px-2 py-0 mt-2 mx-auto flex w-fit items-center gap-1 bg-white hover:bg-#FF6104-20 hover:cursor-pointer normal-case text-#333333 font-[400] text-[16px] hover:shadow-none shadow-none" onClick={onEditProfile}>
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.53443 2.75281L1.48761 10.8196L1 12L2.18043 11.5124L10.2471 3.46545L9.53443 2.75281ZM11.1445 1.14301L10.7959 1.49135L11.5085 2.20399L11.8571 1.85536C11.9486 1.76381 12 1.63969 12 1.51027C12 1.38086 11.9486 1.25673 11.8571 1.16519L11.835 1.14301C11.7896 1.09767 11.7358 1.06171 11.6766 1.03717C11.6173 1.01263 11.5538 1 11.4897 1C11.4256 1 11.3621 1.01263 11.3029 1.03717C11.2437 1.06171 11.1898 1.09767 11.1445 1.14301Z" stroke="#333333" strokeWidth="1.18279" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {t("EditProfile")}</Button>
                            </div>
                        </div>
                    }
                </div>
                <div className="border-t-[1px] border-#D9D9D9 pt-2 mt-2 xl:border-t-0 xl:pt-0 xl:mt-0 xl:border-l-2 xl:pl-2 xl:ml-2 2xl:col-span-2 3xl:col-span-3">
                    {!showEditProfile ?
                        <div className="items-center grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-1 gap-1 place-items-center xl:place-items-start xl:pl-3 2xl:pl-20 3xl:grid-cols-4 3xl:h-full">
                            <Typography id="Membership" variant="paragraph" className="w-fit h-fit my-auto"><a className="p-2 uppercase font-semibold xl:text-[16px] text-[14px] cursor-pointer hover:bg-#FF6104-20 rounded-[5px]" href="#membership">{t("Membership")}</a></Typography>
                            <Typography id="Directory" variant="paragraph" className="w-fit h-fit my-auto"><a className="p-2 uppercase font-semibold xl:text-[16px] text-[14px] cursor-pointer hover:bg-#FF6104-20 rounded-[5px]" href="#directory">{t("Directory")}</a></Typography>
                            <Typography id="Subscriptions" variant="paragraph" className="w-fit h-fit my-auto"><a className="p-2 uppercase font-semibold xl:text-[16px] text-[14px] cursor-pointer hover:bg-#FF6104-20 rounded-[5px]" href="#subscriptions">{t("Subscriptions")}</a></Typography>
                            <Typography id="Licenses" variant="paragraph" className="w-fit h-fit my-auto"><a className="p-2 uppercase font-semibold xl:text-[16px] text-[14px] cursor-pointer hover:bg-#FF6104-20 rounded-[5px]" href="#licenses">{t("Licenses")}</a></Typography>
                        </div> : <></>}
                </div>
            </div>
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
            </Dialog>
        </div>
    )
}