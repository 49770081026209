import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { getMicrosoftAuth } from '../Authentification/authentificationSlice';
import { Typography } from '@material-tailwind/react';
import { useTranslation } from "react-i18next";
import SignInButton from './signInSSOButton';

const AuthComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const login = async () => {
        dispatch(getMicrosoftAuth())
    };

    return (
        <div>
            {/* <Typography className="mt-6 flex justify-center items-center gap-[10px] text-#FF6104 text-base 2xl:text-lg uppercase font-[500] cursor-pointer" onClick={login}>
                <svg className='w-4 h-4 2xl:w-5 2xl:h-5' viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.70296 5.70296H0V0H5.70296V5.70296Z" fill="#F1511B" />
                    <path d="M11.9998 5.70296H6.29688V0H11.9998V5.70296Z" fill="#80CC28" />
                    <path d="M5.70282 11.9998H0V6.29688H5.70282V11.9998Z" fill="#00ADEF" />
                    <path d="M11.9998 11.9998H6.29688V6.29688H11.9998V11.9998Z" fill="#FBBC09" />
                </svg>
                {t("ContinueWithMicrosoft")}
            </Typography> */}
            <SignInButton />
        </div>
    );
};

export default AuthComponent;
