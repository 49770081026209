import { AppRoutes } from "./app/routes";
import { Header } from "./features/HeaderAndFooter/header";
import { Footer } from "./features/HeaderAndFooter/footer";
import ReactGA from "react-ga4";
import { useEffect } from "react";

function App() {

  useEffect(() => {
    const googleMeasurementId = process.env.GA_MEASUREMENT_ID;
    if (googleMeasurementId !== undefined)
      ReactGA.initialize(googleMeasurementId);
  }, [])


  return (
    <div className="bg-#FEFEFE min-h-[100vh] flex flex-col">
      <Header />
      <AppRoutes />
      <Footer />
    </div>
  );
}

export default App;
