import { Input, Select, Option, Button, Typography, Dialog } from "@material-tailwind/react";
import { AppRoles, ResponsiveWidthProfile } from "../../../app/common"
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { GetDirectoryMembers, PostAddMembership, PostRemoveMembership, PostSetMembershipRole, resetStatusCode } from "../directorySlice";

import { AddMembershipModel, DirectorySliceData, GetHomeDirectoryResponse, RemoveMembershipModel, SetMembershipRoleModel } from "../directoryModel";
import { useTranslation } from "react-i18next";
import SuccessCard from "../../CommonPages/successCard";
import { ValidateEmail } from "../../../app/validation";
import InfoComponent from "../../../UIComponents/Info/infoComponent";

const RoleUpdate = [AppRoles['Admin'], AppRoles['Member']]

interface DirectoryDropdownModel {
    directoryId: string,
    directoryEmail: string
}

export function Membership({ directoryData, statusValues }: DirectorySliceData) {

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [counter, setCounter] = useState(0);

    const [directoryForAddMembership, setDirectoryForAddMembership] = useState<DirectoryDropdownModel[]>();
    const [availableUsersForSetRole, setAvailableUsersForSetRole] = useState<string[]>();
    const [availableUsersForRemove, setAvailableUsersForRemove] = useState<string[]>();
    //Add:
    const emailForAdd = createRef<HTMLInputElement>()
    const directoryForAddRef = useRef('');
    const [directoryForAdd, setDirectoryForAdd] = useState('');
    //Set:
    const [directoryForSet, setDirectoryForSet] = useState('');
    const [userForSet, setUserForSet] = useState('');
    const [roleForSet, setRoleForSet] = useState('');
    const directoryForSetRoleRef = useRef('');
    const userForSetRoleRef = useRef('');
    const roleForSetRoleRef = useRef('');
    //Remove
    const [userForRemove, setUserForRemove] = useState('');
    const [directoryForRemove, setDirectoryForRemove] = useState('');
    const userForRemoveRef = useRef('');
    const directoryForRemoveRef = useRef('');
    //Dialog:
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogText, setDialogText] = useState('');
    const [dialogSuccess, setDialogSuccess] = useState(false);
    //Error:
    const [mailErrorStatus, setMailErrorStatus] = useState(false);
    const [addMembershipDirectoryError, setAddMembershipDirectoryError] = useState(false);
    const [roleDirectoryError, setRoleDirectoryError] = useState(false);
    const [roleUserError, setRoleUserError] = useState(false);
    const [roleRoleError, setRoleRoleError] = useState(false);
    const [removeUserError, setRemoveUserError] = useState(false);
    const [removeDirectoryError, setRemoveDirectoryError] = useState(false);

    const handleOpenDialog = (success: boolean) => {
        success === true ? setDialogText(successText) : setDialogText(unsuccessText)
        setDialogSuccess(success)
        setOpenDialog(!openDialog);
    }

    const successText = t('SuccessText')
    const unsuccessText = t('UnsuccessText')

    const setEmailForAddOnChange = () => {
        setMailErrorStatus(false);
    }

    const setDirectoryForAddOnChange = (directory: any) => {
        setDirectoryForAdd(directory);
        setAddMembershipDirectoryError(false);
    }

    const onAddClick = () => {
        if (emailForAdd.current?.value && ValidateEmail(emailForAdd.current?.value) && directoryForAdd) {

            setAllValues();
            let memberData: AddMembershipModel = {
                email: emailForAdd.current.value,
                DirectoryId: directoryForAdd
            }
            setMailErrorStatus(false);
            setAddMembershipDirectoryError(false);

            dispatch(PostAddMembership(memberData));
        }
        if (!ValidateEmail(emailForAdd.current?.value) || emailForAdd.current === undefined || emailForAdd.current === null) {
            setMailErrorStatus(true);
        }
        if (directoryForAdd === '') {
            setAddMembershipDirectoryError(true);
        }
    }

    const setDirectoryForSetOnChange = (directory: any) => {
        setDirectoryForSet(directory);
        setRoleDirectoryError(false);
        let directoryResponse: GetHomeDirectoryResponse = { directoryId: directory };
        dispatch(GetDirectoryMembers(directoryResponse));
    }

    const setUserForSetOnChange = (user: any) => {
        setUserForSet(user);
        setRoleUserError(false);
    }

    const setRoleForSetOnChange = (role: any) => {
        setRoleForSet(role);
        setRoleRoleError(false);
    }

    const onSetClick = () => {
        if (directoryForSet && userForSet && roleForSet) {
            setAllValues();
            let userToSet = directoryData.directoryMembersData.find(user => user.email === userForSet)
            let memberData: SetMembershipRoleModel = {
                directoryId: directoryForSet,
                userId: userToSet ? userToSet.userId : '',
                RoleType: roleForSet
            }
            setRoleDirectoryError(false);
            setRoleUserError(false);
            setRoleRoleError(false);

            dispatch(PostSetMembershipRole(memberData))
        }
        if (directoryForSet === '') {
            setRoleDirectoryError(true);
        }
        if (userForSet === '') {
            setRoleUserError(true);
        }
        if (roleForSet === '') {
            setRoleRoleError(true);
        }
    }

    const setDirectoryForRemoveOnChange = (directory: any) => {
        setDirectoryForRemove(directory);
        setRemoveDirectoryError(false);
        let directoryResponse: GetHomeDirectoryResponse = { directoryId: directory };
        dispatch(GetDirectoryMembers(directoryResponse));
    }

    const setUserForRemoveOnChange = (user: any) => {
        setUserForRemove(user);
        setRemoveUserError(false);
    }

    const onRemoveClick = () => {
        if (userForRemove && directoryForRemove) {
            setAllValues();
            let userToRemove = directoryData.directoryMembersData.find(user => user.email === userForRemove)
            let memberData: RemoveMembershipModel = {
                userId: userToRemove ? userToRemove.userId : '',
                DirectoryId: directoryForRemove
            }
            setRemoveUserError(false);
            setRemoveDirectoryError(false);

            dispatch(PostRemoveMembership(memberData));
        }

        if (userForRemove === '') {
            setRemoveUserError(true);
        }
        if (directoryForRemove === '') {
            setRemoveDirectoryError(true);
        }
    }

    function setAllValues() {
        if (emailForAdd.current)
            emailForAdd.current.value = emailForAdd.current.value;
        directoryForAddRef.current = directoryForAdd;
        directoryForSetRoleRef.current = directoryForSet;
        userForSetRoleRef.current = userForSet;
        roleForSetRoleRef.current = roleForSet;
        userForRemoveRef.current = userForRemove;
        directoryForRemoveRef.current = directoryForRemove;
    }

    function resetAllValues() {
        setMailErrorStatus(false);
        setAddMembershipDirectoryError(false);
        setRoleDirectoryError(false);
        setRoleUserError(false);
        setRoleRoleError(false);
        setRemoveUserError(false);
        setRemoveDirectoryError(false);
        setDirectoryForAdd('');
        setDirectoryForSet('');
        setUserForSet('');
        setRoleForSet('');
        setUserForRemove('');
        setDirectoryForRemove('');
        if (emailForAdd.current)
            emailForAdd.current.value = '';
        directoryForAddRef.current = '';
        directoryForSetRoleRef.current = '';
        userForSetRoleRef.current = '';
        roleForSetRoleRef.current = '';
        userForRemoveRef.current = '';
        directoryForRemoveRef.current = '';
        setAvailableUsersForSetRole([]);
        setAvailableUsersForRemove([]);
    }

    useEffect(() => {
        let usersToSelect: string[] = [];

        directoryData.directoryMembersData.forEach(member => {
            if (member.directoryId === directoryForSet) {
                usersToSelect.push(member.email);
            }
        })
        if (usersToSelect.length > 0) {
            setAvailableUsersForSetRole(usersToSelect);
        }

        usersToSelect = [];

        directoryData.directoryMembersData.forEach(member => {
            if (member.directoryId === directoryForRemove) {
                usersToSelect.push(member.email);
            }
        })
        if (usersToSelect.length > 0) {
            setAvailableUsersForRemove(usersToSelect);
        }
    }, [directoryData.directoryMembersData])

    useEffect(() => {
        let directoriesForAddMembership: DirectoryDropdownModel[] = [];

        directoryData.directoryData.forEach(dir => {
            if (dir.role !== AppRoles.Member) {
                directoriesForAddMembership.push({ directoryId: dir.directoryId, directoryEmail: dir.directoryMail });
            }
        })
        //Add Membership:
        setDirectoryForAddMembership(directoriesForAddMembership)

    }, [directoryData.directoryData])

    useEffect(() => {
        if (statusValues.statusCode === '') {
            return
        }
        if (statusValues.statusCode === '200' || statusValues.statusCode === '201') {
            dispatch(resetStatusCode())
            handleOpenDialog(true)
        }
        else {
            dispatch(resetStatusCode())
            handleOpenDialog(false)
        }
        resetAllValues();
    }, [statusValues])

    return (
        <div id="membership" className={"lg:scroll-mt-[50px] xl:scroll-mt-[70px] 2xl:scroll-mt-[90px] rounded-b-[5px] bg-#FFFFFF h-auto space-y-5 shadow-4s pb-10 " + ResponsiveWidthProfile}>
            <div className="2xl:h-[30px] bg-#FF6104-20 rounded-t-[5px]">
                <Typography variant="lead" className="uppercase font-semibold px-10">
                    {t('Membership')}
                </Typography>
            </div>
            {/* Inside Membership*/}
            <div className="2xl:space-y-5 xl:space-y-2.5 sm:flex-col gap-y-10 px-10">
                <div className="flex flex-col gap-3">
                    <div className="flex flex-row items-center gap-2">
                        <Typography variant="paragraph" className="uppercase font-semibold">{t('AddMembership')}</Typography>
                        <InfoComponent text={"AddMembershipInfo"} />
                    </div>

                    {/* Inside Add Membership:*/}
                    <div>
                        <div className="flex xl:flex-row gap-5 flex-col">
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Select id="SelectDirectoryMembership" color="orange" label={t("SelectDirectory")} error={addMembershipDirectoryError} onChange={(choice) => setDirectoryForAddOnChange(choice)} value={directoryForAddRef.current} defaultValue={""}
                                    className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded">
                                    {directoryForAddMembership ? directoryForAddMembership.map((directory, index) =>
                                        <Option id={index.toString()} key={directory.directoryId + index} value={directory.directoryId}>{directory.directoryEmail}</Option>
                                    ) : <></>}
                                </Select>
                            </div>
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Input id="email" type="email" color="orange" inputRef={emailForAdd} label={t("Email")} error={mailErrorStatus} onChange={setEmailForAddOnChange} defaultValue={""}
                                    className="xl:w-[280px]  2xl:w-[320px] 2xl:h-[40px] rounded">
                                </Input>
                            </div>
                            <div className="">
                                <Button id="addMemberButton" onClick={onAddClick} color="deep-orange" className="bg-#FF8136 shadow-none float-right hover:bg-#FF6104 hover:shadow-none">{t('Add')}</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-3">
                    <div className="flex flex-row items-center gap-2">
                        <Typography variant="paragraph" className="uppercase font-semibold">{t('SetMembershipRole')}</Typography>
                        <InfoComponent text={"SetMembershipRoleInfo"} />
                    </div>
                    {/* Inside Set Membership Role:*/}
                    <div className="flex flex-col 2xl:flex-row gap-5">
                        <div className="flex xl:flex-row gap-5 flex-col">
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Select id="SelectDirectoryForSetMembershipRole" color="orange" label={t("SelectDirectory")} error={roleDirectoryError} onChange={(choice) => setDirectoryForSetOnChange(choice)} value={directoryForSetRoleRef.current} defaultValue={""}
                                    className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded">
                                    {directoryForAddMembership ? directoryForAddMembership.map((directory, index) =>
                                        <Option id={index.toString()} key={directory.directoryId + index} value={directory.directoryId}>{directory.directoryEmail}</Option>
                                    ) : <></>}
                                </Select>
                            </div>
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Select id="SelectUserForSetMembershipRole" color="orange" label={t("SelectUser")} error={roleUserError} disabled={directoryForSet === ""} onChange={(choice) => setUserForSetOnChange(choice)} value={userForSetRoleRef.current} defaultValue={""}
                                    className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded">
                                    {availableUsersForSetRole ? availableUsersForSetRole.map((directory, index) =>
                                        <Option id={index.toString()} key={directory + index} value={directory}>{directory}</Option>
                                    ) : <></>}
                                </Select>
                            </div>
                        </div>
                        <div className="flex xl:flex-row gap-5 flex-col">
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Select id="SelectRoleForSetMembershipRole" color="orange" label={t("SelectRole")} error={roleRoleError} onChange={(choice) => setRoleForSetOnChange(choice)} value={roleForSetRoleRef.current} defaultValue={""}
                                    className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded">
                                    {RoleUpdate.map((role, index) =>
                                        <Option id={index.toString()} key={role + index} value={role}>{role}</Option>
                                    )}
                                </Select>
                            </div>
                            <div>
                                <Button id="SetButtonMembership" onClick={onSetClick} color="deep-orange" className="bg-#FF8136 shadow-none float-right hover:bg-#FF6104 hover:shadow-none">{t('Set')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-3">
                    <Typography variant="paragraph" className="uppercase font-semibold">{t('RemoveMembership')}</Typography>
                    {/* Inside Remove Membership:*/}
                    <div>
                        <div className="flex xl:flex-row gap-5 flex-col">
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Select id="SelectDirectoryForRemoveMembership" color="orange" label={t("SelectDirectory")} error={removeDirectoryError} onChange={(choice) => setDirectoryForRemoveOnChange(choice)} value={directoryForRemoveRef.current} defaultValue={""}
                                    className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded">
                                    {directoryForAddMembership ? directoryForAddMembership.map((directory, index) =>
                                        <Option id={index.toString()} key={directory.directoryId + index} value={directory.directoryId}>{directory.directoryEmail}</Option>
                                    ) : <></>}
                                </Select>
                            </div>
                            <div className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px]">
                                <Select id="SelectUserForRemoveMembership" color="orange" label={t("SelectUser")} error={removeUserError} disabled={directoryForRemove === ""} onChange={(choice) => setUserForRemoveOnChange(choice)} value={userForRemoveRef.current} defaultValue={""}
                                    className="xl:w-[280px] 2xl:w-[320px] 2xl:h-[40px] rounded">
                                    {availableUsersForRemove ? availableUsersForRemove.map((directory, index) =>
                                        <Option id={index.toString()} key={directory + index} value={directory}>{directory}</Option>
                                    ) : <></>}
                                </Select>
                            </div>
                            <div>
                                <Button id="RemoveButtonMembership" onClick={onRemoveClick} color="red" className="bg-#EA4247 shadow-none float-right hover:bg-#D12026 hover:shadow-none">{t('Remove')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}>
                <SuccessCard onClickFunction={() => handleOpenDialog(dialogSuccess)} text={dialogText} success={dialogSuccess} />
            </Dialog>

        </div>
    )
}