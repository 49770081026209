import { Button, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import calculationToolsLogo from "../../Images/calculationToolsLogo.png";
import tryNowBg from "../../Images/TryNowBg.png";
import { textShadowStyle } from "../../app/common";

function TryNowComponent() {
    const { t } = useTranslation();

    const onTryNowClick = () => {
        window.open("https://calctoolsheat.bimagine.com/", '_blank');
    };

    return (
        <div className="mx-auto relative h-[150px] xl:h-[90px] w-full shadow-4s">
            <img src={tryNowBg} alt="TryNowBg" className="object-cover h-full w-full rounded-[5px]" />
            <div className="absolute flex flex-row xl:flex-row justify-center xl:justify-between top-0 bottom-0 left-0 right-0">
                <div className="flex flex-row w-full items-center justify-center gap-5 mx-[30px]">
                    <img src={calculationToolsLogo} alt="calculationToolsLogo" className="h-[60px] w-[60px] xl:h-[50px] xl:w-[50px]" />
                    <div className="flex flex-col xl:flex-row items-center w-fit justify-center gap-2 xl:gap-10">
                        <Typography className="uppercase text-#333333 font-bold text-[16px] xl:text-[20px] 2xl:text-[24px] leading-[16px] xl:leading-[20px] 2xl:leading-[24px]" style={textShadowStyle}>
                            {"Calculation Tools"}
                        </Typography>
                        <hr className="bg-#282828 w-[1px] hidden xl:block xl:py-[30px]" />
                        <Typography className="uppercase text-#282828 font-medium text-[12px] xl:text-[16px] 2xl:text-[18px] leading-[12px] xl:leading-[16px] 2xl:leading-[18px]">
                            {t("CalculationToolsTryNowDescription")}
                        </Typography>
                        <Button id="tryNowBtn" onClick={onTryNowClick} className="bg-#333333 shadow-none hover:bg-#4E4E4E hover:shadow-none min-w-[130px] h-[30px] xl:h-[40px] text-[12px] xl:text-[14px] leading-[12px] xl:leading-[14px] font-medium rounded-[5px] py-0">
                            {t("TryNow")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TryNowComponent;
