import { Typography } from "@material-tailwind/react";
import FAQComponent from "./FAQComponent";
import { useTranslation } from "react-i18next";

function CalculationToolsFAQsComponent() {
    const { t } = useTranslation();
    const faqs = [
        {
            id: "1",
            question: t("WhatIsCalculationToolsSoftware"),
            answer: t("WhatIsCalculationToolsSoftwareAnswer"),
        },
        {
            id: "2",
            question: t("WhoCanBenefitFromUsingCalculationTools"),
            answer: t("WhoCanBenefitFromUsingCalculationToolAnswer"),
        },
        {
            id: "3",
            question: t("HowDoesCalculationToolsEnsureAccurateHeatLoadCalculations"),
            answer: t("HowDoesCalculationToolsEnsureAccurateHeatLoadCalculationsAnswer"),
        },
        {
            id: "4",
            question: t("IsCalculationToolsCompatibleWithDifferentBuildingTypes"),
            answer: t("IsCalculationToolsCompatibleWithDifferentBuildingTypesAnswer"),
        },
        {
            id: "5",
            question: t("CanIModifyParametersOnceAProjectIsStarted"),
            answer: t("CanIModifyParametersOnceAProjectIsStartedAnswer"),
        },
        {
            id: "6",
            question: t("WhatStandardsDoesCalculationToolsAdhereTo"),
            answer: t("WhatStandardsDoesCalculationToolsAdhereToAnswer"),
        },
        {
            id: "7",
            question: t("HowDoesCalculationToolsContributeToEnergyEfficiencyInSeatingSystems"),
            answer: t("HowDoesCalculationToolsContributeToEnergyEfficiencyInSeatingSystemsAnswer"),
        },
        {
            id: "8",
            question: t("WhatKindOfSupportAndUpdatesCanUsersExpect"),
            answer: t("WhatKindOfSupportAndUpdatesCanUsersExpectAnswer"),
        },
    ];

    return (
        <>
            <div>
                <Typography className="uppercase text-lg 2xl:text-3xl font-bold tracking-[0.9px] text-center my-[30px] lg:my-[60px]">
                    {t("FAQ")}
                </Typography>
                <div className="mx-[20px] lg:mx-[100px] mt-[30px] lg:mt-[40px]">
                    {faqs.map((faq) => (
                        <FAQComponent key={faq.id} faq={faq} />
                    ))}
                </div>
            </div>
        </>
    );
}

export default CalculationToolsFAQsComponent;
