import { useEffect, useState } from "react";
import { ValidateEmail } from "../../../app/validation";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { postInitActivateAccount } from "../authentificationSlice";
import ResendActivationCard from "./resendActivationCard";
import { Alert, Dialog, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { LoadingPage } from "../../CommonPages/loadingPage";
import SuccessCard from "../../CommonPages/successCard";

export function InitActivateAccount() {
    const [errorMesssage, seterrorMesssage] = useState('');
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");
    const dispatch = useDispatch<AppDispatch>();
    const authData = useSelector((state: RootState) => state.AuthentificationSlice);
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const [success, setSuccess] = useState(false);
    const [messsage, setMesssage] = useState('');

    const handleOpenDialog = () => setOpenDialog(!openDialog);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        submitFunction(email);
    };

    const submitFunction = (email: string) => {
        if (ValidateEmail(email)) {
            dispatch(postInitActivateAccount(email));
        }
        else {
            seterrorMesssage(t("YouHaveEnteredAnInvalidEmailAddress"));
            setOpen(true);
        }
    }

    useEffect(() => {
        if (authData.statusCode === '200' || authData.statusCode === 'OK') {
            setSuccess(true);
            setMesssage(t("RequestForAccountCreationWasResentCheckYourEmail"));
            handleOpenDialog();
        }
        else if (authData.statusCode !== '') {
            setSuccess(false);
            setMesssage(authData.error ?? t("AnErrorOccurred"));
            handleOpenDialog();
        }
    }, [authData.statusCode])

    if (authData.isLoading) {
        return (LoadingPage(authData.isLoading))
    }

    return (
        <div className="flex justify-center m-[5%]">
            <form className="space-y-5" onSubmit={handleSubmit}>
                {errorMesssage !== "" ?
                    <Alert color="red" open={open} onClose={() => setOpen(false)}>
                        <Typography className="text-[14px] flex-nowrap overflow-hidden text-ellipsis max-w-[320px] max-lg:w-[400px] max-2xl:w-[500px]">
                            {errorMesssage}
                        </Typography>
                    </Alert>
                    : <></>}
                <ResendActivationCard onClickFunction={submitFunction} setEmailForm={setEmail} />
                <input type="submit" style={{ display: "none" }} />
            </form>
            <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0.9, y: -100 } }}>
                <SuccessCard onClickFunction={handleOpenDialog} text={messsage} success={success} />
            </Dialog>
        </div>
    )
}