import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RejectedValue, StatusValue, handleFulfilled, handlePending, handleRejected } from "../../app/common";
import { ContactEmailModel } from "./ContactEmailModel";
import { apiAddress } from "../../app/apiModel";
import { ErrorResponse } from "../Authentification/authModel";

export const PostSendContactEmail = createAsyncThunk<string, ContactEmailModel, { rejectValue: RejectedValue }>(
    'ContactSlice/ContactUs',
    async (email: ContactEmailModel, { rejectWithValue }) => {
        const requestHeaders: HeadersInit = new Headers();
        const accessToken = localStorage.getItem('webShopBimTAccessToken');
        requestHeaders.set('Content-Type', 'application/json');
        requestHeaders.set('Authorization', 'Bearer ' + accessToken);

        const response = await fetch(apiAddress + 'contactUs', {
            method: 'POST',
            headers: requestHeaders,
            body: JSON.stringify(email)
        })
        if (response.status === 200) {
            return response.status.toString();
        }
        else if (response.status === 404 || response.status === 500) {
            return rejectWithValue({ message: response.statusText, status: response.status })
        }
        else {
            const errorResponse = await response.json() as ErrorResponse;
            return rejectWithValue({ message: errorResponse.detail, status: errorResponse.status });
        }
    }
);

const initStatusValues: StatusValue = {
    statusCode: "",
    isLoading: false,
    error: "",
}

const ContactEmailSlice = createSlice({
    name: 'ContactSlice',
    initialState: {
        statusValues: initStatusValues,
    },
    reducers: {
        resetStatusCode: (state) => {
            state.statusValues.statusCode = '';
          }
    },
    extraReducers(builder) {
        //SendContactEmail
        builder.addCase(PostSendContactEmail.fulfilled, (state, action) => {
            state.statusValues.statusCode = handleFulfilled(state.statusValues, action.payload);
        })
            .addCase(PostSendContactEmail.rejected, (state, action) => {
                handleRejected(state.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
            })
            .addCase(PostSendContactEmail.pending, (state) => {
                handlePending(state.statusValues);
            })
    },
})

export default ContactEmailSlice.reducer;
export const { resetStatusCode } = ContactEmailSlice.actions;