import { Typography } from "@material-tailwind/react";

interface FeatureProps {
  Icon: () => JSX.Element;
  title: string;
  text: string;
}

function FeatureComponent(props: { feature: FeatureProps }) {
  return (
    <>
      <div className="w-[270px] 2xl:w-[405px] h-fit flex flex-col items-center">
        <div className="bg-#F6F6F6 rounded-full w-[102px] h-[102px] 2xl:w-[153px] 2xl:h-[153px] flex items-center justify-center shadow-circle">
          <props.feature.Icon />
        </div>
        <Typography className="mt-[40px] text-#2C2C2C text-base 2xl:text-2xl font-semibold tracking-[0.8px] text-center">
          {props.feature.title}
        </Typography>
        <Typography className="mt-[20px] text-#2C2C2C text-sm 2xl:text-xl font-light tracking-[0.7px] text-center">
          {props.feature.text}
        </Typography>
      </div>
    </>
  );
}

export default FeatureComponent;
