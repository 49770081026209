import { configureStore, ThunkAction, Action, combineReducers, getDefaultMiddleware, Middleware } from '@reduxjs/toolkit';
import AuthentificationSlice from '../features/Authentification/authentificationSlice';
import AccountSlice from '../features/Accounts/accountSlice';
import DirectorySlice from '../features/Directories/directorySlice';
import StripeSlice from '../features/stripe/stripeSlice';
import LicensesSlice from '../features/Licenses/licensesSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import BlogSlice from '../features/Blogs/BlogSlice';
import ContactEmailSlice from '../features/ContactEmail/ContactEmailSlice';
import ProductSlice from '../features/Product/productSlice';
import { tokenExpirationMiddleware } from '../tokenExpirationMiddleware';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['LicensesSlice', 'DirectorySlice', 'AuthentificationSlice', 'BlogsSlice']
};

const stripePersistConfig = {
  key: 'stripe',
  storage,
  whitelist: ['stripeSlice.stripeData.productValue'],
  blacklist: ['']
}

const AccountPersistConfig = {
  key: 'account',
  storage,
  whitelist: ['loggedUserData', 'shouldCreateData', 'hasUserData']
}

const reducer = combineReducers({
  AuthentificationSlice: AuthentificationSlice,
  AccountSlice: persistReducer(AccountPersistConfig, AccountSlice),
  DirectorySlice: DirectorySlice,
  LicensesSlice: LicensesSlice,
  StripeSlice: StripeSlice,
  BlogsSlice: BlogSlice,
  ContactSlice: ContactEmailSlice,
  ProductSlice: ProductSlice
})

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware()

    const sliceMiddleware: Middleware = (middlewareAPI) => (next) => (action) => {
      if (action.type.endsWith('pending')) {
        tokenExpirationMiddleware(middlewareAPI)(next)(action);
      }
      else {
        return next(action);
      }
    };

    return defaultMiddleware.concat(sliceMiddleware);
  },
  devTools: false
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
