import { Button, Card, Typography, Dialog } from "@material-tailwind/react";
import { ConvertFullDateTimeToDate, ResponsiveWidthProfile, TableDataWidth, } from "../../../app/common";
import { SubscriptionProps, SubscriptionScheduleProps } from "../stripeModel";
import { LoadingPage } from "../../CommonPages/loadingPage";
import { AppDispatch } from "../../../app/store";
import { useDispatch } from "react-redux";
import { GetCustomerSubscriptions, GetSusbcriptionToUpdateInterval, PostCreateCustomerPortalSession, RemoveSubscriptionSchedule, resetSessionUrl, updateSchedulesStatus, updateSubscriptionStatus, } from "../stripeSlice";
import { useEffect, useState } from "react";
import { TableWithButton } from "../../../UIComponents/Tables/uiTableWithButton";
import { useTranslation } from "react-i18next";
import { SubscriptionUpdate } from "../../Licenses/licensesModel";
import { UpdateSubscription } from "./updateSubscription";

export function Subscription({ stripeData, statusValues, subscriptionSchedules, updateValues }: SubscriptionProps) {

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [subscritionData, SetSubscritionData] = useState<SubscriptionUpdate[]>();
  const [schedulesData, SetSchedulesData] = useState<SubscriptionScheduleProps[]>();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(!openDialog);

  const onOpenClick = () => {
    dispatch(PostCreateCustomerPortalSession());

  };

  useEffect(() => {
    if (stripeData.sessionUrl !== "") {
      dispatch(resetSessionUrl())
      window.open(stripeData.sessionUrl, "_blank", "noopener,noreferrer");
    }
  }, [stripeData.sessionUrl])

  const TABLE_HEAD_UPDATE_SUB_INTERVAL_TIER = [
    t("SubscriptionId"),
    t("ProductName"),
    t("CurrentPrice"),
    //t("AvailableProductUpdates"),
    // t("AvailablePriceUpdates"),
    t("StartDate"),
    t("CurrentPeriodEnd"),
    t("Update"),
  ];

  const TABLE_HEAD_SUB_UPDATE_OVERVIEW = [
    t("ScheduleId"),
    t("CurrentProduct"),
    t("CurrentTier"),
    t("CurrentPrice"),
    t("CurrentNumber"),
    t("ScheduledProduct"),
    t("ScheduledTier"),
    t("ScheduledPrice"),
    t("ScheduledNumber"),
    t("CreationDate"),
    t("ExecutionDate"),
    t("Remove"),
  ];

  let subscritions: SubscriptionUpdate[] = [];
  let schedules: SubscriptionScheduleProps[] = [];

  useEffect(() => {
    schedules = [];
    stripeData.subscriptionSchedules.subscriptionScheduleResponses.forEach((subSchedule) => {
      let price = subSchedule.currentPrice.currencyOptions.find(x => x.currency === subSchedule.currentPrice.currencyOptions[0].currency)
      let schedulePrice = subSchedule.scheduledPrice.currencyOptions.find(x => x.currency === subSchedule.scheduledPrice.currencyOptions[0].currency)

      let schedule: SubscriptionScheduleProps = {
        scheduleId: subSchedule.subscriptionSchedule.id,
        subscriptionId: subSchedule.subscriptionSchedule.subscriptionId,
        currentProduct: subSchedule.currentProduct.name,
        currentTier: subSchedule.currentProduct.tier,
        currentPrice: subSchedule.currentPrice.intervalCount + " " + subSchedule.currentPrice.interval + " - " + price?.unitAmount + price?.currency,
        currentNumber: subSchedule.subscriptionSchedule.currentQuantity,
        scheduledProduct: subSchedule.scheduledProductDto.name,
        scheduledTier: subSchedule.scheduledProductDto.tier,
        scheduledPrice: subSchedule.scheduledPrice.intervalCount + " " + subSchedule.scheduledPrice.interval + " - " + schedulePrice?.unitAmount + schedulePrice?.currency,
        scheduledNumber: subSchedule.subscriptionSchedule.scheduledQuantity,
        creationDate: ConvertFullDateTimeToDate(subSchedule.subscriptionSchedule.creationDate),
        executionDate: ConvertFullDateTimeToDate(subSchedule.subscriptionSchedule.executionDate)
      }
      schedules.push(schedule)
    })
    SetSchedulesData(schedules);
  }, [stripeData.subscriptionSchedules.subscriptionScheduleResponses])

  useEffect(() => {
    subscritions = [];

    stripeData.customerSubscriptions.subscriptions.map((sub) => {
      let price = sub.price.currencyOptions.find(x => x.currency === sub.userCurrency)
      let stringPrice = ''
      if (price !== undefined)
        stringPrice = sub.price.intervalCount + " " + sub.price.interval + " - " + price?.unitAmount + price?.currency

      let subscription: SubscriptionUpdate = {
        subscriptionID: sub.subscriptionId,
        productName: sub.product.name,
        currentPrice: stringPrice,
        currentPriceId: sub.price.id,
        selectedProductUpdate: "",
        selectedPriceUpdate: "",
        startDate: ConvertFullDateTimeToDate(sub.startDate),
        currentPeriodEnd: ConvertFullDateTimeToDate(sub.currentPeriodEnd),
      }
      subscritions.push(subscription)

    });
    SetSubscritionData(subscritions);
  }, [stripeData.customerSubscriptions.subscriptions]);

  useEffect(() => {
    if (updateValues.updateSubscriptions === true) {
      dispatch(updateSubscriptionStatus(false))
      dispatch(GetCustomerSubscriptions());
    }

  }, [updateValues.updateSubscriptions])
  useEffect(() => {
    if (updateValues.updateSchedules === true) {
      dispatch(updateSchedulesStatus(false))
      dispatch(GetCustomerSubscriptions());
    }

  }, [updateValues.updateSchedules])

  const onRemoveClick = (itemId: string) => {
    dispatch(RemoveSubscriptionSchedule(itemId));
  };

  if (statusValues.isLoading) {
    return LoadingPage(statusValues.isLoading);
  }

  const setPriceChoiceForGivenData = (choice: string | undefined, subscriptionID: string): void => {
    let newData = subscritionData;
    if (newData !== undefined) {
      let subscriptionIndex = newData.findIndex(x => x.subscriptionID === subscriptionID)
      newData[subscriptionIndex].selectedPriceUpdate = choice ? choice : "";
      SetSubscritionData(newData)
    }
  };

  const setProductChoiceForGivenData = (choice: string | undefined, subscriptionId: string, index: number): void => {
    subscritions[index].selectedProductUpdate = choice ? choice : "";
  };

  const onUpdateClick = (data: SubscriptionUpdate) => {
    dispatch(GetSusbcriptionToUpdateInterval(data.subscriptionID));
    handleOpenDialog();
  }

  return (
    <div id="subscriptions" className={"bg-#FFFFFF scroll-mt-[50px] xl:scroll-mt-[70px] 2xl:scroll-mt-[90px] h-auto space-y-5 shadow-4s pb-10 rounded-b-[5px] " + ResponsiveWidthProfile}>
      <div className="2xl:h-[30px] bg-#FF6104-20 rounded-t-[5px]">
        <Typography variant="lead" className="uppercase font-semibold px-10">
          {t("Subscription")}
        </Typography>
      </div>
      {/* Inside Subscription*/}
      <div className="2xl:space-y-5 lg:space-y-2.5 sm:flex-col gap-y-10 px-10">
        {/* Inside Overview*/}
        <div className="flex flex-row items-center gap-3">
          <Typography variant="paragraph" className="uppercase font-semibold">
            {t("Overview")}
          </Typography>
          <Button id="openBtn" onClick={onOpenClick} color="deep-orange" className="bg-#FF8136 shadow-none hover:bg-#FF6104 hover:shadow-none">
            {t("Open")}
          </Button>
        </div>

        {/* Inside Update subscription interval or tier*/}
        <div className="flex flex-col gap-3 h-fit max-h-[300px] pb-5">
          <Typography variant="paragraph" className="uppercase font-semibold">
            {t("UpdateSubscriptionIntervalOrTier")}
          </Typography>
          <div className="overflow-auto">
            <Card className="h-full w-full border-hidden overflow-y-auto ">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD_UPDATE_SUB_INTERVAL_TIER.map((head) => (
                      <th key={head} className=" bg-#F6F6F6 p-4">
                        <Typography variant="small" color="blue-gray" className=" font-semibold leading-none">
                          {head}
                        </Typography>
                      </th>

                    ))}
                  </tr>
                </thead>
                <tbody>
                  {subscritionData?.map((data, index) => {
                    return (
                      <tr key={index} className="even:bg-blue-gray-50/50">
                        <td className={"p-4 " + TableDataWidth}>
                          <Typography variant="small" color="blue-gray" className="font-normal" >
                            {data.subscriptionID}
                          </Typography></td>
                        <td className={"p-4 " + TableDataWidth}>
                          <Typography variant="small" color="blue-gray" className="font-normal" >
                            {data.productName}
                          </Typography>
                        </td>
                        <td className={"p-4 " + TableDataWidth}>
                          <Typography variant="small" color="blue-gray" className="font-normal" >
                            {data.currentPrice}
                          </Typography>
                        </td>
                        {/* <td className={"p-4 " + TableDataWidth}>
                          <Select onChange={(choice) => setProductChoiceForGivenData(choice, data.subscriptionID, index)} label="Select Version">
                            {productUpdates.map((data, index) => (
                              <Option value={data} key={data + index}>{data}</Option>
                            ))}
                          </Select>
                        </td> */}
                        {/* <td className={"p-4 " + TableDataWidth}>
                          <Select onChange={(choice) => setPriceChoiceForGivenData(choice, data.subscriptionID)} value={data.selectedPriceUpdate} label="Select Version">
                            {priceUpdates.map((data, index) => (
                              <Option value={data} key={data + index}>{data}</Option>
                            ))}
                          </Select>
                        </td> */}
                        <td className={"p-4 " + TableDataWidth}>
                          <Typography variant="small" color="blue-gray" className="font-normal" >
                            {data.startDate}
                          </Typography>
                        </td>
                        <td className={"p-4 " + TableDataWidth}>
                          <Typography variant="small" color="blue-gray" className="font-normal" >
                            {data.currentPeriodEnd}
                          </Typography>
                        </td>
                        <td className={"p-4 " + TableDataWidth}>
                          <Button id="updateBtn" onClick={() => onUpdateClick(data)} className=" bg-#A3B974 shadow-none hover:bg-#83A639 hover:shadow-none">{t("Update")}</Button></td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Card>
          </div>
        </div>

        {/* Inside Update subscription update overview*/}
        <div className="flex flex-col gap-3 h-fit max-h-[300px] pb-5">
          <Typography variant="paragraph" className="uppercase font-semibold">
            {t("SubscriptionUpdateOverview")}
          </Typography>
          <TableWithButton
            tableHead={TABLE_HEAD_SUB_UPDATE_OVERVIEW}
            tableBody={schedulesData?.map((sub) => {
              return {
                itemId: sub.scheduleId,
                tableData: [
                  //Current:
                  sub.subscriptionId,
                  sub.currentProduct,
                  sub.currentTier.toString(),
                  sub.currentPrice,
                  sub.currentNumber.toString(),
                  //Schedule:
                  sub.scheduledProduct,
                  sub.scheduledTier.toString(),
                  sub.scheduledPrice,
                  sub.scheduledNumber.toString(),
                  sub.creationDate,
                  sub.executionDate,
                  t("Remove"),
                ],
              };
            })}
            onClickFunction={(param) => onRemoveClick(param)} />
        </div>
      </div>
      <Dialog open={openDialog} handler={handleOpenDialog} size="xs" animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}>
        <UpdateSubscription onClickFunction={handleOpenDialog} priceUpdates={stripeData.susbcriptionToUpdateIntervalResponse} productUpdates={stripeData.susbcriptionToUpdateTierResponse} />
      </Dialog>

    </div>
  );
}
