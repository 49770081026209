import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiAddress } from "../../app/apiModel";
import { handleFulfilled, handlePending, handleRejected, RejectedValue, StatusValue } from "../../app/common";
import { AssignLicenseRequest, GetLicenseResponse, ErrorResponse, LicensesSliceData, LicensesData, AssignDemoLicenseResponse } from "../Licenses/licensesModel";

export const PostAssignLicense = createAsyncThunk<
  string,
  AssignLicenseRequest,
  { rejectValue: RejectedValue }
>(
  "LicensesSlice/assignlicense",
  async (license: AssignLicenseRequest, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "license/assignlicense", {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify({
        licenseId: license.licenseId,
        directoryId: license.directoryId,
        userToAssignId: license.userToAssignId,
      }),
    });

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const PostRevokeLicense = createAsyncThunk<
  string,
  string,
  { rejectValue: RejectedValue }
>(
  "LicensesSlice/revokelicense",
  async (licenseId: string, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress + "license/revokelicense?licenseId=" + licenseId,
      {
        method: "POST",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const GetLicensesByDirectory = createAsyncThunk<
  GetLicenseResponse[],
  string,
  { rejectValue: RejectedValue }
>("GetLicensesByDirectory", async (directory: string, { rejectWithValue }) => {
  const requestHeaders: HeadersInit = new Headers();
  const accessToken = localStorage.getItem("webShopBimTAccessToken");
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Authorization", "Bearer " + accessToken);

  const response = await fetch(
    apiAddress + "license/getuserlicensesbydirectory?directoryId=" + directory,
    {
      method: "GET",
      headers: requestHeaders,
    }
  );

  if (response.status === 200) {
    const dataResponse = (await response.json()) as GetLicenseResponse[];
    return dataResponse;
  }
  else if (response.status === 401 || response.status === 402) {
    return rejectWithValue({ message: response.statusText, status: response.status })
  }
  else {
    const errorResponse = (await response.json()) as ErrorResponse;
    return rejectWithValue({
      message: errorResponse.detail,
      status: response.status,
    });
  }
});

export const GetLicensesBySubscription = createAsyncThunk<
  GetLicenseResponse[],
  string,
  { rejectValue: RejectedValue }
>(
  "GetLicensesBySubscription",
  async (subscription: string, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress +
      "license/getlicensesbysubscription?subscriptionId=" + subscription,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const dataResponse = (await response.json()) as GetLicenseResponse[];
      return dataResponse;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const GetLicensesByUser = createAsyncThunk<
  GetLicenseResponse[],
  void,
  { rejectValue: RejectedValue }
>("GetLicensesByUser", async (_, { rejectWithValue }) => {
  const requestHeaders: HeadersInit = new Headers();
  const accessToken = localStorage.getItem("webShopBimTAccessToken");
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Authorization", "Bearer " + accessToken);

  const response = await fetch(apiAddress + "license/getlicensesbyuser", {
    method: "GET",
    headers: requestHeaders,
  });

  if (response.status === 200) {
    const dataResponse = (await response.json()) as GetLicenseResponse[];
    return dataResponse;
  }
  else if (response.status === 401 || response.status === 402) {
    return rejectWithValue({ message: response.statusText, status: response.status })
  }
  else {
    const errorResponse = (await response.json()) as ErrorResponse;
    return rejectWithValue({
      message: errorResponse.detail,
      status: response.status,
    });
  }
});

export const GetViewLicensesByDirectory = createAsyncThunk<
  GetLicenseResponse[],
  string,
  { rejectValue: RejectedValue }
>("GetViewLicensesByDirectory", async (directory: string, { rejectWithValue }) => {
  const requestHeaders: HeadersInit = new Headers();
  const accessToken = localStorage.getItem("webShopBimTAccessToken");
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Authorization", "Bearer " + accessToken);

  const response = await fetch(
    apiAddress + "license/getuserlicensesbydirectory?directoryId=" + directory,
    {
      method: "GET",
      headers: requestHeaders,
    }
  );

  if (response.status === 200) {
    const dataResponse = (await response.json()) as GetLicenseResponse[];
    return dataResponse;
  }
  else if (response.status === 401 || response.status === 402) {
    return rejectWithValue({ message: response.statusText, status: response.status })
  }
  else {
    const errorResponse = (await response.json()) as ErrorResponse;
    return rejectWithValue({
      message: errorResponse.detail,
      status: response.status,
    });
  }
});

export const GetViewLicensesBySubscription = createAsyncThunk<
  GetLicenseResponse[],
  string,
  { rejectValue: RejectedValue }
>(
  "GetViewLicensesBySubscription",
  async (subscription: string, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(
      apiAddress +
      "license/getlicensesbysubscription?subscriptionId=" + subscription,
      {
        method: "GET",
        headers: requestHeaders,
      }
    );

    if (response.status === 200) {
      const dataResponse = (await response.json()) as GetLicenseResponse[];
      return dataResponse;
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

export const GetViewLicensesByUser = createAsyncThunk<
  GetLicenseResponse[],
  void,
  { rejectValue: RejectedValue }
>("GetViewLicensesByUser", async (_, { rejectWithValue }) => {
  const requestHeaders: HeadersInit = new Headers();
  const accessToken = localStorage.getItem("webShopBimTAccessToken");
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Authorization", "Bearer " + accessToken);

  const response = await fetch(apiAddress + "license/getlicensesbyuser", {
    method: "GET",
    headers: requestHeaders,
  });

  if (response.status === 200) {
    const dataResponse = (await response.json()) as GetLicenseResponse[];
    return dataResponse;
  }
  else if (response.status === 401 || response.status === 402) {
    return rejectWithValue({ message: response.statusText, status: response.status })
  }
  else {
    const errorResponse = (await response.json()) as ErrorResponse;
    return rejectWithValue({
      message: errorResponse.detail,
      status: response.status,
    });
  }
});

export const AssignDemoLicense = createAsyncThunk<string, string, { rejectValue: RejectedValue }>(
  "LicensesSlice/AssignDemoLicense",
  async (productFamily: string, { rejectWithValue }) => {
    const requestHeaders: HeadersInit = new Headers();
    const accessToken = localStorage.getItem("webShopBimTAccessToken");
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", "Bearer " + accessToken);

    const response = await fetch(apiAddress + "demolicense/assigndemolicense?productFamily=" + productFamily, {
      method: "POST",
      headers: requestHeaders,
    });

    if (response.status === 200) {
      return response.status.toString();
    }
    else if (response.status === 401 || response.status === 402) {
      return rejectWithValue({ message: response.statusText, status: response.status })
    }
    else {
      const errorResponse = (await response.json()) as ErrorResponse;
      return rejectWithValue({
        message: errorResponse.detail,
        status: response.status,
      });
    }
  }
);

const initialLicenses: GetLicenseResponse[] = [
  {
    licenseId: "",
    directoryId: "",
    assignedUserId: "",
    assignedDirectoryId: "",
    editorId: "",
    isActive: false,
    isDeleted: false,
    isAssigned: false,
    creationTimestampUnix: "",
    editingTimestampUnix: "",
    validUntilUnix: "",
    subscriptionId: "",
    licenseType: "",
  },
];

const initialAssigneDemoLicense: AssignDemoLicenseResponse = {
  licenseId: "",
}

const initialLicensesData: LicensesData = {
  viewLicensesByDirectory: initialLicenses,
  viewLicensesBySubscription: initialLicenses,
  viewLicensesByUser: initialLicenses,
  licensesByDirectory: initialLicenses,
  licensesBySubscription: initialLicenses,
  licensesByUser: initialLicenses,
  assigneDemoLicenses: initialAssigneDemoLicense,
}

const initStatusValues: StatusValue = {
  statusCode: "",
  isLoading: false,
  error: "",
}

const initialLicensesSliceData: LicensesSliceData = {
  licensesData: initialLicensesData,
  statusValues: initStatusValues
}

export const LicensesSlice = createSlice({
  name: "LicensesSlice",
  initialState: {
    licensesSliceData: initialLicensesSliceData
  },
  // {
  //   statusCode: "",
  //   isLoading: false,
  //   error: "",
  //   licensesByDirectory: initialLicenses,
  //   licensesBySubscription: initialLicenses,
  //   licensesByUser: initialLicenses,
  // },
  reducers: {
    resetLicensesSlice: (state) => {
      state.licensesSliceData.statusValues.statusCode = '';
      state.licensesSliceData.statusValues.isLoading = false;
      state.licensesSliceData.statusValues.error = '';
      state.licensesSliceData.licensesData.viewLicensesByDirectory = initialLicenses;
      state.licensesSliceData.licensesData.viewLicensesBySubscription = initialLicenses;
      state.licensesSliceData.licensesData.viewLicensesByUser = initialLicenses;
      state.licensesSliceData.licensesData.licensesByDirectory = initialLicenses;
      state.licensesSliceData.licensesData.licensesBySubscription = initialLicenses;
      state.licensesSliceData.licensesData.licensesByUser = initialLicenses;
      state.licensesSliceData.licensesData.assigneDemoLicenses = initialAssigneDemoLicense;
    },
    resetStatusCode: (state) => {
      state.licensesSliceData.statusValues.statusCode = '';
    },
    setStatusCodeToNone: (state) => {
      state.licensesSliceData.statusValues.statusCode = 'none';
    },
  },
  extraReducers(builder) {
    //Assign License
    builder
      .addCase(PostAssignLicense.fulfilled, (state, action) => {
        state.licensesSliceData.statusValues.statusCode = handleFulfilled(state.licensesSliceData.statusValues, action.payload);
      })
      .addCase(PostAssignLicense.rejected, (state, action) => {
        handleRejected(state.licensesSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(PostAssignLicense.pending, (state) => {
        handlePending(state.licensesSliceData.statusValues);
      });
    //Revoke License
    builder
      .addCase(PostRevokeLicense.fulfilled, (state, action) => {
        state.licensesSliceData.statusValues.statusCode = handleFulfilled(state.licensesSliceData.statusValues, action.payload);
      })
      .addCase(PostRevokeLicense.rejected, (state, action) => {
        handleRejected(state.licensesSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(PostRevokeLicense.pending, (state) => {
        handlePending(state.licensesSliceData.statusValues);
      });
    //Licenses By Directory
    builder
      .addCase(GetLicensesByDirectory.fulfilled, (state, action) => {
        state.licensesSliceData.licensesData.licensesByDirectory = handleFulfilled(state.licensesSliceData.statusValues, action.payload);
      })
      .addCase(GetLicensesByDirectory.rejected, (state, action) => {
        handleRejected(state.licensesSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetLicensesByDirectory.pending, (state) => {
        handlePending(state.licensesSliceData.statusValues);
      });
    //Licenses By Subscription
    builder
      .addCase(GetLicensesBySubscription.fulfilled, (state, action) => {
        state.licensesSliceData.licensesData.licensesBySubscription = handleFulfilled(state.licensesSliceData.statusValues, action.payload);
      })
      .addCase(GetLicensesBySubscription.rejected, (state, action) => {
        handleRejected(state.licensesSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetLicensesBySubscription.pending, (state) => {
        handlePending(state.licensesSliceData.statusValues);
      });
    //Licenses By User
    builder
      .addCase(GetLicensesByUser.fulfilled, (state, action) => {
        state.licensesSliceData.licensesData.licensesByUser = handleFulfilled(state.licensesSliceData.statusValues, action.payload);
      })
      .addCase(GetLicensesByUser.rejected, (state, action) => {
        handleRejected(state.licensesSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetLicensesByUser.pending, (state) => {
        handlePending(state.licensesSliceData.statusValues);
      });
    //View Licenses By Directory
    builder
      .addCase(GetViewLicensesByDirectory.fulfilled, (state, action) => {
        state.licensesSliceData.licensesData.viewLicensesByDirectory = handleFulfilled(state.licensesSliceData.statusValues, action.payload);
      })
      .addCase(GetViewLicensesByDirectory.rejected, (state, action) => {
        handleRejected(state.licensesSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetViewLicensesByDirectory.pending, (state) => {
        handlePending(state.licensesSliceData.statusValues);
      });
    //View Licenses By Subscription
    builder
      .addCase(GetViewLicensesBySubscription.fulfilled, (state, action) => {
        state.licensesSliceData.licensesData.viewLicensesBySubscription = handleFulfilled(state.licensesSliceData.statusValues, action.payload);
      })
      .addCase(GetViewLicensesBySubscription.rejected, (state, action) => {
        handleRejected(state.licensesSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetViewLicensesBySubscription.pending, (state) => {
        handlePending(state.licensesSliceData.statusValues);
      });
    //View Licenses By User
    builder
      .addCase(GetViewLicensesByUser.fulfilled, (state, action) => {
        state.licensesSliceData.licensesData.viewLicensesByUser = handleFulfilled(state.licensesSliceData.statusValues, action.payload);
      })
      .addCase(GetViewLicensesByUser.rejected, (state, action) => {
        handleRejected(state.licensesSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(GetViewLicensesByUser.pending, (state) => {
        handlePending(state.licensesSliceData.statusValues);
      });
    //Assign Demo License
    builder
      .addCase(AssignDemoLicense.fulfilled, (state, action) => {
        state.licensesSliceData.statusValues.statusCode = handleFulfilled(state.licensesSliceData.statusValues, action.payload);
      })
      .addCase(AssignDemoLicense.rejected, (state, action) => {
        handleRejected(state.licensesSliceData.statusValues, action.payload ? action.payload : { message: 'Error in application', status: -1 })
      })
      .addCase(AssignDemoLicense.pending, (state) => {
        handlePending(state.licensesSliceData.statusValues);
      });
  },
});

export default LicensesSlice.reducer;
export const { resetLicensesSlice, resetStatusCode, setStatusCodeToNone } = LicensesSlice.actions;
